export const operators = [
  {
    name: 'E',
    operatorColor: 'blue',
    description: (
      <small className="operators-card_content-description">
        Utilize o operador <small className="font-semibold">“E”</small> para
        buscar documentos que contenham todas as palavras-chave especificadas na
        pesquisa.
      </small>
    ),
    controlId: 'control-id-e',
    dataTestId: 'operator-btn-e',
    addOperator: 'E',
    videoRoute:
      'https://www.youtube.com/watch?v=gmnKsmm0A2U&list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8&index=8',
  },
  {
    name: 'OU',
    operatorColor: 'green',
    description: (
      <small className="operators-card_content-description">
        Utilize o operador <small className="font-semibold">“OU”</small> para
        buscar documentos que contenham pelo menos uma das palavras-chave
        inseridas na pesquisa.
      </small>
    ),
    controlId: 'control-id-ou',
    dataTestId: 'operator-btn-ou',
    addOperator: 'OU',
    videoRoute:
      'https://www.youtube.com/watch?v=vvGuRHy29Yg&list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8&index=6',
  },
  {
    name: 'NÃO',
    operatorColor: 'red',
    description: (
      <small className="operators-card_content-description">
        O operador <small className="font-semibold">“NÃO”</small> é utilizado
        para excluir palavras-chave específicas dos resultados da pesquisa.
      </small>
    ),
    controlId: 'control-id-nao',
    dataTestId: 'operator-btn-nao',
    addOperator: 'NÃO',
    videoRoute:
      'https://www.youtube.com/watch?v=F89hwKsu2PE&list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8&index=7',
  },
  {
    name: 'ASPAS',
    operatorColor: 'orange',
    description: (
      <small className="operators-card_content-description">
        As <small className="font-semibold">aspas</small> são usadas para fazer
        uma pesquisa exata, ou seja, o software retornará documentos que
        contenham a expressão exata inserida entre as aspas.
      </small>
    ),
    controlId: 'control-id-quotes',
    dataTestId: 'operator-btn-quotes',
    addOperator: ' "" ',
    videoRoute:
      'https://www.youtube.com/watch?v=YE4BRcook4Y&list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8&index=5',
  },
  {
    name: 'ADJ',
    operatorColor: 'light-green',
    description: (
      <small className="operators-card_content-description">
        Utilize o operador <small className="font-semibold">ADJ( )</small> para
        especificar (entre os parênteses) a quantidade máxima de palavras entre
        as palavras-chave pesquisadas.
      </small>
    ),
    controlId: 'control-id-adj',
    dataTestId: 'operator-btn-adj',
    addOperator: 'ADJ',
    videoRoute:
      'https://www.youtube.com/watch?v=RWDyHTX3RpI&list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8&index=9',
  },
];
