import React, { useState } from 'react';
import {
  SearchNormal1,
  FolderOpen,
  GlobalSearch,
  Briefcase,
  Map1,
} from 'iconsax-react';

import CardOption from './CardOption/index';
import { withRouter } from 'react-router-dom';
const Step3 = (props) => {
  const [cards, _] = useState([
    {
      icon: <SearchNormal1 size="36" color="#0069F5" />,
      text: (
        <p>
          Fazer uma <b>busca jurisprudencial</b> para encontrar o melhor
          precedente e consultar Jurimetria do tema
        </p>
      ),
      onClick: () => props.history.push('/pesquisa'),
      recomended: true,
    },
    {
      icon: <FolderOpen size="36" color="#0069F5" />,
      text: (
        <p>
          <b>Criar uma coleção</b> para você salvar suas decisões e exportar
          relatórios customizáveis de Jurimetria
        </p>
      ),
      onClick: () => props.history.push('/colecoes'),
      recomended: false,
    },
    {
      icon: <GlobalSearch size="36" color="#0069F5" />,
      text: (
        <p>
          <b>Monitorar decisões</b> sobre temas específicos e receber alertas de
          novas decisões?
        </p>
      ),
      onClick: () => props.history.push('/monitoramento'),
      recomended: false,
    },
    {
      icon: <Briefcase size="36" color="#0069F5" />,
      text: (
        <p>
          Crie dashboards de <b>Jurimetria Trabalhista</b>
        </p>
      ),
      onClick: () => props.history.push('/jurimetria-trabalhista'),
      recomended: false,
    },
    {
      icon: <Map1 size="36" color="#0069F5" />,
      text: (
        <p>
          <b>Explorar a ferramenta por conta própria</b>
        </p>
      ),
      onClick: () => props.history.push('/pesquisa'),
      recomended: false,
    },
  ]);

  return (
    <>
      {' '}
      {cards?.map((item, i) => {
        return (
          <CardOption
            icon={item.icon}
            text={item.text}
            onClick={item.onClick}
            recomended={item.recomended}
          />
        );
      })}
    </>
  );
};

export default withRouter(Step3);
