import { request } from './requester';

function sendEmail(
  userId,
  userName,
  userEmail,
  companyId,
  companyName,
  title,
  message,
  attachment,
) {
  return request({
    type: 'POST',
    microservice: 'help',
    action: `ticket/`,
    contentType: 'application/json',
    data: {
      user: `${userName}(${userId})`,
      company: `${companyName}(${companyId})`,
      email: userEmail,
      title,
      message,
      attachment,
    },
  }).then((response) => {
    return response;
  });
}

export const help = {
  sendEmail,
};
