import { Turivius } from '../../../../../ui';
import './BarChartCustomTooltip.scss';
import { BasicTooltip } from '@nivo/tooltip';

const BarChartCustomTooltip = ({
  id,
  key,
  title,
  legend,
  data,
  keys,
  indexBy,
  maxValue,
  getColors,
  width,
}) => {
  let axisBottomTickValues = new Set();
  data.forEach((dataPoint) => {
    if (
      dataPoint['month'].split('-')[1] == '01' &&
      !axisBottomTickValues.has(dataPoint['month'])
    )
      axisBottomTickValues.add(dataPoint['month']);
  });

  const NUM2MONTH = [
    'Jan.',
    'Fev.',
    'Mar.',
    'Abr.',
    'Maio',
    'Jun.',
    'Jul.',
    'Ago.',
    'Set.',
    'Out.',
    'Nov.',
    'Dez.',
  ];

  const axisBottomFormat = (value) => {
    const dateData = value.split('-');
    let dateTick = dateData[0];
    if (dateData.length == 2)
      dateTick = NUM2MONTH[parseInt(dateData[1]) - 1] + ' ' + dateTick;
    return dateTick;
  };

  const axisLeftFormat = (value) => {
    value = Number(value);
    if (value >= 1000000) {
      value = value / 1000000 + 'M';
    } else if (value >= 1000) {
      value = value / 1000 + 'K';
    }
    return value;
  };

  return (
    <>
      <p className="chart-title">
        <small style={{ fontSize: '95%' }}>{title}</small>
      </p>

      <Turivius.TuriviusBarChart
        style={{ width: '100%', height: width ? width : 150 }}
        key={key}
        id={id}
        margin={{
          top: 0,
          right: 30,
          bottom: 50,
          left: 50,
        }}
        axisBottom={{
          format: axisBottomFormat,
          tickValues: [...axisBottomTickValues],
          tickRotation: 30,
        }}
        axisLeft={{
          legendOffset: -45,
          legend: legend,
          legendPosition: 'middle',
          format: axisLeftFormat,
          tickValues: 5,
        }}
        gridYValues={5}
        data={data}
        keys={keys}
        indexBy={indexBy}
        getColors={(value) => {
          return getColors[value.data.label];
        }}
        maxValue={maxValue}
        enableLabel={false}
        gridXValues={[...axisBottomTickValues]}
        tooltip={({ color, label, ...data }) => {
          const dateData = data.indexValue.split('-');
          let tooltipDate = dateData[0];
          if (dateData.length == 2)
            tooltipDate = dateData[1] + '/' + tooltipDate;
          return (
            <BasicTooltip
              id={
                <span>
                  {data.formattedValue} decisões em {tooltipDate}
                </span>
              }
              enableChip={true}
              color={color}
            />
          );
        }}
      />
    </>
  );
};

export default BarChartCustomTooltip;
