import React from 'react';
import { Panel } from 'rsuite';
import PropTypes from 'prop-types';
import './TuriviusPanel.scss';

const TuriviusPanel = ({
  header,
  withShadow,
  withBorder,
  style,
  backgroundColor,
  children,
  className,
}) => (
  <Panel
    header={header}
    shaded={withShadow}
    bordered={withBorder}
    className={`turivius-panel ${className}`}
    style={{ ...style, backgroundColor }}
  >
    {children}
  </Panel>
);

TuriviusPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  withShadow: PropTypes.bool,
  withBorder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  header: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

TuriviusPanel.defaultProps = {
  withShadow: true,
  withBorder: true,
  backgroundColor: 'white',
  className: '',
  header: '',
  style: {},
};

export default TuriviusPanel;
