import { Turivius } from '../../../../../ui';
import './HorizontalBarChart.scss';
import { BasicTooltip } from '@nivo/tooltip';

const HorizontalBarChart = ({
  id,
  key,
  title,
  legend,
  data,
  keys,
  indexBy,
  maxValue,
  getColors,
  width,
}) => {
  const MAX_LEFT_TICK_LENGTH = 30;
  return (
    <>
      <p className="chart-title">
        <small style={{ fontSize: '95%' }}>{title}</small>
      </p>

      <Turivius.TuriviusBarChart
        style={{ width: '100%', height: width ? width : 250 }}
        key={key}
        id={id}
        margin={{
          top: 0,
          right: 10,
          bottom: 50,
          left: 200,
        }}
        axisBottom={{
          legendOffset: 45,
          legend: legend,
          legendPosition: 'middle',
          format: '>-.2~s',
          tickValues: 5,
        }}
        axisLeft={{
          format: (v) => {
            return v.length > MAX_LEFT_TICK_LENGTH
              ? v.substr(0, MAX_LEFT_TICK_LENGTH).concat('...')
              : v;
          },
        }}
        gridYValues={5}
        data={data}
        keys={keys}
        indexBy={indexBy}
        getColors={(value) => {
          return getColors[value.data.label];
        }}
        maxValue={maxValue}
        enableLabel={false}
        layout={'horizontal'}
        tooltip={({ color, ...data }) => {
          const dateData = data.indexValue.split('-');
          let tooltipDate = dateData[0];
          if (dateData.length == 2)
            tooltipDate = dateData[1] + '/' + tooltipDate;
          return (
            <BasicTooltip
              id={tooltipDate}
              value={`${data.formattedValue} decisões`}
              enableChip={true}
              color={color}
            />
          );
        }}
      />
    </>
  );
};

export default HorizontalBarChart;
