import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from '../../../../../../components/ui/dialog';
import { useLocation } from 'react-router-dom';

const NOTIFICATION_KEY = 'modal-grande-resumo-inteiro-teor';

export const BigLaunchMode = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(NOTIFICATION_KEY, 'false');
  };

  useEffect(() => {
    const _show = JSON.parse(localStorage.getItem(NOTIFICATION_KEY) as string);

    if (_show === null) {
      setOpen(true);
    } else {
      setOpen(_show);
    }
  }, [pathname]);

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogPortal>
        <DialogOverlay className="bg-black/30" />
        <DialogContent className="max-w-[891px] flex gap-0 p-0 z-[100] rounded-lg overflow-hidden">
          <div className="flex flex-col max-w-[298px] bg-[#053AC8] text-white font-poppins text-sm py-20 px-14">
            <span className="mb-2.5">NOVIDADE</span>
            <span className="text-xl font-bold leading-6 w-48 mb-6">
              Ampliação da funcionalidade deresumo com IA
            </span>
            <span className="w-[192px]">
              Agora você pode aproveitar nossa ferramenta de resumo de acórdãos
              em quase 100% da nossa base de dados. <br /> <br /> Aproveite uma
              pesquisa jurisprudencial ainda mais ágil e eficiente, confira a
              abrangência:
            </span>
          </div>
          <div className="flex flex-col bg-white pt-[71px] px-14">
            <span className="w-fit text-xs text-[#053AC8] leading-5 font-medium mb-[34px]">
              {' '}
              Expansão do resumo de IA nos órgãos:
            </span>
            <div className="gap-4 flex flex-col w-[491px] leading-6">
              <span>
                <strong>Tribunais Superiores:</strong> 100% de cobertura
              </span>
              <span>
                <strong>Justiça Federal:</strong> 100% de cobertura
              </span>
              <span>
                <strong>Órgãos Administrativos:</strong> TCU, CARF, CVM,
                CRSFN/BCB, TIT-SP, CERF-ES, CC-MG, CC-RJ, TARF-RS, CCRF-PR,
                SEFAZ-BA, CRF-PB, SEFAZ-AM, SEFAZ-MA/CRF-MA e SEFAZ-PE/TATE-PE
              </span>
              <span className="max-w-[470px]">
                <strong>Justiça Estadual:</strong> TJ-RJ, TJ-PR, TJ-SC, TJ-SP,
                TJ-MG, TJ-RS, TJ-ES, TJ-DFT, TJ-GO, TJ-AM, TJ-RR, TJ-RO, TJ-SE,
                TJ-AP, TJ-RN e TJ-MA
              </span>
              <span>
                <strong>Justiça do trabalho:</strong> TRT4 (RS), TRT12 (SC),
                TRT2 (SP), TRT17 (ES), <br /> TRT1 (RJ), ACTs e CCTs, TRT9 (PR),
                TST (Súmulas), TST (OJs), TST, <br /> NR (MinTrab), TRT20 (SE),
                TRT7 (CE), TRT6 (PE), TRT13 (PB), TRT16 (MA), TRT22 (PI), TRT11
                (AM e RR), TRT23 (MT), TRT10 (DF e TO), TRT5 (BA), TRT24 (MS),
                TRT18 (GO), TRT14 (AC e RO), TRT8 (PA e AP) e TRT21
              </span>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
