import React from 'react';

import FlagSystem from '../../../components/FlagSystem';
import { businessCases } from '../../../config/business-cases';
import UseCaseCard from '../core/Cards/UseCaseCard';
import { useHistory } from 'react-router-dom';

const DashEmpresarial = () => {
  const history = useHistory();
  // console.log('test regex', /^\/dashboard-empresarial\/visao-assunto\/\w+$/)
  return (
    <div className="business">
      <FlagSystem current="dashboard-empresarial" />
      <div className="business-home">
        <h5 className="business-home_title text-color_black">
          Selecione um dos <b>campos de busca e analise os dashboards</b> da sua
          empresa
        </h5>
        <div className="business-home_content mt-3 p-5">
          {businessCases?.map((item, index) => {
            return (
              <UseCaseCard
                icon={item.icon}
                title={item.title}
                description={item.description}
                onSelectCase={
                  item.route !== undefined
                    ? () => history.push(item.route)
                    : null
                }
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default DashEmpresarial;
