import axios from 'axios';

export const submit_hubspot_form = async (name, email, companyObject) => {
  const portalId = '25979713';
  const formGuid = 'fc58caa6-5fb7-4ded-82bc-8a196af06d54';
  const config = {
    // important!
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: 'firstname',
          value: name,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'phone',
          value: companyObject.cellphone,
        },
        {
          name: 'company',
          value: companyObject.name,
        },
        {
          name: 'admin_new_user',
          value: 'Sim',
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: 'App Turivius',
      },
    },
    config,
  );
  return response;
};

export const postHubSpotEvent = async (eventName, properties) => {
  // URL do endpoint para enviar eventos no HubSpot
  const endpoint = process.env.REACT_APP_HUBSPOT_PROXY;

  const profile = JSON.parse(localStorage.getItem('profile'));

  if (!profile) return;

  // Objeto de evento a ser enviado
  const data = {
    email: profile.email,
    eventName: eventName,
    properties: properties,
  };

  // Parâmetros da solicitação POST
  const params = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Realizar solicitação POST

  axios
    .post(endpoint, data, params)
    .then((response) => {
      //console.log('Solicitação POST bem-sucedida:', response.data);
    })
    .catch((error) => {
      //console.error('Erro ao realizar solicitação POST:', error);
    });
};
