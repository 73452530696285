import React from 'react';
import { Toggle } from 'rsuite';

const ToggleEmailMonitoring = ({ sendEmail, setSendEmail }) => {
  return (
    <div className="monitoring-settings-content_toggle">
      <Toggle
        size="lg"
        name="send_email"
        onChange={setSendEmail}
        checked={sendEmail}
        checkedChildren={<i className="ri-check-line" />}
        unCheckedChildren={<i className="ri-close-fill" />}
      />
      <label className="title-toggle">Enviar por e-mail</label>
    </div>
  );
};

export default ToggleEmailMonitoring;
