import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TuriviusPreloader from '../../app/TuriviusPreloader';
import { TURIVIUS_VERSION } from '../../app/App';
import { bindActionCreators } from 'redux';
import { Creators as ProfilesActions } from '../../store/ducks/profiles';
import { requester } from '../../requesters';

class Redirect extends React.Component {
  getProfile(userId, token, redirect) {
    this.props.setProfile({});
    this.props.setPermissions({});
    requester.setUserToken(token);

    const res = requester.admin.getCheckoutBlockById(userId);

    requester.profiles
      .getById(userId)
      .then((response) => {
        localStorage.setItem('first_access_blocked', String(res[1]));
        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));
        localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));
        this.props.setCompany(response.company);
        this.props.setProfile(response);

        if (response) {
          if (response.hasOwnProperty('permissions')) {
            this.props.setPermissions(response?.permissions);
          } else {
            this.props.history.push('/logout');
          }
        }

        if (res[1]) {
          // console.log(res[1])
          window.location.href = '/trial';
          // this.props.history.push("/trial");
        } else if (redirect) {
          this.props.history.push(redirect);
        } else {
          this.props.history.push('/');
        }
        // posthog.identify(response.id);
        // posthog.people.set({
        //   email: response.email,
        //   company: response.company.id,
        //   name: response.name,
        // });
      })
      .catch((err) => {
        console.error(err);
        // localStorage.clear();
        setTimeout(() => {
          this.props.history.push('/login');
        }, 200);
      });
  }

  // redirect?user=9791&hash=99352c40f8f0a027d86c2cfa63ad05499839d169
  // redirect?user=9791&hash=99352c40f8f0a027d86c2cfa63ad05499839d169
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const user = params.get('user');
    const token = params.get('hash');
    const redirect = params.get('redirect');

    localStorage.setItem('token', token);
    localStorage.setItem('user', user);
    this.getProfile(user, token, redirect);
  }

  render() {
    return <TuriviusPreloader backdrop />;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProfilesActions }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Redirect));
