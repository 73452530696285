import React from 'react';
import { Nav, Sidenav, Sidebar, Dropdown, Button } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import './TuriviusSidenav.scss';

const TuriviusSidenav = ({
  show,
  defaultActiveKey,
  defaultOpenKeys,
  logoURL,
  sidenavCustomClasses,
  handleSelectNavItem,
  header,
  footer,
  navItems,
}) => {
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey);
  const [isOpen, toggleIsOpen] = React.useState(false);

  const toggleSideBar = () => {
    toggleIsOpen(!isOpen);
  };
  const handleToggle = () => {};

  return (
    <Sidebar className="custom-sidebar">
      {show ? (
        <div id="turivius-sidenav">
          <Sidenav
            defaultOpenKeys={defaultOpenKeys}
            expanded={true}
            activeKey={activeKey}
            onSelect={handleSelectNavItem}
            className={`custom-sidenav rs-sidenav-collapse-out ${isOpen ? 'open' : ''} ${sidenavCustomClasses || ''}`}
          >
            <Sidenav.Header className="sidenav-header">
              {isOpen ? (
                <div className="open-sidebar close" onClick={toggleSideBar}>
                  <FontAwesomeIcon size="lg" color="white" icon={faTimes} />
                </div>
              ) : (
                <div className="open-sidebar" onClick={toggleSideBar}>
                  <FontAwesomeIcon size="lg" color="white" icon={faBars} />
                </div>
              )}
              <img
                src={logoURL}
                alt="Logo Turivius"
                className="image-logo"
                onClick={handleToggle}
              />
              {header ? (
                <div className="user">
                  {header.children}
                  <Button
                    onClick={header.onClick}
                    className="btn-header"
                    appearance="link"
                  >
                    {' '}
                    Meu perfil{' '}
                  </Button>
                </div>
              ) : null}
            </Sidenav.Header>
            <Sidenav.Body>
              <Nav>
                {navItems?.map((item, i) => {
                  return (item?.show === undefined && item?.group.length > 0) ||
                    (item?.show && item?.group.length > 0) ? (
                    <Dropdown
                      key={i}
                      eventKey={item.id}
                      title={
                        <div
                          className="option-sidenav"
                          onClick={() => {
                            setActiveKey(item.id);
                            if (item.onClick) {
                              item.onClick();
                            }
                          }}
                        >
                          {item.icon ? (
                            <div className="fragment-sidenav">{item.icon}</div>
                          ) : null}

                          {item.children}

                          {item.icon ? (
                            <div className="fragment-sidenav">
                              <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                          ) : null}
                        </div>
                      }
                    >
                      {item?.group?.map((ig, i) => {
                        return ig.show === undefined || ig.show ? (
                          <Dropdown.Item
                            key={`${ig.id}-${i}`}
                            eventKey={`${ig.id}-${i}`}
                            onClick={() => {
                              setActiveKey(`${ig.id}-${i}`);
                              if (ig.onClick) {
                                ig.onClick();
                              }
                            }}
                          >
                            {ig.children}
                          </Dropdown.Item>
                        ) : null;
                      })}
                    </Dropdown>
                  ) : item.show ? (
                    <Nav.Item
                      key={i}
                      eventKey={item.id}
                      className={item.customClass}
                      onClick={() => {
                        setActiveKey(item.id);
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      <div className="option-sidenav">
                        {item.icon ? (
                          <div className="fragment-sidenav">{item.icon}</div>
                        ) : null}

                        {item.children}
                      </div>
                    </Nav.Item>
                  ) : null;
                })}

                {footer ? (
                  <Nav.Item
                    key={footer.id}
                    eventKey={footer.id}
                    className="footer-sidebar"
                  >
                    <div
                      className="option-sidenav footer"
                      onClick={() => {
                        setActiveKey(footer.id);
                        if (footer.onClick) {
                          footer.onClick();
                        }
                      }}
                    >
                      {footer.icon ? (
                        <div className="fragment-sidenav">{footer.icon}</div>
                      ) : null}

                      {footer.children}
                    </div>
                  </Nav.Item>
                ) : null}
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </div>
      ) : null}
    </Sidebar>
  );
};

TuriviusSidenav.propTypes = {
  show: PropTypes.bool.isRequired,
  defaultActiveKey: PropTypes.string,
  logoURL: PropTypes.string.isRequired,
  sidenavCustomClasses: PropTypes.string,
  handleSelectNavItem: PropTypes.func,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      customClass: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]).isRequired,
      show: PropTypes.bool.isRequired,
      ref: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
      ]),
    }),
  ).isRequired,
};

TuriviusSidenav.defaultProps = {
  show: true,
  logoURL: '',
  navItems: [],
};

export default TuriviusSidenav;
