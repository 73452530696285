import { useState } from 'react';
import {
  ArrowDown2,
  ArrowUp2,
  CloseCircle,
  Judge,
  TickCircle,
} from 'iconsax-react';

const options = [
  {
    id: 0,
    iconColor: '#12B76A',
    label: 'Favorável ao contribuinte',
  },
  {
    id: 1,
    iconColor: '#F79009',
    label: 'Contrário ao contribuinte',
  },
  {
    id: 2,
    iconColor: '#0069F5',
    label: 'Parcialmente favorável',
  },
  {
    id: 3,
    iconColor: '#88E2D7',
    label: 'Outro / Indefinido',
  },
];

const DecisionClassification = ({
  activeOption,
  setActiveOption,
  requestError,
}) => {
  const [open, setOpen] = useState(false);

  const handleSelectDecision = (option) => {
    setActiveOption({ id: option.id, name: option.label });
    setOpen(false);
    return;
  };

  return (
    <>
      <div
        className={
          activeOption
            ? `decision-classification_active`
            : `decision-classification`
        }
      >
        {activeOption.name ? (
          <div
            className="decision-classification_input"
            onClick={() => setOpen(!open)}
          >
            {activeOption.name}
            <button onClick={() => setOpen(!open)}>
              {!requestError ? (
                <TickCircle size={12} color="#32D583" />
              ) : (
                <CloseCircle size={12} color="#F04438" />
              )}
            </button>
          </div>
        ) : (
          <div
            className="decision-classification_input"
            onClick={() => setOpen(!open)}
          >
            Selecione a nova classificação
            <button onClick={() => setOpen(!open)}>
              {open ? (
                <ArrowUp2 size={12} color="#475467" />
              ) : (
                <ArrowDown2 size={12} color="#475467" />
              )}
            </button>
          </div>
        )}
      </div>
      {requestError && activeOption.name ? (
        <div className="decision-classification_error-message mt-2">
          {requestError}
        </div>
      ) : null}
      {open ? (
        <div className="decision-classification_options mt-1">
          {options?.map((item, index) => {
            return (
              <div className="decision-classification_options-item" key={index}>
                <button onClick={() => handleSelectDecision(item)}>
                  <Judge size={18} color={item.iconColor} />
                  {item.label}
                </button>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default DecisionClassification;
