import { useMutation, useQuery } from '@tanstack/react-query';

import api from './api';
import {
  FetchEntitiesResponse,
  FetchFiltersResponse,
  FetchSummaryRequest,
  FetchSummaryResponse,
  SummaryFeedbackRequest,
} from './types';

const endpoints = {
  fetchSummary: () => 'summary/',
  summaryFeedback: () => 'summary/feedback/',
  fetchFilters: () => 'filters',
  fetchEntities: () => 'entities',
};

const cardsKeys = {
  all: ['cards'] as const,
  fetchSummarys: () => [...cardsKeys.all, 'fetchSummary'] as const,
  fetchSummary: ({ card_id, entity, user_id }: FetchSummaryRequest) =>
    [...cardsKeys.fetchSummarys(), card_id, entity, user_id] as const,
  summaryFeedbacks: () => [...cardsKeys.all, 'summaryFeedback'] as const,
  summaryFeedback: ({
    card_id,
    entity,
    user_id,
    action,
  }: SummaryFeedbackRequest) =>
    [
      ...cardsKeys.summaryFeedbacks(),
      card_id,
      entity,
      user_id,
      action,
    ] as const,
  fetchFilterss: () => [...cardsKeys.all, 'fetchFilters'] as const,
  fetchFilters: () => [...cardsKeys.fetchFilterss()] as const,
  fetchEntitiess: () => [...cardsKeys.all, 'fetchEntities'] as const,
  fetchEntities: () => [...cardsKeys.fetchEntitiess()] as const,
};

const cardsApi = {
  useFetchSummary: () =>
    useMutation({
      mutationFn: async ({ card_id, entity, user_id }: FetchSummaryRequest) => {
        const response = await api.post<
          FetchSummaryRequest,
          FetchSummaryResponse
        >(endpoints.fetchSummary(), { card_id, entity, user_id });
        return response.data;
      },
    }),
  useSummaryFeedback: () =>
    useMutation({
      mutationFn: async ({
        card_id,
        entity,
        user_id,
        action,
      }: SummaryFeedbackRequest) => {
        const response = await api.post<SummaryFeedbackRequest>(
          endpoints.summaryFeedback(),
          {
            card_id,
            entity,
            user_id,
            action,
          },
        );
        return response;
      },
    }),
  useFetchFilters: () =>
    useQuery({
      queryKey: cardsKeys.fetchFilters(),
      queryFn: async () => {
        const response = await api.get<FetchFiltersResponse>(
          endpoints.fetchFilters(),
        );
        return response.data;
      },
    }),
  useFetchEntities: () =>
    useQuery({
      queryKey: cardsKeys.fetchEntities(),
      queryFn: async () => {
        const response = await api.get<FetchEntitiesResponse>(
          endpoints.fetchEntities(),
        );
        return response.data;
      },
    }),
};

export const {
  useFetchSummary,
  useSummaryFeedback,
  useFetchFilters,
  useFetchEntities,
} = cardsApi;
