export const tourAvaliable = [
  {
    id: 'search',
    title: 'Tour de Pesquisa',
    route: '/pesquisa',
  },
  {
    id: 'collection',
    title: 'Tour de Coleções',
    route: '/colecoes',
  },
  {
    id: 'monitoring',
    title: 'Tour de Monitoramento',
    route: '/monitoramento',
  },
  // {
  //     id:"jurimetricTrabalhista",
  //     title:  "Tour de Jurimetria Trabalhista",
  //     route: "/jurimetria-trabalhista"
  // },
  {
    id: 'result',
    title: 'Tour de Jurisprudência',
    route: '',
  },
  // {
  //     id:"jurimetric",
  //     title:  "Tour de Jurimetria",
  //     route:""
  // },
];

export const helpInformations = [
  {
    session: 'collections',
    route: 'Coleções',
    informations: [
      {
        id: 1,
        query: 'Como as coleções facilitam o trabalho do advogado?',
        information:
          'Com as coleções da Turivius você não precisa refazer pesquisas já realizadas. Salve os precedentes ideais para o seu caso concreto, utilize em suas fundamentações processuais e deixe-as disponíveis para que todos da equipe não precisem perder tempo pesquisando novamente. Assim, mesmo que um advogado esteja ausente ou saia do time, o escritório manterá a sua própria biblioteca de teses concentrada em uma única ferramenta e não em diversas pastas no computador do advogado.',
      },
      {
        id: 2,
        query: 'Como utilizar o campo de anotações das ementas?',
        information:
          'O objetivo é utilizar o campo de anotações para registrar detalhes específicos de uma decisão, como o número do processo, a peça jurídica utilizada como fundamento e sua aplicação no caso em questão. Assim, essa informação está facilmente acessível por você e pelos colegas, que podem atuar de forma colaborativa para atualizar as coleções.',
      },
      {
        id: 3,
        query: 'Por que compartilhar minhas coleções com meus colegas?',
        information:
          'Primeiro, para economizar tempo, certo? Compartilhando coleções entre os membros da equipe, evita o retrabalho com pesquisas já realizadasa. A interação e colaboração de conhecimento entre colegas por meio das anotações nas decisões promove o crescimento do escritório.',
      },
    ],
  },

  {
    session: 'monitoring',
    route: 'Monitoramento',
    informations: [
      {
        id: 1,
        query: 'Para que serve o monitoramento?',
        information:
          'Quantas vezes você precisa pesquisar o mesmo tema e os mesmos termos para encontrar a jurisprudência perfeita à situação ou para atualizar o seu acervo de precedentes? Com o monitoramento da Turivius, o robô pesquisa para você a partir de termos e Órgãos presetados. Assim, você não precisa mais perder tempo, basta escolher em qual dia deseja receber em seu e-mail as novas decisões proferidas que contenham o(s) termo(s) escolhidos. Mantenha-se sempre atualizado!',
      },
      {
        id: 2,
        query: 'Além de termos de assunto, o que mais posso monitorar? ',
        information:
          'Você pode monitorar tudo o que for citado em uma decisão, então use sua criatividade. Inclua no seu monitoramento nome de empresas e CNPJs, complemente os temas com palavras chave como "PROVIDO", "CONHECIDO", "DEFERIMENTO", "AGRAVO", "RESP", etc, mas sempre tenha em mente que isso irá refinar o seu monitoramento, então, o numero de decisões será menor, porém, com mais qualidade!',
      },
      {
        id: 3,
        query: 'Como acionar o monitoramento de forma eficaz?',
        information:
          'O monitoramento nada mais é do que uma pesquisa jurisprudencial automatizada. A melhor forma de acionar o monitoramento é: Passo 1: faça a pesquisa jurisprudencial utilizando os termos de pesquisa; Passo 2: analise os resultados, veja se estão satisfatórios e, caso contrário, utilize nossos operadores para refinar a sua pesquisa; Passo 3: entenda a necessidade ou não de incluir termos semelhantes; Passo 4: acione o monitoramento. Extra: Ainda não encontrou o precedente que deseja, mas acredita que futuramente possa ser julgado um caso semelhante? Determine os termos que você entende que farão parte deste novo julgado e ative o monitoramento para receber a decisão em seu e-mail.',
      },
    ],
  },
  {
    session: 'jurisprudence',
    route: 'Jurisprudência',
    informations: [
      {
        id: 1,
        query: 'teste video',
        information: 'Não.',
        videoTeste: 'https://www.youtube.com/watch?v=qxAeGNVDql4&t=1s',
        videoUrl: (
          <iframe
            width="443"
            height="244"
            src="https://www.youtube.com/embed/Vd1HatJiqGo?list=PLY19DhtbRTMAP9ORvwJaqzU5H5fymXxb8"
            title="Jurimetria Trabalhista Turivius   pesquisa por CNPJ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ),
      },
      {
        id: 2,
        query: 'A base de dados captura processos físicos?',
        information: 'Não.',
      },
      { id: 3, query: 'Qual o ano inicial de cobertura', information: '2000' },
      {
        id: 4,
        query: 'Qual a fonte de captura de dados?',
        information:
          'Os dados são coletados diretamente dos Tribunais de Justiça e Órgão Administrativos, ou seja, estão no sistema da Turivius antes mesmo de sua publicação no Diário Oficial. ',
      },
      {
        id: 5,
        query: 'No que o filtro de termos semelhantes pode ajudar?',
        information:
          'O filtro de termos semelhantes te ajudará quando, no resultado, você perceber muitas variações do termo pesquisado. Um exemplo prático para te ajudar: quando você digita PERSE, no resultado pode aparecer "persistir" ou "persistência". Ao desativar a inclusão de termos semelhantes, apenas o termo que você escreveu irá constar nos resultados. MAS ATENÇÃO: em alguns casos, a inclusão de termos semelhantes se faz necessária para um numero maior de resultados, já que, ao escrever uma palavra no singular e masculino, por exemplo, o sistema também apresentará variações no feminino e plural.',
      },
      {
        id: 6,
        query:
          'Como funciona a ordenação por relevância dos resultados da pesquisa jurisprudencial?',
        information:
          'Quanto mais a decisão citar os termos procurados, maior relevância ela terá.',
      },
      {
        id: 7,
        query: 'É possível emitir um relatório de decisões?',
        information:
          'Sim, a partir da função coleções. Para isso, você necessita salvar em uma única coleção todas as decisões que deseja que apareçam no relatório.',
      },
      {
        id: 8,
        query: 'Qual a frequência de atualização dos dados?',
        information: 'Nossos dados são atualizados diariamente, às 18h.',
      },
      {
        id: 9,
        query: 'O que fazer para obter resultados melhores na minha pesquisa?',
        information:
          'Primeiro, pense sempre em como o magistrado, ministro ou desembargador se refere à determinado tema. Por exemplo, ao invés de utilizar "exclusão de sócio", escreva "destituição de sócio", assim você terá resultados mais satisfatórios. Em segundo lugar, adicione termos que possam complementar o assunto da sua pesquisa, como, por exemplo, "deferimento" ou "deferido". Dessa forma, você terá resultados com pedidos deferidos para fundamentar as suas peças.',
      },
      {
        id: 10,
        query: 'Quando utilizar o operador de busca "não"?',
        information:
          'O operador de busca "não" vai ser seu maior aliado de pesquisa. Imagine que você está pesquisando sobre a destituição de um sócio administrador do quadro societário e, no seu resultado, aparecem síndicos. Você não irá conseguir utilizar esses precedentes porque não cabem no seu caso concreto. Utilize o operador NÃO para excluir o termo "síndico" dos seus resultados e obtenha a jurisprudencia ideal. Você pode utilizar o NÃO várias vezes e excluir vários termos na mesma pesquisa, por exemplo: NÃO síndico NÃO associação NÃO condomínios.',
      },
      {
        id: 11,
        query: 'Qual e melhor forma de utilizar o operador ADJ?',
        information:
          'Imagine que você está pesquisando sobre a incidência de ITR x IPTU. Ao colocar os termos ITR e IPTU, você percebe que os resultados te mostram esses termos, mas fora do contexto que você precisa. Utilize o operador ADJ para limitar a quantidade de palavras entre um termo e outro, dessa forma: ITR ADJ3 IPTU. Quer dizer que o máximo de palavras entre esses termos será três e, assim, você terá um resultado no contexto em que precisa.',
      },
      {
        id: 12,
        query: 'Como o operador OU otimiza a pesquisa jurisprudencial?',
        information:
          'Esse operador é excelente para obter o maior número de resultados possível, quando sabe-se que os julgadores utilizam diferentes termos para falar sobre o mesmo assunto. O exemplo mais clássico é quando falamos de números de lei. Pesquise dessa forma: "lei 14.148/2021" OU "lei nº 148 de 2021" OU "lei nª 148 do ano de 2021", dessa forma, independente de como a lei é escrita pelo julgador, aparecerá no seu resultado.',
      },
      {
        id: 13,
        query: 'Por que pesquisar acordos e convenções coletivas na Turivius?',
        information:
          'A resposta é simples: os acordos e convenções coletivas têm uma validade de dois anos, com fulcro no art 614 da CLT. Pesquise pelo nome do sindicato ou CNPJ, ordene os resultados por data de publicação e descubra quais ACTs estão vigentes para evitar a utilização de precedentes defasados.',
      },
    ],
  },
];
