import { LampOn } from 'iconsax-react';
import React from 'react';

const HighlightCard = ({ highlight }) => {
  // console.log("typeof(highlight)", typeof (highlight))
  return (
    <div className="dashboard-highlights_card">
      <p className="font-medium text-color_black">{highlight}</p>
      <div className="dashboard-highlights_card-icon">
        <LampOn color="#0086C9" size={28} />
      </div>
    </div>
  );
};

export default HighlightCard;
