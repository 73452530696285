import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';

const SynonymsFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, setSynonymsFilter } = props;

    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'synonyms',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    setSynonymsFilter(newOption);
  };

  return (
    <div className="synonyms-filter">
      <span className="title-option">Incluir Termos Semelhantes</span>
      <RadioGroup
        onChange={handleChange}
        value={props.synonymsFilter}
        name="radioList"
        appearance="picker"
        inline
      >
        <Radio value={true}>Sim{<br />}</Radio>
        <Radio value={false}>Não</Radio>
      </RadioGroup>
    </div>
  );
};

export default SynonymsFilter;
