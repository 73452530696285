const NoResultsMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p>Esta busca não obteve resultados</p>
      <p style={{ fontWeight: 700 }}>
        Tente modificar os filtros ou refazer a busca com termos mais amplos!
      </p>
      <img
        alt="Sem resultados"
        src="/no-results.png"
        style={{ width: 150, marginTop: 30, marginBottom: 30 }}
      />
      <div
        style={{
          border: '1px solid #666',
          padding: 20,
          cursor: 'pointer',
          margin: 'auto',
          width: 'fit-content',
          borderRadius: 5,
        }}
      >
        <p>
          <b>
            Essa busca não retornou o resultado esperado
            <br />
            ou acredita que haja algum problema?
          </b>
        </p>
      </div>
    </div>
  );
};

export default NoResultsMessage;
