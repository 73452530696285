import { Turivius } from '../../../../../ui';
import { withRouter } from 'react-router-dom';
import './style.scss';

const SearchButtonAdvanced = ({
  title,
  disabled,
  propName,
  value,
  history,
  errorAction,
  location,
}) => {
  return (
    <Turivius.TuriviusButton
      fullWidth
      className="btn-advanced-search"
      disabled={disabled}
      size="sm"
      onClick={() => {
        const service = location.pathname.replace('/', '');
        if (
          value === '' ||
          value === '[]' ||
          (typeof value === 'object' && value.length === 0)
        ) {
          errorAction(`error_${propName}`);
        } else {
          if (propName === 'relator_ids') {
            let params = [];
            value
              .replace('[', '')
              .replace(']', '')
              .split(',')
              .forEach((relator) => {
                params.push(`relator=${relator}`);
              });
            let searchString = service
              ? `/${service}/resultados?${params.join('&')}`
              : `/resultados?${params.join('&')}`;
            history.push(searchString);
          } else {
            let searchString = service
              ? `/${service}/resultados?${propName}=${value}`
              : `/resultados?${propName}=${value}`;
            history.push(searchString);
          }
        }
      }}
    >
      {title ? title : 'Buscar'}
    </Turivius.TuriviusButton>
  );
};

export default withRouter(SearchButtonAdvanced);
