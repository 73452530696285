const validateCNPJ = (cnpjStr, withMask) => {
  if (withMask === 'withMask') {
    cnpjStr = cnpjStr.replaceAll('.', '').replace('/', '').replace('-', '');
  }

  cnpjStr = cnpjStr.replace(/[^\d]+/g, '');

  if (cnpjStr === '') {
    return false;
  }

  if (cnpjStr.length !== 14) {
    return false;
  }

  if (
    cnpjStr === '00000000000000' ||
    cnpjStr === '11111111111111' ||
    cnpjStr === '22222222222222' ||
    cnpjStr === '33333333333333' ||
    cnpjStr === '44444444444444' ||
    cnpjStr === '55555555555555' ||
    cnpjStr === '66666666666666' ||
    cnpjStr === '77777777777777' ||
    cnpjStr === '88888888888888' ||
    cnpjStr === '99999999999999'
  ) {
    return false;
  }

  let size = cnpjStr.length - 2;
  let numbers = cnpjStr.substring(0, size);
  let digits = cnpjStr.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(0)) {
    return false;
  }
  size = size + 1;
  numbers = cnpjStr.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(1)) {
    return false;
  }
  return true;
};

const validateCPF = (strCPF, withMask) => {
  let sum = 0;
  let rest;

  if (withMask === 'withMask') {
    strCPF = strCPF.replaceAll('.', '').replace('-', '');
  }

  if (strCPF === '00000000000') {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(strCPF.substring(10, 11))) {
    return false;
  }
  return true;
};

const EncodeText = (type, text) => {
  switch (type) {
    case 'base64':
      return btoa(unescape(encodeURIComponent(text)));
    default:
      break;
  }
};

const DecodeText = (type, text) => {
  switch (type) {
    case 'base64':
      return atob(text);
    default:
      break;
  }
};

export const TuriviusValidate = {
  validateCNPJ,
  validateCPF,
};

export const TuriviusCrypt = {
  EncodeText,
  DecodeText,
};
