import { create } from 'zustand';

interface TokenLoginState {
  code: string;
  email: string;
  setCode: (code: string) => void;
  setEmail: (email: string) => void;
  clear: () => void;
}

export const useTokenLoginStore = create<TokenLoginState>((set) => ({
  code: '',
  email: '',
  setCode: (code) =>
    set((state) => ({
      ...state,
      code,
    })),
  setEmail: (email) =>
    set((state) => ({
      ...state,
      email,
    })),
  clear: () => set(() => ({ code: '', email: '' })),
}));
