import { Button } from '../../../../../ds-components';
import { Notification } from 'rsuite';
import { cutString } from '../../../../../utils/cutString';
import { withRouter } from 'react-router-dom';

const NotificationPush = ({ result, now, ...props }) => {
  return (
    <Notification type="info" className="notification-push" closable>
      <div className="notification-push_header">
        <img src="/favicon.ico" alt="turivius_icon" />
        <p className="notification-push_header_category">
          {result?.message.category}
        </p>
        <span className="notification-push_header_timestamp">• Agora</span>
      </div>

      <div className="notification-push_body">
        <p className="notification-push_body_title">
          {cutString(result.message.title, 56)}
        </p>
        <span className="notification-push_body_subtitle">
          {cutString(result.message.subtitle, 115)}
        </span>
      </div>
      <hr />
      <div className="notification-footer">
        {result.message.actions?.map((action) => (
          <Button size="sm" onClick={() => props.history.push(action.ref_link)}>
            {action.button_label}
          </Button>
        ))}
      </div>
    </Notification>
  );
};

export default withRouter(NotificationPush);
