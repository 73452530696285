import html2canvas from 'html2canvas';

export const printDocument = (id) => {
  // console.log('printDocument', id);
  const input = document.getElementById(id);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    var link = document.createElement('a');
    link.download = `download.png`;
    // link.download = `${linkName}.png`;
    link.href = imgData;
    link.click();
    //impressão de PDF
    // const pdf = new jsPDF({
    //     orientation: 'landscape',
    // });
    // pdf.addImage(imgData, 'JPEG', 0, 0);
    // // pdf.output('dataurlnewwindow');
    // pdf.save("download.jpeg");
  });
};
