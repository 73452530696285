import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  beginLogin: [],
  endLogin: [],
  verifyFirstAccess: ['email', 'hash'],
  cancelFirstAccess: [],
});

const INITIAL_STATE = {
  loggingIn: false,
  email: null,
  hash: null,
};

const beginLogin = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loggingIn: true,
  };
};

const endLogin = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loggingIn: false,
  };
};

const verifyFirstAccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    email: action.email,
    hash: action.hash,
  };
};

const cancelFirstAccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    email: null,
    hash: null,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.BEGIN_LOGIN]: beginLogin,
  [Types.END_LOGIN]: endLogin,
  [Types.VERIFY_FIRST_ACCESS]: verifyFirstAccess,
  [Types.CANCEL_FIRST_ACCESS]: cancelFirstAccess,
});
