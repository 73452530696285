import React, { useEffect, useState, useRef } from 'react';
import { MaskedInput, InputGroup, Button } from 'rsuite';

const DatePicker = ({ label, startDate, endDate, name, onChange }) => {
  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);

  const [startDateVal, setStartDateVal] = useState(startDate);
  const [endDateVal, setEndDateVal] = useState(endDate);

  useEffect(() => {
    // console.log("startDateVal", startDateVal)
    // console.log("endDateVal", endDate)
    // console.log("name", name)
    onChange({ 0: startDateVal, 1: endDateVal });
  }, [startDateVal, endDateVal]);

  return (
    <div className="range-container pb-3">
      <p className="tv-multiselect_label pb-1">{label}</p>
      <InputGroup>
        <MaskedInput
          block="true"
          id="initial-date-input"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          guide={true}
          showMask={true}
          keepCharPositions={false}
          placeholder={'__/__/____'}
          value={startDateVal}
          onChange={(value) => {
            setStartDateVal(value);
            startDateRef.current = value;
          }}
        />
        <InputGroup.Addon>até</InputGroup.Addon>
        <MaskedInput
          block="true"
          id="final-date-input"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          guide={true}
          showMask={true}
          keepCharPositions={false}
          placeholder={'__/__/____'}
          value={endDateVal}
          onChange={(value) => {
            setEndDateVal(value);
            endDateRef.current = value;
          }}
        />
      </InputGroup>
    </div>
  );
};

export default DatePicker;
