import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCafH3kZcEA1KNJrhSHiJq9gWiHrz_WAjg',
  authDomain: 'turivius-academy.firebaseapp.com',
  projectId: 'turivius-academy',
  storageBucket: 'turivius-academy.appspot.com',
  messagingSenderId: '662902834380',
  appId: '1:662902834380:web:4566a953d8495cd1f46e69',
  measurementId: 'G-C9BK1ZSHKE',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default (app, db);
