import { ArrowRight2, CloseCircle } from 'iconsax-react';
import { Form, Uploader, MaskedInput } from 'rsuite';
import { Button } from '../../../ds-components';

const Step1 = ({
  formRef,
  setPhone,
  email,
  phone,
  formError,
  loading,
  handleSubmit,
  setFormError,
  setUploading,
  previewFile,
}) => {
  const masks = [
    '+',
    '5',
    '5',
    '(',
    /[1-9]/,
    /[1-9]/,
    ')',
    ' ',
    /[1-9]/,
    /[1-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
  return (
    <Form
      fluid
      ref={formRef}
      onCheck={setFormError}
      formError={formError}
      disabled={loading}
      onSubmit={handleSubmit}
    >
      <Form.Group>
        <Form.ControlLabel>Qual seu email corporativo?</Form.ControlLabel>
        <Form.Control size="lg" name="email" value={email} disabled />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>Qual seu telefone?</Form.ControlLabel>
        <Form.Control
          size="lg"
          name="phone"
          onChange={setPhone}
          value={phone}
          accepter={MaskedInput}
          mask={masks}
          placeholder={phone ? phone : 'Insira seu telefone'}
        />
      </Form.Group>

      <div className="upload">
        Insira uma imagem de perfil
        <Uploader
          accept=".png, .jpg, .jpeg"
          fileListVisible={false}
          action=""
          onUpload={(file) => {
            setUploading(true);

            previewFile(file.blobFile, (value) => {
              //setFileInfo(value);
            });
          }}
          onError={() => {
            console.log('error');
          }}
        >
          <button type="button">+ Enviar imagem</button>
        </Uploader>
      </div>

      <Button
        type="submit"
        appearance="primary"
        loading={loading}
        icon={<ArrowRight2 size="18" color="#FFF" />}
        iconPosition={'left'}
      >
        Avançar
      </Button>
      <Button
        className="mt-5"
        onClick={() => (window.location.href = '/')}
        type="button"
        appearance="minimal"
        icon={<CloseCircle size="18" color="#d0d5dd" />}
        iconPosition={'left'}
      >
        Preencher outra hora
      </Button>
    </Form>
  );
};

export default Step1;
