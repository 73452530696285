import React from 'react';
import { Button, Message, toaster, Whisper, Tooltip, IconButton } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faDownload,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import { Turivius } from '../../../../../../ui';
import { posthog } from '../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../services/hubspot';

class CardActions extends React.Component {
  state = {
    floating: window.detectMob() && this.props.responsive,
    citeText: '',
  };

  updateButtons = () => {
    this.setState({
      ...this.state,
      floating: window.detectMob() && this.props.responsive,
    });
  };

  componentDidMount() {
    const { card, entity } = this.props;
    window.addEventListener('resize', this.updateButtons);
    let citeText = `${this.strip(card.content[0].content)}\n\n(${
      card.nome ? card.nome + '.' : ''
    } ${entity ? 'Órgão: ' + entity.name + '. ' : ''}${
      card.relator ? 'Rel. ' + card.relator + '.' : ''
    } ${card.data_jul ? card.data_jul + '.' : ''} ${
      card.data_pub ? card.data_pub + '.' : ''
    })`;
    this.setState({ citeText });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateButtons);
  }

  showMessage = (message) => {
    toaster.push(
      <Message duration={4000} showIcon closable>
        {message}
      </Message>,
    );
  };

  reload = () => {
    const current = this.props.location.pathname;
    this.props.history.replace(`/reload`);
    setTimeout(() => {
      this.props.history.replace(current);
    }, 100);
  };

  strip = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  copyToClipboard = () => {
    posthog.capture(this.props.context, {
      action: 'copy_to_clipboard',
      id: this.props.card.id,
    });
    postHubSpotEvent('app_copytoclipboard', {
      app_copytoclipboard: this.props.card.id,
    });
    this.showMessage('Copiado para a área de transferência');
  };

  openIntegra = () => {
    window.open(this.props.card.url, '_blank');
    posthog.capture('open_integra', {
      action: 'open_integra',
      id: this.props.card.id,
      entity: this.props?.entity.id,
    });
    postHubSpotEvent('app_openintegra', {
      app_openintegra: this.props.card.id,
    });
  };

  openCommentsModal = () => {
    const { card } = this.props;
    let elem = document.getElementById(`mobile-open-comment-modal-${card.id}`);
    elem?.click();
  };

  render() {
    const { floating, citeText } = this.state;
    const { card, collectionId, share, download, cite } = this.props;
    return (
      <>
        {share ? null : null}

        {download && card.url ? (
          <IconButton
            style={{ marginBottom: 5, textOverflow: 'ellipsis' }}
            className={`action-button expand reverse`}
            icon={<FontAwesomeIcon className="rs-icon" icon={faDownload} />}
            onClick={this.openIntegra}
          >
            {!floating ? 'Íntegra' : 'Íntegra'}
          </IconButton>
        ) : (
          <Whisper
            trigger="hover"
            placement="bottom"
            speaker={
              <Tooltip>
                A íntegra ainda não está disponível.
                <br />É uma decisão importante?
                <br />
                Entre em contato pelo botão AJUDA abaixo.
              </Tooltip>
            }
          >
            <div className="action-button-disabled">
              <Turivius.TuriviusIconButton
                style={{ marginBottom: 5, textOverflow: 'ellipsis' }}
                className={`action-button ${floating ? 'floating' : ''}`}
                icon={<FontAwesomeIcon className="rs-icon" icon={faDownload} />}
                disabled
              >
                {!floating ? 'Íntegra' : 'Íntegra'}
              </Turivius.TuriviusIconButton>
            </div>
          </Whisper>
        )}

        {cite ? (
          <CopyToClipboard
            text={citeText}
            onCopy={() => this.copyToClipboard()}
            options={{ format: 'text/plain' }}
          >
            <Turivius.TuriviusIconButton
              style={{ marginBottom: 5, textOverflow: 'ellipsis' }}
              className={`action-button expand reverse`}
              icon={<FontAwesomeIcon className="rs-icon" icon={faQuoteRight} />}
            >
              {!floating ? 'Citar' : 'Citaraaa'}
            </Turivius.TuriviusIconButton>
          </CopyToClipboard>
        ) : null}

        {card && card.id && collectionId && floating ? (
          <Button
            className={`action-button expand reverse`}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            floating={floating}
            node="button"
            onClick={this.openCommentsModal}
            small
          >
            {!floating ? 'Comentários' : 'Comentários'}{' '}
            <FontAwesomeIcon icon={faComment} />
          </Button>
        ) : null}
      </>
    );
  }
}

export default withRouter(CardActions);
