import { Button } from '../../../../ds-components';
import { ArrowRight2 } from 'iconsax-react';

const UseCaseCard = ({ icon, title, description, onSelectCase }) => {
  return (
    <div className="tv-card use-case">
      <div className="use-case_header">
        {icon}
        <p className="pt-3 font-semibold">{title}</p>
      </div>
      <span className="py-4 mb-2">{description}</span>
      <Button
        appearance="primary"
        size="sm"
        icon={<ArrowRight2 color="#FFFFFF" size={16} />}
        iconPosition="right"
        onClick={onSelectCase}
      >
        Buscar
      </Button>
    </div>
  );
};
export default UseCaseCard;
