import { useState, useEffect } from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

const TaxFilters = ({
  filters,
  onDecisionOptionChange,
  onTypeOptionChange,
  updateSelectedFilters,
  resultById,
}) => {
  const [isDecisionOpen, setIsDecisionOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [toggleSessionOpen, setToggleSessionOpen] = useState(false);

  const [selectedDecisionOptions, setSelectedDecisionOptions] = useState([
    1, 2, 3, 4,
  ]);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);
  const [selectedSessionOptions, setSelectedSessionOptions] = useState([]);

  const toggleDecision = () => setIsDecisionOpen(!isDecisionOpen);
  const toggleType = () => setIsTypeOpen(!isTypeOpen);
  const SessionOpen = () => setToggleSessionOpen(!toggleSessionOpen);
  const filterFavorability = (filters && filters[3]) || {};
  const filterVote = (filters && filters[4]) || {};
  const filterCarf = (filters && filters[6]) || {};

  useEffect(() => {
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      if (key === 'filters') {
        const updatedFilters = JSON.parse(value);
        if (updatedFilters) {
          setSelectedDecisionOptions(
            updatedFilters.filter_by_favorability?.map((item) => item),
          );
          setSelectedTypeOptions(
            updatedFilters.filter_by_vote?.map((item) => item),
          );
          setSelectedSessionOptions(
            updatedFilters.filter_by_secao_carf?.map((item) => item),
          );
        }
      }
      originalSetItem.apply(this, arguments);
    };

    return () => {
      localStorage.setItem = originalSetItem;
    };
  }, []);

  const updateURLParams = (paramName, values) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const filters = JSON.parse(params.get('filters') || '{}');
      filters[paramName] = values;
      params.set('filters', JSON.stringify(filters));
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`,
      );
    } catch (error) {
      console.error('Erro ao atualizar os parâmetros da URL:', error);
    }
  };

  const handleCheckboxChange = (id, filterType) => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal) || {};

    if (filterType === 'decision') {
      const newSelected = selectedDecisionOptions?.includes(id)
        ? selectedDecisionOptions.filter((option) => option !== id)
        : [...selectedDecisionOptions, id];

      setSelectedDecisionOptions(newSelected);
      updateSelectedFilters({ decision: newSelected });
      onDecisionOptionChange?.(id);

      filtersLocal.filter_by_favorability = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_favorability', newSelected);
    } else if (filterType === 'type') {
      const currentTypeOptions = Array.isArray(selectedTypeOptions)
        ? selectedTypeOptions
        : [];

      const newSelected = currentTypeOptions.includes(id)
        ? currentTypeOptions.filter((option) => option !== id)
        : [...currentTypeOptions, id];

      setSelectedTypeOptions(newSelected);
      updateSelectedFilters({ type: newSelected });
      onTypeOptionChange?.(id);

      filtersLocal.filter_by_vote = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_vote', newSelected);
    } else if (filterType === 'session') {
      const currentSessionOptions = Array.isArray(selectedSessionOptions)
        ? selectedSessionOptions
        : [];
      const newSelected = currentSessionOptions.includes(id)
        ? currentSessionOptions.filter((option) => option !== id)
        : [...currentSessionOptions, id];

      setSelectedSessionOptions(newSelected);
      updateSelectedFilters({ session: newSelected });

      filtersLocal.filter_by_secao_carf = newSelected;
      localStorage.setItem('filters', JSON.stringify(filtersLocal));
      updateURLParams('filter_by_secao_carf', newSelected);
    }
  };

  useEffect(() => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal) || {};
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = JSON.parse(params.get('filters') || '{}');

    if (filtersFromUrl.filter_by_favorability) {
      setSelectedDecisionOptions(filtersLocal.filter_by_favorability || []);
    }

    if (resultById?.filter_by_favorability) {
      setSelectedDecisionOptions(resultById.filter_by_favorability || []);
    }

    if (filtersFromUrl.filter_by_vote) {
      setSelectedTypeOptions(filtersLocal.filter_by_vote || []);
    }

    if (resultById?.filter_by_vote) {
      setSelectedTypeOptions(resultById.filter_by_vote || []);
    }

    if (filtersFromUrl.filter_by_secao_carf) {
      setSelectedSessionOptions(filtersLocal.filter_by_secao_carf || []);
    }

    if (resultById?.filter_by_secao_carf) {
      setSelectedSessionOptions(resultById.filter_by_secao_carf || []);
    }
  }, [resultById]);

  return (
    <div className="bg-gray-50 p-4 rounded-lg shadow w-full font-sans">
      <div className="text-[#101828] font-semibold">Filtros Tributários</div>
      <div
        onClick={toggleDecision}
        className="flex justify-between items-center cursor-pointer py-2 border-b"
      >
        <span className="flex gap-2 items-center">
          {isDecisionOpen ? (
            <ArrowUp2 color="#98A2B3" />
          ) : (
            <ArrowDown2 color="#98A2B3" />
          )}
          {filterFavorability.public_name || 'Resultado da decisão'}
        </span>
      </div>

      {isDecisionOpen && (
        <div className="mt-2">
          {filterFavorability.options?.map((item, index) => (
            <div
              key={index}
              className="item-collapse-entities"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <label className="flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedDecisionOptions?.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id, 'decision')}
                />

                <span>{item.name}</span>
              </label>
            </div>
          ))}
        </div>
      )}

      <div
        onClick={toggleType}
        className="flex justify-between items-center cursor-pointer py-2 border-b"
      >
        <span className="flex gap-2 items-center">
          {isTypeOpen ? (
            <ArrowUp2 color="#98A2B3" />
          ) : (
            <ArrowDown2 color="#98A2B3" />
          )}
          {filterVote.public_name} (CARF)
        </span>
      </div>

      {isTypeOpen && (
        <div className="mt-2">
          {filterVote.options?.map((item) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              key={item.id}
              className="item-collapse-entities"
            >
              <label className="flex items-center gap-2 p-2 ">
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedTypeOptions?.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id, 'type')}
                  className="w-4 h-4 cursor-pointer rounded-full border-[4px] border-gray-300"
                />
                {item.name}
              </label>
            </div>
          ))}
        </div>
      )}
      <div
        onClick={SessionOpen}
        className="flex justify-between items-center cursor-pointer py-2 border-b"
      >
        <span className="flex gap-2 items-center">
          {toggleSessionOpen ? (
            <ArrowUp2 color="#98A2B3" />
          ) : (
            <ArrowDown2 color="#98A2B3" />
          )}
          Sessão (CARF)
        </span>
      </div>
      {toggleSessionOpen && (
        <div className="mt-2">
          {filterCarf.options?.map((item, index) => (
            <div
              key={index}
              className="item-collapse-entities"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <label className="flex items-center gap-2 p-2">
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedSessionOptions?.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id, 'session')}
                />

                <span>{item.name}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TaxFilters;
