import { useQuery, useMutation } from '@tanstack/react-query';
import api from './api';
import {
  GoogleLoginRequest,
  GoogleLoginResponse,
  OauthGoogleResponse,
  EmailTokenRequest,
  EmailTokenResponse,
  TokenLoginRequest,
  TokenLoginResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangePasswordWithCodeRequest,
  ChangePasswordWithCodeResponse,
  OauthMicrosoftResponse,
  OauthMicrosoftRequest,
  CheckPasswordHashRequest,
  CheckPasswordHashResponse,
  OauthMicrosoftLoginRequest,
  OauthMicrosoftLoginResponse,
} from './types';

const endpoints = {
  oauthGoggle: () => 'platform/oauth/google/',
  goggleLogin: () => 'platform/login_code/validate/',
  emailToken: () => 'platform/login_code/',
  tokenLogin: () => 'platform/login_code/validate/',
  changePassword: () => 'platform/change_password_code/',
  changePasswordWithCode: () => 'platform/change_password_with_code/',
  oauthMicrosoft: () => 'platform/oauth/microsoft/callback/',
  oauthMicrosoftLogin: () => 'platform/login_code/validate/',
  checkPasswordHash: () => 'platform/new_login/',
};

const authKeys = {
  all: ['auth'] as const,
  oauthGoogles: () => [...authKeys.all, 'oauthGoogle'] as const,
  oauthGoggle: () => [...authKeys.oauthGoogles()] as const,
  googleLogins: () => [...authKeys.all, 'googleLogin'] as const,
  googleLogin: ({ code, email }: GoogleLoginRequest) =>
    [...authKeys.googleLogins(), code, email] as const,
  emailTokens: () => [...authKeys.all, 'emailToken'] as const,
  emailToken: (email: string) => [...authKeys.emailTokens(), email] as const,
  tokenLogins: () => [...authKeys.all, 'tokenLogin'] as const,
  tokenLogin: ({ code, email }: TokenLoginRequest) =>
    [...authKeys.tokenLogins(), code, email] as const,
  changePasswords: () => [...authKeys.all, 'changePassword'] as const,
  changePassword: (email: string) =>
    [...authKeys.changePasswords(), email] as const,
  changePasswordWithCodes: () =>
    [...authKeys.all, 'changePasswordWithCode'] as const,
  changePasswordWithCode: ({
    code,
    email,
    new_password,
  }: ChangePasswordWithCodeRequest) =>
    [...authKeys.changePasswordWithCodes(), code, email, new_password] as const,
  oauthMicrosofts: () => [...authKeys.all, 'oauthMicrosoft'] as const,
  oauthMicrosoft: (email: string) =>
    [...authKeys.oauthMicrosofts(), email] as const,
};

const authApi = {
  useOauthGoogle: () =>
    useQuery({
      queryKey: authKeys.oauthGoggle(),
      queryFn: async () => {
        const response = await api.get<OauthGoogleResponse>(
          endpoints.oauthGoggle(),
        );
        return response.data;
      },
    }),
  useGoogleLogin: () =>
    useMutation({
      mutationFn: async ({ code, email }: GoogleLoginRequest) => {
        const response = await api.post<GoogleLoginResponse>(
          endpoints.goggleLogin(),
          {
            code,
            email,
          },
        );
        return response;
      },
    }),
  useSendEmailToken: () =>
    useMutation({
      mutationFn: async ({ email }: EmailTokenRequest) => {
        const response = await api.post<EmailTokenRequest, EmailTokenResponse>(
          endpoints.emailToken(),
          { email },
        );
        return response;
      },
    }),
  useTokenLogin: () =>
    useMutation({
      mutationFn: async ({ code, email }: TokenLoginRequest) => {
        const response = await api.post<TokenLoginResponse>(
          endpoints.tokenLogin(),
          {
            code,
            email,
          },
        );
        return response;
      },
    }),
  useChangePassword: () =>
    useMutation({
      mutationFn: async ({ email }: ChangePasswordRequest) => {
        const response = await api.post<
          ChangePasswordRequest,
          ChangePasswordResponse
        >(endpoints.changePassword(), {
          email,
        });
        return response;
      },
    }),
  useChangePasswordWithCode: () =>
    useMutation({
      mutationFn: async ({
        code,
        email,
        new_password,
      }: ChangePasswordWithCodeRequest) => {
        const response = await api.post<
          ChangePasswordWithCodeRequest,
          ChangePasswordWithCodeResponse
        >(endpoints.changePasswordWithCode(), {
          code,
          email,
          new_password,
        });
        return response;
      },
    }),
  useOauthMicrosoft: () =>
    useMutation({
      mutationFn: async ({ email }: OauthMicrosoftRequest) => {
        const response = await api.post<OauthMicrosoftResponse>(
          endpoints.oauthMicrosoft(),
          {
            email,
          },
        );
        return response;
      },
    }),
  useOauthMicrosoftLogin: () =>
    useMutation({
      mutationFn: async ({ code, email }: OauthMicrosoftLoginRequest) => {
        const response = await api.post<OauthMicrosoftLoginResponse>(
          endpoints.oauthMicrosoftLogin(),
          {
            code,
            email,
          },
        );
        return response;
      },
    }),
  useCheckEmailHash: () =>
    useMutation({
      mutationFn: async ({ password, hash }: CheckPasswordHashRequest) => {
        const response = await api.post<
          CheckPasswordHashRequest,
          CheckPasswordHashResponse
        >(endpoints.checkPasswordHash(), {
          password,
          hash,
        });
        return response.data;
      },
    }),
};

export const {
  useOauthGoogle,
  useGoogleLogin,
  useSendEmailToken,
  useTokenLogin,
  useChangePassword,
  useChangePasswordWithCode,
  useOauthMicrosoft,
  useOauthMicrosoftLogin,
  useCheckEmailHash,
} = authApi;
