import { Button } from '../../../../ds-components';
import FilterWrapper from '../../core/Filters/FilterWrapper';

const SubjectFiltersContainer = ({
  filters,
  subjectFilter,
  handleFilter,
  tempFilters,
  setTempFilters,
}) => {
  return (
    <div className="tv-filters_list filter-dash mt-3 mb-5">
      <h6 className=" tv-filters_title font-bold pb-3 text-color_black">
        Filtros
      </h6>
      <div className="px-4">
        {filters?.map((filter, index) => {
          return (
            <FilterWrapper
              filter={filter}
              key={index}
              filters={subjectFilter}
              setTempFilters={setTempFilters}
              tempFilters={tempFilters}
            />
          );
        })}
      </div>
      <div className="tv-filters_footer filter-dash_footer">
        <Button
          size="md"
          fullWidth
          onClick={() => {
            handleFilter(tempFilters);
          }}
        >
          Filtrar
        </Button>
      </div>
    </div>
  );
};

export default SubjectFiltersContainer;
