import React from 'react';
import { Turivius } from '../../../../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

class ExpandAllCardsButton extends React.Component {
  render() {
    const { expanded, toggleExpanded } = this.props;
    return (
      <Turivius.TuriviusIconButton
        fullWidth
        style={{ borderRadius: 4 }}
        icon={
          !expanded ? (
            <FontAwesomeIcon className="rs-icon" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="rs-icon" icon={faAngleUp} />
          )
        }
        className="expand-more-button"
        onClick={toggleExpanded}
      >
        {!expanded ? 'Expandir Resultados' : 'Restaurar Resultados'}
      </Turivius.TuriviusIconButton>
    );
  }
}

export default ExpandAllCardsButton;
