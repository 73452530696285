import db from '../config/fire-config';
import {
  getDocs,
  doc,
  updateDoc,
  collection,
  addDoc,
  query,
  where,
} from 'firebase/firestore';
import { getAuth, reauthenticateWithCredential } from 'firebase/auth';

export const getByUser = async (userId) => {
  const infoRef = collection(db, 'checklist');
  const q = query(infoRef, where('user', '==', userId));

  const querySnapshot = await getDocs(q);
  var result = null;
  querySnapshot.forEach((doc) => {
    var obj = doc.data();
    obj.id = doc.id;
    result = obj;
  });
  return result;
};

export const createByUser = async (data) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ('vitor@turivius.com', 'Turivius@2023');

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
  const docRef = await addDoc(collection(db, 'checklist'), data);
  return docRef.id;
};

export const updateCheckllist = async (id, key) => {
  const auth = getAuth();
  const user = auth.currentUser;
  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ('vitor@turivius.com', 'Turivius@2023');

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });

  const check = JSON.parse(localStorage.getItem('checklist'));
  const notify = JSON.parse(localStorage.getItem('checklistNotify'));
  const data = check.trail;
  data[key] = true;
  const newData = {
    id: check.id,
    user: Number(check.user),
    trail: data,
  };

  const docRef = doc(db, 'checklist', id);
  try {
    await updateDoc(docRef, newData);
    localStorage.setItem('checklist', JSON.stringify(newData));
    localStorage.setItem(
      'checklistNotify',
      JSON.stringify({
        new: true,
        amount: notify?.amount + 1,
      }),
    );
    window.dispatchEvent(new Event('checklist_notify'));
    return docRef.id;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(errorCode + ' ' + errorMessage);
  }
};

export const setCheckedTour = async (moduleTour) => {
  //   console.log(moduleTour);
  const check = JSON.parse(localStorage.getItem('tour'));
  await updateTour(check.id, moduleTour);
};

export const updateTour = async (id, key) => {
  const auth = getAuth();
  const user = auth.currentUser;
  // TODO(you): prompt the user to re-provide their sign-in credentials
  const credential = ('vitor@turivius.com', 'Turivius@2023');

  reauthenticateWithCredential(user, credential)
    .then(() => {
      // User re-authenticated.
    })
    .catch((error) => {
      // An error ocurred
      // ...
    });
  const tour = JSON.parse(localStorage.getItem('tour'));
  const data = tour.tour;
  data[key] = true;

  const newData = {
    id: tour.id,
    user: Number(tour.user),
    tour: data,
  };

  const docRef = doc(db, 'checklist', id);
  try {
    await updateDoc(docRef, newData);
    localStorage.setItem('tour', JSON.stringify(newData));
    return docRef.id;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(errorCode + ' ' + errorMessage);
  }
};
