import React from 'react';
import { Toggle, MultiCascader, Message } from 'rsuite';

const SelectEntitiesMonitoring = ({
  setSelectedEntities,
  setAllEntities,
  allEntities,
  groupArray,
  selectedEntities,
  changeValue,
  entities,
}) => {
  return (
    <div className="monitoring-settings-content">
      <div className="monitoring-settings-content_toggle">
        <Toggle
          size="lg"
          name="all_entities"
          onChange={(value) => {
            if (value) {
              setSelectedEntities([]);
            }
            setAllEntities(value);
          }}
          checked={allEntities}
          checkedChildren={<i className="ri-check-line" />}
          unCheckedChildren={<i className="ri-close-fill" />}
        />
        <label>Monitorar todos os órgãos</label>
      </div>

      {allEntities === false ? (
        <div>
          <div className="monitoring-settings-content">
            <label className="monitoring-settings-label">
              Selecione os órgãos
            </label>
            <MultiCascader
              block
              data={groupArray(entities, 'class')}
              placeholder="Selecione os órgãos"
              onChange={(value) => changeValue(value)}
              value={selectedEntities}
              menuWidth={200}
            />
          </div>
          <Message showIcon type="info">
            Selecione todos os ógãos que deseja receber informações de
            monitoramento
          </Message>
        </div>
      ) : null}
    </div>
  );
};

export default SelectEntitiesMonitoring;
