const disconnect_steps = [
  {
    headerColor: 'header-red',
    title: 'Desconecte sua conta de um dos dispositivos abaixo para continuar.',
    button: 'Voltar',
    action: 'back',
    icon: 'ri-arrow-left-s-line',
  },
  {
    headerColor: 'header-yellow',
    title: 'Confirmar desconexão do dispositivo',
    button: 'Confirmar',
    action: 'disconnect',
    icon: 'ri-arrow-right-s-line',
  },
  {
    headerColor: 'header-green',
    title: 'Sucesso!',
    button: 'Ir para o software',
    action: 'close',
    icon: 'ri-arrow-right-s-line',
  },
];

export default disconnect_steps;
