import { useEffect } from 'react';
import SearchNewJurimetrics from './SearchNewJurimetrics';
import SearchJurimetrics from './SearchJurimetrics';
import { Turivius } from '../../../../../ui';
import { Col, Row } from 'rsuite';

const JurimetricsCenter = ({
  searchPlots,
  requesting,
  noJurimetricEntities,
  searchPlotsFunc,
  dataQuery,
  countResults,
  entities,
}) => {
  useEffect(() => {
    searchPlotsFunc(dataQuery);
  }, []);

  return countResults && countResults > 0 ? (
    requesting ? (
      <Row>
        <Col xs={24} sm={24} style={{ height: 150 }}>
          <Turivius.TuriviusPreloader />
        </Col>
      </Row>
    ) : (
      <>
        <SearchNewJurimetrics
          searchPlots={searchPlots}
          requesting={requesting}
          entities={entities}
          noJurimetricEntities={noJurimetricEntities}
          countResults={countResults}
          searchPlotsFunc={searchPlotsFunc}
          dataQuery={dataQuery}
        />
        <SearchJurimetrics
          searchPlots={searchPlots}
          requesting={requesting}
          entities={entities}
          noJurimetricEntities={noJurimetricEntities}
          countResults={countResults}
          searchPlotsFunc={searchPlotsFunc}
          dataQuery={dataQuery}
        />
      </>
    )
  ) : null;
};

export default JurimetricsCenter;
