import React from 'react';
import './NoJurimetrics.scss';

const NoJurimetrics = ({ jurimetricEntities }) => {
  return jurimetricEntities ? (
    <p className="no-jurimetrics">
      Nenhuma jurimetria contratada para os órgãos da busca
    </p>
  ) : (
    <p className="no-jurimetrics">
      Não tivemos dados suficientes para gerar a Jurimetria desta busca.
    </p>
  );
};

export default NoJurimetrics;
