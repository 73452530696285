import { useEffect, useState, useCallback } from 'react';
import { InputWithIcon } from '../../../../components/TuriviusComponents/Inputs';
import { ArrowLeft, DirectboxSend, Lock } from 'iconsax-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useChangePassword, useCheckEmailHash } from '../../../../api/auth';
import { queryStringToObject } from '../../../../utils/query';
import { Button } from '../../../../components/TuriviusComponents/Buttons';
import { onFirstLogIn } from '../../../../services/authentication';
import TuriviusPreloader from '../../../../ui/loaders/TuriviusPreloader';
import { toast } from 'react-toastify';

export const FirstLoginComponent = (props) => {
  const { replace } = useHistory();
  const location = useLocation();

  const query = queryStringToObject(location.search);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { mutateAsync, isPending } = useCheckEmailHash();
  const {
    mutateAsync: mutateAsyncChangePassword,
    isPending: isPendingChangePassword,
    isSuccess: isSuccessChangePassword,
  } = useChangePassword();

  const saveNewPassword = async () => {
    setError(false);
    setErrorMessage('');
    if (newPassword.length < 8 || newPasswordConfirm < 8) {
      setError(true);
      return setErrorMessage('A senha precisa ter no mínimo 8 dígitos.');
    }
    if (newPassword === newPasswordConfirm) {
      try {
        const response = await mutateAsync({
          password: newPassword,
          hash: query.hash,
        });
        onFirstLogIn(response, setLoading, props);
      } catch (error) {
        setError(true);
        setLoading(false);
        switch (error.response.status) {
          case 400:
            setErrorMessage('O token expirou.');
            break;
          default:
            setErrorMessage(
              'Houve um problema de conexão, tente novamente em instantes',
            );
        }
      }
    } else {
      setError(true);
      setErrorMessage('As senhas precisam ser iguais nos dois campos');
    }
  };

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      saveNewPassword();
    }
  };

  const makePasswordRedefinition = useCallback(async () => {
    try {
      const email = query.email;
      await mutateAsyncChangePassword({ email });
      toast(
        <span className="gap-4 flex items-center">
          {' '}
          <DirectboxSend color="#12B76A" size={24} /> E-mail enviado para{' '}
          {email}
        </span>,
        {
          style: {
            right: 85,
          },
          progressStyle: {
            background: '#053AC8',
          },
          className: 'w-96',
          position: 'bottom-right',
        },
      );
    } catch (error) {
      switch (error.status) {
        case 400:
          toast.error(
            'Não foi possível alterar sua senha. Verifique as informações etente novamente.',
          );
        default:
          toast.error('Houve um problema de comunicação, tente novamente');
      }
    }
  }, [query.email]);

  useEffect(() => {
    if (!query?.email || !query?.hash) return replace('/login');
  }, [location]);

  return (
    <div className="login-component">
      {loading && <TuriviusPreloader />}

      {!loading && isSuccessChangePassword && (
        <div>
          <div className="gap-2 flex mb-11">
            <ArrowLeft
              className="cursor-pointer"
              color="#0D91FE"
              onClick={() => replace('/login')}
            />
            <span
              className="cursor-pointer text-[#0069F5]"
              onClick={() => replace('/login')}
            >
              Voltar ao login
            </span>
          </div>

          <div className="login-component_header font-rubik">
            <h2>Redefinição de senha</h2>
          </div>

          <p className="max-w-[430px]" style={{ margin: '18px 0' }}>
            Enviamos um e-mail para você. Siga as instruções para redefinir sua
            senha.
          </p>
          <div className="flex justify-start" style={{ marginTop: 11 }}>
            <span className="text-sm leading-5" style={{ color: '#101828' }}>
              Se ainda precisar de ajuda,{' '}
              <a
                href="mailto:contato@turivius.com"
                className="cursor-pointer"
                style={{ color: '#0069f5' }}
              >
                fale com nosso suporte
              </a>
            </span>
          </div>
        </div>
      )}

      {!loading && !isSuccessChangePassword && (
        <>
          <div className="login-component_header">
            <h2>Estamos quase lá. Vamos configurar sua senha.</h2>
            <p>Insira uma nova senha para a sua conta:</p>
          </div>

          <div className="login-component_body">
            <label htmlFor="email">Nova senha</label>

            <InputWithIcon
              id={'password'}
              type={'password'}
              icon={Lock}
              placeholder={'Insira sua nova senha'}
              onChange={(value) => setNewPassword(value)}
              value={newPassword}
              style={{ marginBottom: 24 }}
              onKeyUp={onPressEnter}
            />

            <label htmlFor="email">Repita a senha</label>

            <InputWithIcon
              id={'repassword'}
              type={'password'}
              icon={Lock}
              placeholder={'Repita a sua nova senha'}
              onChange={(value) => setNewPasswordConfirm(value)}
              value={newPasswordConfirm}
              style={{ marginBottom: 10 }}
              onKeyUp={onPressEnter}
            />

            {error && (
              <span
                className="text-xs font-normal mt-2 mb-[13px]"
                style={{ color: '#F04438' }}
              >
                {errorMessage}
              </span>
            )}

            <Button
              loading={isPending}
              disabled={isPending}
              appearance="default"
              onClick={() => saveNewPassword()}
            >
              Salvar nova senha
            </Button>

            <div className="flex gap-1 items-center mt-[13px]">
              Token expirado?{' '}
              <span
                className="cursor-pointer text-[#0069F5]"
                onClick={makePasswordRedefinition}
                disabled={isPendingChangePassword}
              >
                Solicite um novo
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
