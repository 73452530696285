import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import { toaster, Message } from 'rsuite';
import { posthog, requester } from '../../../../requesters';
import './Profiles.scss';
import ChangePassword from '../components/ChangePassword';
import PersonalData from '../components/PersonalData';
import ProfileHeader from '../components/Header';
import TuriviusPreloader from '../../../../app/TuriviusPreloader';

const Profile = (props) => {
  const [requesting, setRequesting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [company, setCompany] = useState(null);

  const disabled = status === 'disabled';

  useEffect(() => {
    document.title = 'Meu Perfil';
    getProfile();
    posthog.capture('$pageview');
  }, []);

  const getProfile = async () => {
    setRequesting(true);
    await requester.profiles
      .getById(localStorage.getItem('user'))
      .then((response) => {
        posthog.identify(response.id);
        posthog.people.set({
          email: response.email,
          company: response.company.id,
          company_name: response.company.name,
          name: response.name,
          is_trial: response.company.asaas_url.match(
            '^https://www.asaas.com/.*',
          ),
        });

        if (window.satismeter) {
          window.satismeter({
            writeKey: 'BOPCaSo62Birj8Id',
            userId: response.id,
            traits: {
              name: response.name,
              email: response.email,
              createdAt: response.created,
              company: response.company.id,
              companyName: response.company.name,
              active: response.active,
            },
          });
        }

        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));

        setCompany(response.company);
        props.setCompany(response.company);
        setProfile(response);
        props.setProfile(response);
        props.setPermissions(response?.permissions);
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const changePassword = (data, onSuccessAction) => {
    setPasswordLoading(true);
    requester.authentication
      .changePassword(
        localStorage.getItem('user'),
        data.old_password,
        data.new_password,
        'Profiles',
      )
      .then((response) => {
        if (onSuccessAction) {
          onSuccessAction();
        }
        toaster.push(
          <Message
            header="Senha alterada com sucesso"
            duration={4000}
            type="success"
            showIcon
            closable
          ></Message>,
        );
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 400) {
          toaster.push(
            <Message
              header="Sua senha antiga está incorreta"
              duration={4000}
              type="error"
              showIcon
              closable
            >
              Verifique os campos e tente novamente.
            </Message>,
          );
        } else {
          toaster.push(
            <Message
              header="Houve um problema de conexão, tente novamente"
              duration={4000}
              type="error"
              showIcon
              closable
            ></Message>,
          );
        }
      })
      .finally(() => {
        setPasswordLoading(false);
      });
  };

  const patchProfile = (id, data, onSuccessAction) => {
    setLoading(true);
    requester.profiles
      .updateProfile(
        id,
        data.name,
        data.email,
        data.position,
        data.short_bio,
        data.linkedin_link,
        'Profiles',
        data.phone,
      )
      .then((response) => {
        if (onSuccessAction) {
          onSuccessAction();
        }
        toaster.push(
          <Message
            header="Perfil atualizado com sucesso"
            duration={4000}
            type="success"
            showIcon
            closable
          ></Message>,
        );
      })
      .catch((err) => {
        toaster.push(
          <Message
            header="Houve um problema de conexão, tente novamente"
            duration={4000}
            type="error"
            showIcon
            closable
          ></Message>,
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const patchImage = (photo) => {
    setLoading(true);
    requester.profiles
      .updateProfileImage(localStorage.getItem('user'), photo)
      .then((response) => {
        getProfile();
        toaster.push(
          <Message
            header="Dados atualizados"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Imagem atualizada com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente.
          </Message>,
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'auto',
        paddingTop: 0,
        backgroundColor: '#fff',
      }}
    >
      {requesting ? <TuriviusPreloader backdrop /> : null}
      <div className="profile-item">
        {profile ? (
          <>
            <div>
              <ProfileHeader
                profile={profile}
                company={company}
                patchImageMethod={patchImage}
                onSuccessAction={getProfile}
                loading={loading}
                disabled={disabled}
              />
            </div>
            <div>
              <PersonalData
                profile={profile}
                company={company}
                patchProfileMethod={patchProfile}
                onSuccessAction={getProfile}
                loading={loading}
                setLoading={setLoading}
                disabled={disabled}
              />
            </div>
            <div>
              <ChangePassword
                profile={profile}
                company={company}
                changePasswordMethod={changePassword}
                onSuccessAction={getProfile}
                loading={passwordLoading}
                disabled={disabled}
              />
            </div>
            <div>{}</div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profiles.profile,
  company: state.profiles.company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ProfilesActions), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
