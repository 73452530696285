import { Key } from 'iconsax-react';
import { useOauthGoogle } from '../../../../api/auth';
import { useCallback } from 'react';
import { MicrosoftSocialLoginProvider } from '../../../../providers/microsoft-social-login.provider';

const OthersLoginOptions = ({ SSOLogin }) => {
  const { data: oauthGoogleData } = useOauthGoogle();

  const oauthLogin = useCallback(() => {
    const url = oauthGoogleData.redirect_url;
    if (url) window.open(url, '_self');
  }, [oauthGoogleData]);

  return (
    <>
      <button
        className="turivius-button with-icon"
        onClick={oauthLogin}
        disabled={!oauthGoogleData}
      >
        <img src="Icons/Google.png" />
        Continuar com Google
      </button>
      <MicrosoftSocialLoginProvider />
      <button className="turivius-button with-icon" onClick={SSOLogin}>
        <Key size={22} color="#292D32" />
        Continuar com SSO
      </button>
      <small>
        Ao fazer login na plataforma você concorda com os{' '}
        <a href="https://turivius.com/termos-de-uso/">
          <u>termos de uso</u>
        </a>{' '}
        e{' '}
        <a href="https://turivius.com/politica-de-privacidade/">
          <u>politicas de privacidade</u>.
        </a>
      </small>
    </>
  );
};

export default OthersLoginOptions;
