import { Button } from '../../../../ds-components';

const CollectionWithoutResults = ({ filter, onCleanFilter }) => {
  return (
    <div className="collection-without-results">
      <p>
        Nenhum resultado encontrado para a busca <b>"{filter}"</b>
      </p>
      <Button
        size="sm"
        appearance="secondary"
        onClick={() => onCleanFilter()}
        style={{ marginTop: '10px' }}
      >
        Limpar Filtro
      </Button>
    </div>
  );
};

export default CollectionWithoutResults;
