import { useState, useRef } from 'react';
import {
  Button,
  Message,
  toaster,
  Modal,
  IconButton,
  Schema,
  Form,
  InputGroup,
} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requester } from '../../../../../requesters';
import {
  faUser,
  faSuitcase,
  faAt,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { submit_hubspot_form } from '../../../../../services/hubspot';

const userModel = Schema.Model({
  name: Schema.Types.StringType().isRequired('Este campo é obrigatório.'),
  email: Schema.Types.StringType()
    .isEmail('Insira um e-mail válido')
    .isRequired('Este campo é obrigatório.'),
  position: Schema.Types.StringType().isRequired('Este campo é obrigatório'),
});

const NewUserButton = ({ getUsers }) => {
  const userForm = useRef(null);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userFormValue, setUserFormValue] = useState({});
  const [userFormError, setUserFormError] = useState({});
  const [resolutionScreen, setResolutionScreen] = useState(window.innerWidth);

  //Abrir/Fechar Modal de Cadastro
  const handleModal = () => setOpenModal(!openModal);

  const validateAndCreateUser = async () => {
    if (userForm.current.check()) {
      const { name, email, position } = userFormValue;
      setCreating(true);
      requester.profiles
        .createUser(
          name,
          email,
          position,
          '',
          localStorage.getItem('company'),
          'Admin',
        )
        .then((response) => {
          //this.props.fetchCompanyProfiles('requesting')
          getUsers();
          toaster.push(
            <Message duration={4000} type="success" showIcon closable>
              Conta criada com sucesso
            </Message>,
          );
          handleModal();
          const companyObject = JSON.parse(
            localStorage.getItem('profile'),
          ).company;

          submit_hubspot_form(name, email, companyObject);

          const data = {
            event_type: 'CONVERSION',
            event_family: 'CDP',
            payload: {
              name: name,
              email: email,
              mobile_phone: companyObject.cellphone,
              tags: ['new_user'],
              conversion_identifier: 'Account Admin Added New User',
              company_name: companyObject.name,
            },
          };
          requester.reports
            .reportToRDStation(data)
            .then(console.log)
            .catch(console.error);
          requester.reports
            .reportNewAppUser(
              companyObject.id,
              companyObject.name,
              email,
              companyObject.profiles.length,
            )
            .then((response) => {
              // console.log(response);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => {
          console.error(err);
          if (err.status === 409) {
            toaster.push(
              <Message
                header="Ocorreu um erro"
                duration={4000}
                type="error"
                showIcon
                closablea
              >
                {err?.message ||
                  'Houve um problema de conexão, tente novamente'}
              </Message>,
            );
          } else {
            toaster.push(
              <Message
                header="Ocorreu um erro"
                duration={4000}
                type="error"
                showIcon
                closable
              >
                Houve um problema de conexão, tente novamente
              </Message>,
            );
          }
        })
        .finally(() => setCreating(false));
    } else {
      toaster.push(
        <Message
          header="Campos inválidos"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Verifique os campos e tente novamente
        </Message>,
      );
    }
  };

  return (
    <div className="modal-container">
      <IconButton
        icon={<FontAwesomeIcon className="rs-icon" icon={faUserPlus} />}
        onClick={() => handleModal()}
        appearance="primary"
        color="blue"
        block
      >
        Criar Usuário
      </IconButton>

      <Modal open={openModal} onClose={() => handleModal()}>
        <Modal.Header>
          <Modal.Title>Adicionar Novo Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="admin-content section-forms">
            <Form
              fluid
              ref={userForm}
              onChange={(userFormValue) => {
                setUserFormValue(userFormValue);
              }}
              onCheck={(userFormError) => {
                setUserFormError(userFormError);
              }}
              formError={userFormError}
              model={userModel}
              readOnly={creating}
            >
              <Form.Group controlId="name">
                <Form.ControlLabel>Nome *</Form.ControlLabel>
                <InputGroup inside style={{ width: '100%' }}>
                  <InputGroup.Addon>
                    <FontAwesomeIcon icon={faUser} />
                  </InputGroup.Addon>
                  <Form.Control
                    name={'name'}
                    placeholder={'Insira o seu nome'}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.ControlLabel>E-mail *</Form.ControlLabel>
                <InputGroup inside style={{ width: '100%' }}>
                  <InputGroup.Addon>
                    <FontAwesomeIcon icon={faAt} />
                  </InputGroup.Addon>
                  <Form.Control
                    name={'email'}
                    placeholder={'Insira o seu e-mail'}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="position">
                <Form.ControlLabel>Cargo *</Form.ControlLabel>
                <InputGroup inside style={{ width: '100%' }}>
                  <InputGroup.Addon>
                    <FontAwesomeIcon icon={faSuitcase} />
                  </InputGroup.Addon>
                  <Form.Control
                    name={'position'}
                    placeholder={'Insira o seu cargo'}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => handleModal()} appearance="subtle">
            Cancelar
          </Button>
          <Button
            onClick={() => validateAndCreateUser()}
            appearance="primary"
            loading={creating}
          >
            Adicionar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewUserButton;
