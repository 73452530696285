import React, { Component } from 'react';
import { Icon } from 'react-materialize';
import { Grid, Row, Col } from 'rsuite';
import { withRouter } from 'react-router-dom';
import { Avatar } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { cutString } from '../../../../../utils/cutString';
import { ArrowRight2 } from 'iconsax-react';

const NotificationItem = ({ onClick, ...props }) => {
  function handleOnClick(e) {
    if (!e.target.classList.contains('notification-link-button')) {
      if (onClick) {
        onClick(props);
      }
    }
  }

  return (
    <div className={`rn-item`} onClick={() => handleOnClick.bind(this)}>
      <Grid fluid>
        <Row style={{ margin: 0 }}>
          <Col
            xs={24}
            sm={24}
            className={`header-col ${props[props.options.new] ? 'new' : ''}`}
          >
            <div>
              {props[props.options.category] && (
                <span className="category">
                  {props[props.options.category]}
                </span>
              )}

              {props[props.options.date] && (
                <span className="time">{props[props.options.date]}</span>
              )}
            </div>
          </Col>

          <Col xs={24} sm={24} className="title-subtitle-col">
            <div style={{ width: '100%' }}>
              {props[props.options.title] && (
                <span className="rn-title">{props[props.options.title]}</span>
              )}
              {props[props.options.subtitle] && (
                <span className="rn-subtitle">
                  {cutString(props[props.options.subtitle], 80)}
                </span>
              )}
            </div>
          </Col>

          <Col xs={24} sm={24} className="action-col">
            {props.actions?.map((action, i) => (
              <button
                key={i}
                onClick={() => {
                  props.onCloseDrawer();
                  props.history.replace(`/reload`);
                  setTimeout(() => {
                    props.history.replace(action.link);
                  }, 100);
                  if (props.onRedirectClick) {
                    props.onRedirectClick(props);
                  }
                }}
              >
                <ArrowRight2 size="20" color="#0069F5" />
                <span>{action.link_name}</span>
              </button>
            ))}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

class NotificationItema extends Component {
  handleOnClick(e) {
    if (!e.target.classList.contains('notification-link-button')) {
      if (this.props.onClick) {
        this.props.onClick(this.props);
      }
    }
  }

  render() {
    return (
      <li
        className={`rn-item ${
          this.props[this.props.options.new] ? 'new' : 'new'
        }`}
        onClick={this.handleOnClick.bind(this)}
      >
        <MuiThemeProvider>
          <Grid fluid>
            <Row style={{ margin: 0 }}>
              <Col xs={24} sm={24} className="header-col">
                <div>
                  <img src="/favicon.svg" alt="turivius_icon" />
                  {this.props[this.props.options.category] && (
                    <span className="category">
                      {this.props[this.props.options.category]}
                    </span>
                  )}
                  <span className="point">·</span>
                  {this.props[this.props.options.date] && (
                    <span className="time">
                      {this.props[this.props.options.date]}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} className="title-subtitle-col">
                <div className="avatar-div">
                  <Avatar>
                    <Icon>post_add</Icon>
                  </Avatar>
                </div>
                <div style={{ width: '100%' }}>
                  {this.props[this.props.options.title] && (
                    <span className="rn-title">
                      {this.props[this.props.options.title]}{' '}
                    </span>
                  )}
                  {this.props[this.props.options.subtitle] && (
                    <span className="rn-subtitle">
                      {this.cutString(
                        this.props[this.props.options.subtitle],
                        80,
                      )}
                    </span>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} className="action-col">
                {this.props.actions?.map((action, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      this.props.closeNotification();
                      this.props.history.replace(`/reload`);
                      setTimeout(() => {
                        this.props.history.replace(action.link);
                      }, 100);
                      if (this.props.onRedirectClick) {
                        this.props.onRedirectClick(this.props);
                      }
                    }}
                  >
                    <div>
                      <span>{action.link_name}</span>
                    </div>
                  </button>
                ))}
              </Col>
            </Row>
          </Grid>
        </MuiThemeProvider>
      </li>
    );
  }
}

export default withRouter(NotificationItem);
