import { Button } from '../../../../ds-components';
import BrazilCloropleth from '../Charts/BrazilCloropleth';
import LineChart from '../Charts/LineChart';
import TreemapChart from '../Charts/TreemapChart';
import BarsChart from '../Charts/BarsChart';
import HighlightCard from './HighlightCard';
import CardWithHeaderActions from '../../../../core/components/Cards/CardWithHeaderActions';
import {
  Dislike,
  ExportSquare,
  Happyemoji,
  Judge,
  LampOn,
  Like1,
} from 'iconsax-react';
import { printDocument } from '../../../../utils/printDocument';

const ChartsCards = ({ chart }) => {
  const renderChart = (chartType) => {
    switch (chartType) {
      case 'cloropleth':
        return <BrazilCloropleth data={chart.data} />;
      case 'line':
        return <LineChart data={chart.data} />;
      case 'treemap':
        return <TreemapChart data={chart.data} />;
      case 'bars':
        return <BarsChart data={chart.data} />;
    }
  };

  const cardAction = (
    <Button
      appearance="secondary"
      size="sm"
      icon={<ExportSquare size={16} color="#475467" />}
      iconPosition="right"
      onClick={() => printDocument(chart.title)}
    >
      Exportar
    </Button>
  );

  const renderHighlight = (chart) => {
    if (chart.highlights && typeof chart.highlights === 'object') {
      return (
        <div className="dashboard-highlights">
          <div className="dashboard-highlights_card-vara">
            <div className="dashboard-highlights_card-vara-item">
              <div className="dashboard-highlights_card-vara-item_group">
                <Judge size={24} color="#fff" />
                <div>
                  <p className="font-semibold">{chart.highlights.total.vara}</p>
                  <span>{chart.highlights.total.text}</span>
                </div>
              </div>
            </div>
            <div className="dashboard-highlights_card-vara-item">
              <div className="dashboard-highlights_card-vara-item_group">
                <Like1 size={24} color="#fff" />
                <div>
                  <p className="font-semibold">
                    {chart.highlights.favoravel.vara}
                  </p>
                  <span>{chart.highlights.favoravel.text}</span>
                </div>
              </div>
            </div>
            <div className="dashboard-highlights_card-vara-item">
              <div className="dashboard-highlights_card-vara-item_group">
                <Dislike size={24} color="#fff" />
                <div>
                  <p className="font-semibold">
                    {chart.highlights.desfavoravel.vara}
                  </p>
                  <span>{chart.highlights.desfavoravel.text}</span>
                </div>
              </div>
            </div>
            <div className="dashboard-highlights_card-vara-item">
              <div className="dashboard-highlights_card-vara-item_group">
                <Happyemoji size={24} color="#fff" />
                <div>
                  <p className="font-semibold">
                    {chart.highlights.parcialmente.vara}
                  </p>
                  <span>{chart.highlights.parcialmente.text}</span>
                </div>
              </div>
            </div>
            <div className="dashboard-highlights_card-vara-icon">
              <LampOn color="#FFF" size={28} />
            </div>
          </div>
          {renderChart(chart.type)}
        </div>
      );
    } else {
      return (
        <div className="dashboard-highlights">
          {chart?.highlightsPosition === 'left' ? (
            <HighlightCard highlight={chart.highlights} />
          ) : null}
          {renderChart(chart.type)}
          {chart?.highlightsPosition === 'right' ? (
            <HighlightCard highlight={chart.highlights} />
          ) : null}
        </div>
      );
    }
  };

  return (
    <CardWithHeaderActions
      title={chart.title}
      actions={cardAction}
      id={chart.title}
    >
      {renderHighlight(chart)}
    </CardWithHeaderActions>
  );
};

export default ChartsCards;
