import React from 'react';
import { SelectPicker } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

const singleFilterData = [
  {
    id: 0,
    name: 'Nenhuma Ordenação',
  },
  {
    id: 1,
    name: 'Por Relevância',
  },
  {
    id: 2,
    name: 'Por Data de Publicação',
  },
];

class SingleFilter extends React.Component {
  handleChange = (newValue) => {
    const { filter, context, setSingleFilter } = this.props;
    if (context && context != 'Monitoring') {
      posthog.capture(context, {
        action: 'change_filter',
        id: filter.field_name,
      });
      postHubSpotEvent('app_changefilters', { app_changefilters: context });
    }
    if (context === 'Monitoring') {
      setSingleFilter(newValue);
    } else {
      this.props.filtersProps.setTmpFilters(filter.field_name, newValue);
    }
  };

  render() {
    const { filter, labelKey, label, context, filtersProps, singleFilter } =
      this.props;

    return (
      <div className="inteiro-teor-filter">
        <span className="title-option">
          {label ? label : filter.public_name}
        </span>

        <SelectPicker
          data={
            context != 'Monitoring'
              ? filter?.options
                ? filter.options?.map((option) => ({
                    value: option.id,
                    label: option[labelKey],
                  }))
                : []
              : singleFilterData?.map((option) => ({
                  value: option.id,
                  label: option[labelKey],
                }))
          }
          block
          placeholder={'Selecione uma ordenação'}
          value={
            context != 'Monitoring'
              ? filtersProps.tmpFilters[filter.field_name]
              : singleFilter
          }
          onChange={(value, e) => this.handleChange(value)}
          cleanable={false}
        />
      </div>
    );
  }
}

export default SingleFilter;
