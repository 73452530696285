import { Turivius } from '../../../../../ui';
import './BarChart.scss';
import { BasicTooltip } from '@nivo/tooltip';

const BarChart = ({
  id,
  key,
  title,
  legend,
  data,
  keys,
  indexBy,
  maxValue,
  getColors,
  width,
}) => {
  return (
    <>
      <p className="chart-title">
        <small style={{ fontSize: '95%' }}>{title}</small>
      </p>

      <Turivius.TuriviusBarChart
        style={{ width: '100%', height: width ? width : 150 }}
        key={key}
        id={id}
        margin={{
          top: 0,
          right: 10,
          bottom: 10,
          left: 50,
        }}
        axisBottom={null}
        axisLeft={{
          legendOffset: -45,
          legend: legend,
          legendPosition: 'middle',
          format: (value) => {
            value = Number(value);
            if (value >= 1000000) {
              value = value / 1000000 + 'M';
            } else if (value >= 1000) {
              value = value / 1000 + 'K';
            }
            return value;
          },
          tickValues: 5,
        }}
        gridYValues={5}
        data={data}
        keys={keys}
        indexBy={indexBy}
        getColors={(value) => {
          return getColors[value.data.label];
        }}
        maxValue={maxValue}
        enableLabel={false}
        tooltip={({ color, label, ...data }) => {
          const dateData = data.indexValue.split('-');
          let tooltipDate = dateData[0];
          if (dateData.length == 2)
            tooltipDate = dateData[1] + '/' + tooltipDate;
          return (
            <BasicTooltip
              id={
                <span>
                  {data.formattedValue} decisões em {tooltipDate}
                </span>
              }
              enableChip={true}
              color={color}
            />
          );
        }}
      />
    </>
  );
};

export default BarChart;
