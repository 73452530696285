import { useEffect, useState } from 'react';
import {
  useFloating,
  useDismiss,
  useInteractions,
  flip,
  shift,
  inline,
  autoUpdate,
} from '@floating-ui/react';
import { cn } from '../../lib/utils';

type FloatingUIProps = {
  className?: string;
  children: JSX.Element | string;
  getTextSelected: (text: string | undefined) => void;
};

export const FloatingUI = ({
  className,
  children,
  getTextSelected,
}: FloatingUIProps) => {
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'top',
    open,
    onOpenChange: setOpen,
    middleware: [inline(), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      if (refs.floating.current?.contains(event.target as Node)) {
        return;
      }

      setTimeout(() => {
        const selection = window.getSelection();
        const range =
          typeof selection?.rangeCount === 'number' && selection.rangeCount > 0
            ? selection.getRangeAt(0)
            : null;

        if (selection?.isCollapsed) {
          setOpen(false);
          return;
        }

        if (range) {
          refs.setReference({
            getBoundingClientRect: () => range.getBoundingClientRect(),
            getClientRects: () => range.getClientRects(),
          });
          setOpen(true);
          getTextSelected(selection?.toString());
        }
      });
    };

    const handleMouseDown = (event: MouseEvent) => {
      if (refs.floating.current?.contains(event.target as Node)) {
        return;
      }

      if (window.getSelection()?.isCollapsed) {
        setOpen(false);
      }
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [refs, setOpen]);

  return (
    <>
      {open && (
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles, top: -13 }}
          className={cn('relative', open ? 'block' : 'none', className)}
          {...getFloatingProps()}
        >
          {children}
        </div>
      )}
    </>
  );
};
