import { Button, FavorabilityButton } from '../../../../../ds-components';
import { Add, ArrowLeft2, Danger } from 'iconsax-react';
import { useState } from 'react';
import { Modal } from 'rsuite';
import { requester } from '../../../../../requesters';
import { postHubSpotEvent } from '../../../../../services/hubspot';
import DecisionClassification from '../Selects/DecisionClassification';
import { Message, toaster } from 'rsuite';
const FavorabilityModal = ({ chip, card }) => {
  const [showModal, setShowModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [activeOption, setActiveOption] = useState({
    id: null,
    name: '',
  });

  const suggestJurimetrics = (option) => {
    requester.jurimetrics
      .suggestNewClassification(card.id, option.id)
      .then((response) => {
        toaster.push(
          <Message
            header="Sugestão enviada"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Sua sugestão de classificação foi enviada com sucesso!
          </Message>,
        );
        setShowModal(false);
        setCheck(true);
        postHubSpotEvent('app_sugestaoclassificacao', {
          app_sugestaoclassificacao: response.suggestion,
          app_sugestaoclassificacao_card: response.card_id,
        });
      })
      .catch((err) => {
        console.log(err);
        setRequestError('Erro ao gravar sugestão');
        setCheck(false);
        toast.error('Erro ao gravar sugestão');
      });
  };

  return (
    <>
      {!chip ? (
        <FavorabilityButton
          type="Sugerir classificação"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <FavorabilityButton
          type={chip.text}
          onClick={() => setShowModal(true)}
        />
      )}
      <Modal
        backdrop={true}
        keyboard={false}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="decision-header font-semibold">
            Classificação da decisão
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {chip?.comment ? (
            <div className="decision-alert">
              <Danger color="#F79009" size={32} />
              <div className="decision-alert_message">
                <span className="font-medium">Status atual da decisão</span>
                <span>{chip.comment}</span>
              </div>
            </div>
          ) : null}

          <div className="decision-body mt-4">
            Selecione abaixo a opção que considera mais adequada para
            classificar o resultado da decisão desse documento
            <div className="decision-content">
              <DecisionClassification
                card={card}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
                requestError={requestError}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="decision-footer">
          <Button
            appearance="secondary"
            size="md"
            fullWidth
            onClick={() => {
              setActiveOption('');
              setShowModal(false);
            }}
            iconPosition="left"
            icon={<ArrowLeft2 size={16} color="#475467" />}
          >
            Fechar
          </Button>
          <Button
            appearance="primary"
            size="md"
            fullWidth
            onClick={() => suggestJurimetrics(activeOption)}
            icon={<Add size={20} color="#FFFFFF" />}
          >
            Enviar classificação
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FavorabilityModal;
