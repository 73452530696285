import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ProfilesActions } from '../store/ducks/profiles';
import { requester } from '../requesters';
import { TURIVIUS_VERSION } from './App';
import { Message, toaster } from 'rsuite';

const DelayedRedirect = ({ history, to, delay }) => {
  useEffect(() => {
    setTimeout(() => {
      history.push(to);
    }, delay);
  }, []);

  return <></>;
};

class PrivateRoute extends React.Component {
  state = {
    profile: null,
  };

  async getProfile(userId) {
    this.props.setProfile({});
    this.props.setPermissions({});

    requester.profiles
      .getById(userId)
      .then(async (response) => {
        if (response.active && !response.company.blocked) {
          this.setState({ profile: response });
          this.props.setProfile(response);
          this.props.setCompany(response.company);
          localStorage.setItem('profile', JSON.stringify(response));
          localStorage.setItem('company', response.company.id);
          localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));
        } else {
          this.props.history.push('/logout');
          toaster.push(
            <Message showIcon closable type="error" header={'Acesso Bloqueado'}>
              Seu acesso está bloqueado! Entre em contato com a administrador da
              sua conta.
            </Message>,
          ),
            { duration: 20000 };
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async componentDidMount() {
    const userId = localStorage.getItem('user');
    const profile = JSON.parse(localStorage.getItem('profile'));

    if (!this.props.profile) {
      this.getProfile(userId);
    } else {
      this.setState({ profile });
    }
  }

  render() {
    const { component: Component, history, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem('token') ? (
            <Component {...props} />
          ) : (
            <DelayedRedirect to="/login" delay={50} history={history} />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profiles.profile,
  permissions: state.profiles.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(Object.assign({}, ProfilesActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PrivateRoute));
