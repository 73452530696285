import React from 'react';
import { Col, Grid } from 'rsuite';
import CreateNewMonitoringButton from './Buttons/CreateNewMonitoring';
// import "./style.scss";

const MonitoringEmpty = ({
  entities,
  onCreateMonitoring,
  getMonitoring,
  shared,
}) => {
  return (
    <div className="monitoring-empty">
      <img
        style={{ width: 320 }}
        src="https://ouch-cdn2.icons8.com/nC3HaCGSAW-r9xtJ9b1iDjFqhqxykpUZxThkxKwePnk/rs:fit:684:456/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMy82/YTk5NTJiMi1mNWVh/LTRkNDAtYjZlMi1h/ZGQzODUwYTIwMjUu/c3Zn.png"
      />

      <div className="monitoring-empty_information">
        {!shared ? (
          <>
            <h4>Você não possui monitoramento</h4>
            <p>Clique no botão acima para criar um monitoramento</p>
          </>
        ) : (
          <h4>Nenhum monitoramento compartilhado</h4>
        )}
        {}
      </div>
    </div>
  );
};

export default MonitoringEmpty;
