import React, { Component, useRef, useState } from 'react';
import { Grid, Row, Col } from 'rsuite';
import { withRouter } from 'react-router-dom';
import NotificationItem from './item';
import Header from './header';
import Content from './content';
import Footer from './footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { cutString } from '../../../../../utils/cutString';
import { AddCircle, TickCircle } from 'iconsax-react';

class NotificationCenter extends Component {
  static defaultProps = {
    mapToItem: {},
    notifications: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      notifications: this.props.notifications,
      showNotification: false,
      showNotificationMessage: false,
      current: null,
      dontDisturb: false,
    };

    this.mapOptions = this?.mapOptions.bind(this);
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  closeNotification = () => {
    this.props.triggerRef.current.close();
  };

  mapOptions() {
    return {
      id: this.props?.mapToItem.id || 'id',
      category: this.props?.mapToItem.category || 'category',
      icon: this.props?.mapToItem.icon || 'icon',
      title: this.props?.mapToItem.title || 'title',
      subtitle: this.props?.mapToItem.subtitle || 'subtitle',
      message: this.props?.mapToItem.message || 'message',
      actions: this.props?.mapToItem.actions || 'actions',
      date: this.props?.mapToItem.date || 'date',
      new: this.props?.mapToItem.new || 'new',
    };
  }

  onItemClick(item, redirect, a, b, c) {
    let decrease = item.new ? 1 : 0;
    this.setState({
      notifications: this.state.notifications?.map((notification) => {
        if (!notification[this?.mapOptions().id]) {
          console.error('Notification ERROR: You need an id');
          return notification;
        }
        if (
          notification[this?.mapOptions().id] === item[this?.mapOptions().id]
        ) {
          notification[this?.mapOptions().new] = false;
        }
        return notification;
      }),
      showNotificationMessage: true,
      current: item,
    });
    this.props.setUnread(this.props.unread - decrease);
    if (this.props.onItemClick) {
      this.props.onItemClick(item, this.state.notifications);
    }
  }

  onRedirectClick(item) {
    this.setState({
      notifications: this.state.notifications?.map((notification) => {
        if (!notification[this?.mapOptions().id]) {
          console.error('React Notification ERROR: You need an id');
          return notification;
        }

        if (
          notification[this?.mapOptions().id] === item[this?.mapOptions().id]
        ) {
          notification[this?.mapOptions().new] = false;
        }
        return notification;
      }),
    });
    if (this.props.onItemClick) {
      this.props.onItemClick(item, this.state.notifications);
    }
  }

  render() {
    return this.state.notifications.length > 0 ? (
      <>
        {this.state.notifications.filter((notfy) => notfy.new === true).length >
        0 ? (
          <div className="notifications-session">
            <div className="notifications-session_header">
              <AddCircle size="20" color="#0D91FE" />
              <label>Não Lidas</label>
            </div>
            {this.state.notifications
              .filter((notfy) => notfy.new === true)
              ?.map((item, i) => {
                return (
                  <NotificationItem
                    key={i}
                    onClick={this.onItemClick.bind(this, item)}
                    onRedirectClick={this.onRedirectClick.bind(this, item)}
                    options={this?.mapOptions()}
                    closeNotification={this.closeNotification}
                    onCloseDrawer={this.props.onCloseDrawer}
                    {...item}
                  />
                );
              })}
          </div>
        ) : null}

        <div className="notifications-session">
          <div className="notifications-session_header">
            <TickCircle size="20" color="#0D91FE" />
            <label>Lidas</label>
          </div>
          {this.state.notifications
            .filter((notfy) => notfy.new === false)
            ?.map((item, i) => {
              return (
                <NotificationItem
                  key={i}
                  onClick={this.onItemClick.bind(this, item)}
                  onRedirectClick={this.onRedirectClick.bind(this, item)}
                  options={this?.mapOptions()}
                  closeNotification={this.closeNotification}
                  onCloseDrawer={this.props.onCloseDrawer}
                  {...item}
                />
              );
            })}
        </div>
      </>
    ) : (
      <div className="no-rn">{this.props.noNotificationText}</div>
    );
  }
}

export default withRouter(NotificationCenter);
