import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setProfileImage: ['id', 'image'],
  setUserCollections: ['userCollections'],
  setRequestingUserCollections: ['requesting'],
  setDefaultCollectionSave: ['defaultCollectionSave'],
});

const INITIAL_STATE = {
  profilesImages: {},
  userCollections: null,
  requestingUserCollections: false,
  defaultCollectionSave: null,
};

const setProfileImage = (state = INITIAL_STATE, action) => {
  const { id, image } = action;
  return {
    ...state,
    profilesImages: {
      ...state.profilesImages,
      [id]: image,
    },
  };
};

const setUserCollections = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userCollections: [...action.userCollections],
  };
};

const setRequestingUserCollections = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    requestingUserCollections: action.requesting,
  };
};

const setDefaultCollectionSave = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    defaultCollectionSave: action.defaultCollectionSave,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE_IMAGE]: setProfileImage,
  [Types.SET_USER_COLLECTIONS]: setUserCollections,
  [Types.SET_REQUESTING_USER_COLLECTIONS]: setRequestingUserCollections,
  [Types.SET_DEFAULT_COLLECTION_SAVE]: setDefaultCollectionSave,
});
