import { cn } from '../../../../../../lib/utils';

type BadgeProps = {
  children: JSX.Element;
  className?: string;
};

export const Badge = ({ children, className }: BadgeProps) => {
  return (
    <div
      className={cn(
        'min-w-[90px] max-w-[90px] min-h-[80px] max-h-[80px] flex items-center justify-center rounded-lg bg-[#F2F4F7] text-xs font-semibold leading-5 text-black text-center',
        className,
      )}
    >
      {children}
    </div>
  );
};
