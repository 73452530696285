import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Whisper, Tooltip, toaster, Message } from 'rsuite';
import { requester } from '../../../../../requesters';

import './style.scss';

const UserStatusToggleButton = ({ data, table }) => {
  const modalRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState(null);

  function handleModal() {
    setOpenModal(!openModal);
  }

  function setStatusItem() {
    switch (status) {
      case true:
        return 'ativo';
      case false:
        return 'bloqueado';
      default:
        return '--';
    }
  }

  function onToggleUserActivity() {
    const { id } = data;

    setLoad(true);

    requester.profiles
      .toggleUserActivity(id, 'Profiles')
      .then((response) => {
        setStatus(!status);
        handleModal();
        toaster.push(
          <Message
            showIcon
            type="success"
            duration={4000}
            header={`Usuário ${!status ? 'Ativado' : 'Bloqueado'}`}
          >
            {`O usuário foi ${!status ? 'ativado' : 'bloqueado'} com sucesso!`}
          </Message>,
        );
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message showIcon type="error" duration={4000}>
            Ocorreu um erro, tente novamente ou entre em contato com o suporte.
          </Message>,
        );
      })
      .finally(() => {
        setLoad(false);
      });
  }

  useEffect(() => {
    const { active } = data;
    setStatus(active);
  }, [data]);

  return (
    <div className="status-container">
      <Whisper
        trigger="hover"
        placement={table ? 'top' : 'bottomEnd'}
        controlId={`control-id-${status}`}
        speaker={
          <Tooltip>{`Clique aqui para ${status ? 'inativar' : 'ativar'} ${
            data.name
          }`}</Tooltip>
        }
      >
        <Button
          className={`status-button ${setStatusItem()}`}
          onClick={handleModal}
        >
          <FontAwesomeIcon
            color="#ffffff"
            style={{ marginRight: '10px' }}
            size="lg"
            icon={status ? faCheck : faTimes}
          />

          <p className="status">{setStatusItem()}</p>
        </Button>
      </Whisper>

      <Modal ref={modalRef} open={openModal} onClose={handleModal}>
        <Modal.Header>
          <h4>Confirmar Ação</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{`Deseja ${status ? 'inativar' : 'ativar'} ${data.name}?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={load} onClick={onToggleUserActivity}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserStatusToggleButton;
