import React from 'react';

export type CardProps = {
  title: string;
  children: React.ReactElement;
};

export const Card: React.FC<CardProps> = ({ title, children }) => {
  return (
    <div className="tv-card">
      <div className="tv-card-header">
        <h6 className="font-bold">{title}</h6>
      </div>
      <div className="tv-card-content">{children}</div>
    </div>
  );
};

export default Card;
