import React from 'react';
import { Button, Modal } from 'rsuite';
import { Turivius } from '../../../ui';

class ModalAvailability extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { children, title, trigger } = this.props;
    const { open } = this.state;

    return (
      <div>
        <div onClick={this.handleOpen}>{trigger}</div>

        <Modal open={open} onClose={this.handleClose}>
          <Modal.Header>
            <Turivius.TuriviusPopover
              trigger="hover"
              message={
                <p>
                  O Vision é a Inteligência Artificial Tributária da Turivius.
                  <br /> Clique aqui e saiba mais
                </p>
              }
            >
              <div
                className="premium-filter-identifier-animated"
                onClick={() =>
                  window.open(
                    'https://turivius.com/portal/conheca-o-vision-inteligencia-artificial-tributaria/',
                    '__blank',
                  )
                }
              >
                <div className="premium-filter-identifier">
                  <img src="/premium.png" alt="premium filter" />
                  <p>POWERED BY VISION</p>
                </div>
              </div>
            </Turivius.TuriviusPopover>
          </Modal.Header>
          <Modal.Body>
            <h5 className="title">
              <div>{title}</div>
            </h5>
            {children}
          </Modal.Body>

          <Modal.Footer>
            <Button
              flat
              className="btn-margin secondary"
              onClick={this.handleClose}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ModalAvailability;
