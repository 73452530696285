import { request } from './requester';

function getUnreadNotifications(channels) {
  return request({
    type: 'GET',
    microservice: 'notifications',
    action: `publications/unread`,
    auth: 'token',
    data: {
      channels,
    },
  }).then((notifications) => {
    return notifications;
  });
}

function getAllSyncsNotificationsNotNotified(channels) {
  return request({
    type: 'GET',
    microservice: 'notifications',
    action: `publications/syncs`,
    auth: 'token',
    data: {
      channels,
    },
  }).then((notifications) => {
    return notifications;
  });
}

function getById(notificationId) {
  return request({
    type: 'GET',
    microservice: 'notifications',
    action: `publications/${notificationId}`,
    auth: 'token',
  }).then((notification) => {
    return notification;
  });
}

function getNotifications(page, channels) {
  return request({
    type: 'GET',
    microservice: 'notifications',
    action: `publications`,
    auth: 'token',
    data: {
      page,
      channels,
    },
  }).then((notifications) => {
    return notifications;
  });
}

function markAsNotified(userId, notificationsIds) {
  return request({
    type: 'POST',
    microservice: 'notifications',
    action: `publications/mark_as_notified`,
    auth: 'token',
    data: {
      publication_ids: `${notificationsIds}`,
      user_id: userId,
    },
  }).then((response) => {
    return response;
  });
}

function markAsRead(userId, notificationId) {
  return request({
    type: 'POST',
    microservice: 'notifications',
    action: `publications/mark_as_read`,
    auth: 'token',
    data: {
      publication_id: notificationId,
      user_id: userId,
    },
  }).then((response) => {
    return response;
  });
}

function markAllAsRead(channels) {
  return request({
    type: 'POST',
    microservice: 'notifications',
    action: `publications/mark_all_as_read`,
    auth: 'token',
    data: {
      channels,
    },
  }).then((response) => {
    return response;
  });
}

export const notifications = {
  getUnreadNotifications,
  getAllSyncsNotificationsNotNotified,
  getById,
  getNotifications,
  markAsNotified,
  markAsRead,
  markAllAsRead,
};
