import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_CARDS,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Token ${token}`;

  return config;
});

export default api;
