import { Skeleton } from '../../../ds-components';

const DashboardLoader = () => {
  return (
    <>
      <div className="tv-card mb-5">
        <Skeleton size="lg" type="square" className="p-5" />
      </div>
      <div className="tv-card mb-4">
        <Skeleton size="sm" type="default" className="mb-2" />
        <Skeleton size="lg" type="square" className="p-5" />
      </div>
      <div className="tv-card mb-4">
        <Skeleton size="sm" type="default" className="mb-2" />
        <Skeleton size="lg" type="square" className="p-5" />
      </div>
      <div className="tv-card mb-4">
        <Skeleton size="sm" type="default" className="mb-2" />
        <Skeleton size="lg" type="square" className="p-5" />
      </div>
      <div className="tv-card mb-4">
        <Skeleton size="sm" type="default" className="mb-3" />
        <Skeleton size="lg" type="square" className="p-5" />
      </div>
    </>
  );
};

export default DashboardLoader;
