import './SuggestionCards.scss';

const SuggestionCards = ({ text, onClick }) => {
  return (
    <div className="card-suggestion" onClick={() => onClick(text)}>
      {text}
    </div>
  );
};

export default SuggestionCards;
