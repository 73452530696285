import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { requester } from '../../../requesters';
import { Turivius } from '../../../ui';
import TuriviusPreloader from '../../../app/TuriviusPreloader';
import ModalAvailability from './ModalAvailability';
import './style.scss';

const CONTENT = {
  jurimetrics: {
    title: 'Disponibilidade da Jurimetria',
    explanation: `
        <p>Para a Turivius, transparência em confiança são <b>fundamentais</b>. Entenda como fazemos a jurimetria e porque você pode confiar nela.</p>
        <ul style="list-style-type:disc;padding-left:40px;">
            <li style="list-style-type:disc">A jurimetria da Turivius é feita por um sistema de inteligência artificial (AI) que classifica as decisões dos tribunais. </li>
            <li style="list-style-type:disc">Na tabela abaixo você encontra o nível  precisão de cada tribunal. Uma precisão de 85% significa que, para cada 100 decisões, 85 são classificadas corretamente e 15 incorretamente.   </li>
            <li style="list-style-type:disc">A jurimetria passa por um controle de qualidade. Para entrar no ar, ela precisa passar por uma “nota de corte” e ter uma precisão maior que 80%. </li>
        </ul>
        <p>Você pode confiar na jurimetria da Turivius porque aqui você sabe qual a margem de precisão e erro.  Use a jurimetria de acordo com o risco que você aceita tomar na sua análise dado o nível de precisão abaixo. </p>
        <p>Estamos constantemente melhorando nossa precisão. Os novos indicadores serão refletidos na tabela abaixo.</p>
        `,
  },
  inteiro_teor: {
    title: 'Disponibilidade do Inteiro Teor',
    explanation: `
            <p>A tabela abaixo mostra  quais bases de consulta estão disponíveis para pesquisa no inteiro teor e qual o nível de abrangência da pesquisa no inteiro teor. (i.e. percentual de decisões com o inteiro teor indexado para pesquisa) </p>
            <p>Nem todos os tribunais possuem 100% do inteiro teor das decisões disponíveis. Alguns arquivos do inteiro teor das decisões, especialmente as mais antigas, não se encontram no site dos tribunais. </p>
            <p>Na Turivius trabalhamos com uma “nota de corte” de qualidade: só colocamos a pesquisa de inteiro teor disponível quando pelo menos 95% das decisões possuem o documento de inteiro teor disponível.</p>
        `,
  },
  filters: {
    title: 'Precisão dos Filtros',
    explanation: `
        <p>A tabela abaixo mostra o nível de precisão e quais tribunais são cobertos pelos filtros inteligentes.<br/>
        No entanto, existe uma margem de precisão nos filtros. Entenda como ela funciona:</p>
        <ul style="list-style-type:disc;padding-left:40px;">
            <li style="list-style-type:disc">Os filtros inteligentes da Turivius são feitos por um sistema de inteligência artificial (AI) que classifica as decisões dos tribunais. É mesmo sistema que alimenta a jurimetria. </li>
            <li style="list-style-type:disc">Na tabela abaixo você encontra o nível  precisão de cada tribunal. Uma precisão de 85% significa que, para cada 100 decisões, 85 são classificadas corretamente e 15 incorretamente.   </li>
            <li style="list-style-type:disc">A classificação passa por um controle de qualidade. Para entrar no ar, ela precisa passar por uma “nota de corte” e ter uma precisão maior que 80%. </li>
        </ul>
        <p>Note, entretanto, que ainda há uma margem de erro na classificação. Essa margem de erro existe em qualquer sistema de inteligência artificial.
        <br/>Por isso, é possível que uma pequena parcela das decisões filtradas esteja na categoria incorreta.
        <br/>Quando você encontrar uma decisão classificada errada, você pode avisar a classificação correta clicando no botão que indica a sua classificação.</p>
        `,
  },
};

class DataAvailability extends React.Component {
  state = {
    jurimetrics_table: null,
    inteiro_teor_table: null,
    requesting: true,
  };

  getTable() {
    const { context } = this.props;
    const { jurimetrics_table, inteiro_teor_table } = this.state;
    let table = { cols: [], rows: [] };

    if (
      jurimetrics_table !== null &&
      (context === 'jurimetrics' || context === 'filters')
    ) {
      table.cols = jurimetrics_table[0].cols?.map((c) => ({
        key: c,
        label: c,
        fixed: true,
        flexGrow: true,
        align: 'center',
        customCell:
          c === 'Acurácia'
            ? this.customPercentCell
            : c === 'Previsão'
              ? this.customBooleanCell
              : null,
      }));
      table.rows = jurimetrics_table?.map((e) => {
        return e.row.reduce((acc, value, index) => {
          acc[e.cols[index]] = value;
          return acc;
        }, {});
      });
      return table;
    } else if (inteiro_teor_table !== null && context === 'inteiro_teor') {
      table.cols = inteiro_teor_table[0].cols?.map((c) => ({
        key: c,
        label: c,
        fixed: true,
        flexGrow: true,
        align: 'center',
        customCell:
          c === 'Percentagem do Inteiro Teor Disponível'
            ? this.customPercentCell
            : null,
      }));
      table.rows = inteiro_teor_table?.map((e) => {
        return e.row.reduce((acc, value, index) => {
          acc[e.cols[index]] = value;
          return acc;
        }, {});
      });
      return table;
    }
  }

  getColor(col) {
    if (col.includes('%')) {
      const percent = col.split('%')[0];
      if (percent >= 80) {
        return 'txt-green';
      } else if (percent >= 50 && percent < 80) {
        return 'txt-yellow';
      } else {
        return 'txt-red';
      }
    } else {
      return '';
    }
  }

  customPercentCell = ({ rowData, dataKey, ...props }) => (
    <span
      {...props}
      dataKey={dataKey}
      className={this.getColor(rowData[dataKey])}
    >
      {rowData[dataKey]}
    </span>
  );

  customBooleanCell = ({ rowData, dataKey, ...props }) => (
    <span {...props} dataKey={dataKey}>
      {rowData[dataKey] === 'TRUE' ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : rowData[dataKey] === 'FALSE' ? (
        <FontAwesomeIcon icon={faTimes} />
      ) : (
        rowData[dataKey]
      )}
    </span>
  );

  componentDidMount() {
    this.setState({ requesting: true });
    requester.reports
      .getJurimetricsAndInteiroTeorTable()
      .then((tables) => {
        this.setState({
          jurimetrics_table: [...tables.jurimetrics_table],
          inteiro_teor_table: [...tables.inteiro_teor_table],
        });
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ requesting: false });
      });
  }

  render() {
    const { trigger, context } = this.props;
    const { requesting } = this.state;
    const table = this.getTable();

    return (
      <ModalAvailability
        title={<div>{context ? CONTENT[context].title : ''}</div>}
        trigger={trigger}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: context ? CONTENT[context].explanation : '',
          }}
        ></div>
        {!requesting && table !== undefined ? (
          <Turivius.TuriviusTable
            autoHeight
            columns={table.cols}
            data={table.rows}
            paginate={false}
            wordWrap
          />
        ) : (
          <TuriviusPreloader className="centered-preloader" />
        )}
      </ModalAvailability>
    );
  }
}

export default DataAvailability;
