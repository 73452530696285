export const formatDate = (date) => {
  var date_splited = date.split('/');
  var d = new Date(date_splited[2], date_splited[1] - 1, date_splited[0]),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatObjDate = (datesObj) => {
  const formattedDates = {};

  for (const key in datesObj) {
    if (datesObj.hasOwnProperty(key)) {
      const date = datesObj[key];
      const [day, month, year] = date.split('/');
      formattedDates[key] = `${year}-${month}-${day}`;
    }
  }

  return formattedDates;
};
