import React, { forwardRef, useEffect, useState, createRef } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  InputGroup,
  Button,
  toaster,
  Message,
  Schema,
  MaskedInput,
} from 'rsuite';
import './style.scss';
import CountryList from 'country-list-with-dial-code-and-flag';
import { ListItemSecondaryAction } from '@material-ui/core';

const userModel = Schema.Model({
  name: Schema.Types.StringType(),
  email: Schema.Types.StringType().isEmail('Insira um e-mail válido'),
  position: Schema.Types.StringType(),
  short_bio: Schema.Types.StringType(),
  linkedin_link: Schema.Types.StringType(),
  phone: Schema.Types.StringType(),
});

const Textarea = forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

const PersonalData = (props) => {
  const { patchProfileMethod, profile, onSuccessAction, loading, setLoading } =
    props;
  const [userFormValue, setUserFormValue] = useState({
    name: profile?.name,
    email: profile?.email,
    position: profile?.position,
    short_bio: profile?.short_bio,
    linkedin_link: profile?.linkedin_link,
    phone: profile?.phone?.substring(3),
  });
  const [userFormError, setUserFormError] = useState({});
  // const [flags, setFlags] = useState(CountryList.getAll());
  // const [selectionFlag, setSelectionFlag] = useState('+55');
  const masks = [
    '+',
    '5',
    '5',
    '(',
    /[1-9]/,
    /[1-9]/,
    ')',
    ' ',
    /[1-9]/,
    /[1-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  const validateAndSaveProfile = async () => {
    try {
      const { name, email, position, short_bio, linkedin_link, phone } =
        userFormValue;
      // const phone1 = selectionFlag + phone
      await patchProfileMethod(
        profile.id,
        {
          name,
          email,
          position,
          short_bio,
          linkedin_link,
          phone,
        },
        onSuccessAction,
      );
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message
          header="Campos inválidos"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Verifique os campos e tente novamente.
        </Message>,
      );
      setUserFormError({
        errorName: profile.name === '' || profile.name === null,
        errorEmail: profile.email === '' || profile.email === null,
        errorPosition: profile.position === '' || profile.position === null,
      });
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   console.log(profile);
  // }, []);

  return (
    <div className="personal-data">
      <h4 className="title">Dados Pessoais</h4>
      <Form
        fluid
        model={userModel}
        onChange={(formValue) => {
          setUserFormValue(formValue);
        }}
        onCheck={setUserFormError}
        formDefaultValue={userFormValue}
        formError={userFormError}
        formValue={userFormValue}
        disabled={loading}
      >
        <Row gutter={32}>
          <Col lg={12} md={12} xs={24}>
            <Form.Group>
              <Form.ControlLabel>Nome</Form.ControlLabel>
              <Form.Control name="name" placeholder="Insira seu nome aqui" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Cargo</Form.ControlLabel>
              <Form.Control
                name="position"
                placeholder="Insira sua posição aqui"
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>E-mail</Form.ControlLabel>
              <Form.Control
                name="email"
                placeholder="Insira seu e-mail aqui. Ex.: turivius@example.com"
                type="email"
              />
            </Form.Group>
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Form.Group>
              <Form.ControlLabel>Telefone</Form.ControlLabel>
              <InputGroup style={{ width: '100%' }}>
                {/* <InputGroup.Addon>
                  <select aria-label="Country" onChange={(e) => setSelectionFlag(e.target.value)} style={{
                    height: "32px",
                    marginTop: " -7px",
                    marginLeft: "0px",
                    marginRight: "0px"
                  }}>
                    {flags?.map((item, i) => {
                      return <option value={item.data.dial_code} key={i} selected={item.data.dial_code === '+55' ? true : false}>{item.data.flag} {item.data.dial_code}</option>
                    })}
                  </select>
                </InputGroup.Addon> */}
                <Form.Control
                  name="phone"
                  accepter={MaskedInput}
                  mask={masks}
                  placeholder="(99)99999-9999"
                />
              </InputGroup>
              <h5
                style={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  marginTop: '4px',
                }}
              >
                Prezamos pela sua experência na utilização da ferramenta e
                podemos entrar em contato por esse número para coleta de
                feedbacks. Consulte nossa{' '}
                <a
                  href="https://www.turivius.com/politica-de-privacidade/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: 600 }}
                >
                  Política de Privacidade
                </a>{' '}
                para mais informações.
              </h5>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>LinkedIn</Form.ControlLabel>
              <InputGroup style={{ width: '100%' }}>
                <InputGroup.Addon>https://</InputGroup.Addon>
                <Form.Control
                  name="linkedin_link"
                  placeholder="www.linkedin.com/in/Turivius"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Descrição</Form.ControlLabel>
              <Form.Control
                name="short_bio"
                rows={4}
                maxLength={400}
                accepter={Textarea}
                placeholder="Insira uma breve descrição sobre você"
              />
              <Form.HelpText>
                {userFormValue.short_bio ? userFormValue.short_bio?.length : 0}{' '}
                / 400 caracteres
              </Form.HelpText>
            </Form.Group>
          </Col>
        </Row>
        <div className="button-group">
          <Button
            appearance="ghost"
            disabled={loading}
            onClick={setUserFormValue}
            className="bnt-cancel"
          >
            Descartar alterações
          </Button>
          <Button
            loading={loading}
            appearance="primary"
            onClick={validateAndSaveProfile}
            className="bnt-save"
          >
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default PersonalData;
