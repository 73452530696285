import { request } from './requester';

function get(url) {
  return request({
    type: 'GET',
    microservice: 'general',
    action: url,
    contentType: 'application/json',
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function post(url, data) {
  return request({
    type: 'POST',
    microservice: 'general',
    action: url,
    contentType: 'application/json',
    auth: 'token',
    data,
  }).then((response) => {
    return response;
  });
}

export const general = {
  get,
  post,
};
