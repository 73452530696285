import { request } from './requester';

function getServicePlans(service) {
  return request({
    type: 'GET',
    microservice: 'segmentation',
    action: `plans/${service}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function getServicePlan(service, planAlias) {
  return request({
    type: 'GET',
    microservice: 'segmentation',
    action: `plans/${service}/${planAlias}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function createUserServiceSegmentation(
  service,
  userId,
  plano_alias,
  {
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  },
) {
  let data = {
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    plano_alias,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );

  return request({
    type: 'POST',
    microservice: 'segmentation',
    action: `admin/${service}`,
    auth: 'token',
    data: {
      user_id: userId,
      ...data,
    },
  }).then((usersPermissions) => {
    return usersPermissions;
  });
}

function updateUserServiceSegmentation(
  service,
  userId,
  {
    plano_alias,
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  },
) {
  let data = {
    user_id: userId,
    qt_pesquisas,
    qt_monitoramento,
    qt_boards,
    qt_dashboards,
    plano_alias,
    orgaos_monitorados,
    dataviz_entidades,
    filtros,
    jurimetria,
    business_dashboard,
    entidades,
    dataviz,
    dashboard,
  };

  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );

  return request({
    type: 'PATCH',
    microservice: 'segmentation',
    action: `admin/${service}`,
    auth: 'token',
    data,
  }).then((usersPermissions) => {
    return usersPermissions;
  });
}

function deleteServiceSegmentationByUserId(service, userId) {
  return request({
    type: 'DELETE',
    microservice: 'segmentation',
    action: `admin/${service}/${userId}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

export const segmentation = {
  getServicePlans,
  getServicePlan,
  createUserServiceSegmentation,
  updateUserServiceSegmentation,
  deleteServiceSegmentationByUserId,
};
