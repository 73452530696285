import { Skeleton } from '../../../../ds-components';

const FiltersLoader = () => {
  return (
    <div className="tv-filters_list filter-dash-loader mt-3 p-3">
      <h6 className=" tv-filters_title font-bold pb-2 text-color_black p-0">
        Filtros
      </h6>

      <Skeleton type="default" size="md" className="mb-4" />
      <Skeleton type="default" size="md" className="mb-4 " />
      <Skeleton type="default" size="md" className="mb-4" />
      <Skeleton type="default" size="md" className="mb-4" />
      <Skeleton type="default" size="md" className="mb-4" />
      <Skeleton type="default" size="lg" className="mt-5 mb-4" />
    </div>
  );
};

export default FiltersLoader;
