import Skeleton from '@material-ui/lab/Skeleton';

export const DrawerCollectionSkeleton = ({ visible }) => {
  if (!visible) return null;

  return (
    <div style={{ marginBottom: '100px', width: '63%', margin: '0 auto' }}>
      <div className="card-result">
        <div className="content-card">
          <div className="title-modal-search-result">
            <Skeleton circle={true} height={20} width={20} />
            <Skeleton height={20} width={100} style={{ marginLeft: '10px' }} />
            <Skeleton height={20} width={40} style={{ marginLeft: '10px' }} />
          </div>
          <div className="data-search-results">
            <Skeleton height={15} width={15} />
            <Skeleton height={15} width={150} style={{ marginLeft: '10px' }} />
          </div>
        </div>

        <div className="text-info">
          <Skeleton height={20} width={200} />
          <Skeleton count={5} />
        </div>

        <div className="buttons">
          <div className="copy-reload-buttons">
            <Skeleton height={40} width={80} />
            <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
          </div>
          <div className="copy-reload-buttons">
            <Skeleton height={15} width={150} />
            <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
            <Skeleton height={40} width={40} style={{ marginLeft: '10px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};
