import { useEffect, useState, useRef } from 'react';
import { requester } from '../../../requesters';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Message, toaster } from 'rsuite';
import { Creators as ResultsActions } from '../../../store/ducks/searchTributario';
import { Creators as CollectionsActions } from '../../../store/ducks/collections';

import SharedMonitoringSession from '../components/SharedMonitoringSession';
import FlagSystem from '../../../components/FlagSystem';
import MyMonitoringSession from '../components/MyMonitoringSession';
import SuccessChecklistButton from '../../../components/SuccessChecklist/SuccessChecklistButton';

const Monitoring = (props) => {
  const triggerSuccessChecklist = useRef();
  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const [entities, setEntities] = useState('');

  const [monitoringList, setMonitoringList] = useState([]);
  const [monitoringCount, setMonitoringCount] = useState(0);
  const [pageMonitoring, setPageMyMonitoring] = useState(1);
  const [loadingMyMonitoring, setLoadingMyMonitoring] = useState(false);

  const [monitoringSharedWithMeList, setMonitoringSharedWithMeList] = useState(
    [],
  );
  const [monitoringSharedWithMeCount, setMonitoringSharedWithMeCount] =
    useState(0);
  const [pageMonitoringSharedWithMe, setPageMonitoringSharedWithMe] =
    useState(1);
  const [loadingMonitoringSharedWithMe, setLoadingMonitoringSharedWithMe] =
    useState(true);

  function getMonitoring(
    term = '',
    ordering = '-updated_date',
    page = 1,
    limit = 6,
  ) {
    setLoadingMyMonitoring(true);
    requester.monitoring
      .getMonitoring(page, term, ordering, limit, 'Monitoring')
      .then((response) => {
        setMonitoringList(response.results);
        setMonitoringCount(response.count);
      })
      .catch(console.error)
      .finally(() => setLoadingMyMonitoring(false));
  }

  function getMonitoringSharedWithMe(
    term = '',
    ordering = '-updated_date',
    page = 1,
    limit = 6,
  ) {
    setLoadingMonitoringSharedWithMe(true);

    requester.monitoring
      .getMonitoringSharedWithMe(page, term, ordering, limit)
      .then((response) => {
        setMonitoringSharedWithMeList(response.results);
        setMonitoringSharedWithMeCount(response.count);
      })
      .catch(console.error)
      .finally(() => setLoadingMonitoringSharedWithMe(false));
  }

  //MONITORING'S CHANGE FILTERS
  function onChangeFilters({ where, term, ordering, page }) {
    switch (where) {
      case 'my-monitoring':
        setPageMyMonitoring(page);
        getMonitoring(term, ordering, page);
        break;
      case 'monitoring-shared':
        setPageMonitoringSharedWithMe(page);
        getMonitoringSharedWithMe(term, ordering, page);
        break;
    }
  }

  function onCreateMonitoring(
    term,
    frequency,
    sendEmail,
    sendNotification,
    allEntities,
    filters,
    sharedWith,
    monitoringWeekDay,
    monitoringMonthDay,
    userId,
    companyId,
  ) {
    return requester.monitoring.addMonitoringTerm(
      term,
      frequency,
      sendEmail,
      sendNotification,
      allEntities,
      filters,
      sharedWith,
      monitoringWeekDay,
      monitoringMonthDay,
      userId,
      companyId,
      'Monitoring',
    );
  }

  function onDeleteMonitoring(monitoringId, term) {
    requester.monitoring
      .removeMonitoringTerm(monitoringId, 'Monitoring')
      .then((response) => {
        toaster.push(
          <Message
            showIcon
            closable
            type="success"
            header="Monitoramendo Deletado"
            duration={5000}
          >
            {`Monitoramento do termo ${term} foi deletado com sucesso!`}
          </Message>,
        );
        getMonitoring();
      })
      .catch(console.error);
  }

  function onSharedWith(monitoringId, sharedWith) {
    return requester.monitoring.updateMonitoring(monitoringId, sharedWith);
  }

  function onEditMonitoring(
    monitoringId,
    sharedWith,
    etContext,
    query,
    frequency,
    send_email,
    send_notification,
    all_entities,
    filters,
    monitoring_week_day,
    monitoring_month_day,
  ) {
    return requester.monitoring.updateMonitoring(
      monitoringId,
      sharedWith,
      etContext,
      query,
      frequency,
      send_email,
      send_notification,
      all_entities,
      filters,
      monitoring_week_day,
      monitoring_month_day,
    );
  }

  async function getEntities() {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch(console.error);

    setEntities(entity);
  }

  async function getBoardsPage(page) {
    return requester.collections.getUserCollections(
      localStorage.getItem('user'),
      page,
      true,
    );
  }

  async function verifyAndGetCollections() {
    if (!props.userCollections) {
      props.setRequestingUserCollections(true);

      let page = 1;
      let userCollections = {};
      let sharedCollections = {};
      let companyCollections = {};

      let user_boards = {};
      let shared_w_boards = {};
      let public_to_company_boards = {};

      do {
        let collections = await getBoardsPage(page);
        user_boards = collections.user_boards;
        shared_w_boards = collections.shared_w_boards;
        public_to_company_boards = collections.public_to_company_boards;

        userCollections[page] = user_boards.boards;
        sharedCollections[page] = shared_w_boards.boards;
        companyCollections[page] = public_to_company_boards.boards;

        page += 1;
      } while (
        user_boards.boards.length === 12 ||
        shared_w_boards.boards.length === 12 ||
        public_to_company_boards.boards.length === 12
      );

      let collections = [
        ...Object.values(userCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
        ...Object.values(sharedCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
        ...Object.values(companyCollections).reduce(
          (acc, v) => [...acc, ...v],
          [],
        ),
      ];
      collections = collections.filter(
        (collection, index, self) =>
          index === self.findIndex((c) => c.id === collection.id),
      );
      props.setUserCollections(collections);
      props.setRequestingUserCollections(false);
    }
  }

  useEffect(() => {
    document.title = 'Monitoramento';
    getEntities();
    getMonitoring();
    getMonitoringSharedWithMe();
    verifyAndGetCollections();
  }, []);

  return (
    <div className="monitoring">
      <FlagSystem
        current="monitoring"
        openSuccessChecklist={openSuccessChecklist}
      />

      <MyMonitoringSession
        entities={entities}
        monitoringList={monitoringList}
        monitoringCount={monitoringCount}
        pageMonitoring={pageMonitoring}
        loadingMyMonitoring={loadingMyMonitoring}
        getMonitoring={getMonitoring}
        onChangeFilters={onChangeFilters}
        onCreateMonitoring={onCreateMonitoring}
        onDeleteMonitoring={onDeleteMonitoring}
        onSharedWith={onSharedWith}
        onEditMonitoring={onEditMonitoring}
      />

      <SharedMonitoringSession
        entities={entities}
        onChangeFilters={onChangeFilters}
        monitoringSharedWithMeCount={monitoringSharedWithMeCount}
        monitoringSharedWithMeList={monitoringSharedWithMeList}
        loadingMonitoringSharedWithMe={loadingMonitoringSharedWithMe}
        pageMonitoringSharedWithMe={pageMonitoringSharedWithMe}
      />

      {/*<SuccessChecklistButton openSuccessChecklist={triggerSuccessChecklist} />*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  entities: state.searchTributario.entities,
  userCollections: state.collections.userCollections,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, ResultsActions, CollectionsActions),
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
