import { Button, Skeleton } from '../../../../ds-components';
import { ArrowCircleDown2 } from 'iconsax-react';
import ChartsCards from '../../core/Cards/ChartsCards';
import ProcessList from '../../core/List/ProcessList';

const SubjectDashContainer = ({
  dashboardData,
  subjectProcessListPage,
  setSubjectProcessListPage,
  loadingSubjectAcordao,
  subjectAcordao,
}) => {
  const panoramaData = [
    {
      value: dashboardData?.panorama.total,
      description: 'Total de processos',
    },
    {
      value: dashboardData?.panorama.procedentes,
      description: 'Total de pedidos procedentes',
    },
    {
      value: dashboardData?.panorama.improcedentes,
      description: 'Total de pedidos improcedentes',
    },
    {
      value: dashboardData?.panorama.parc_procedentes,
      description: 'Total de pedidos parcialmente procedentes',
    },
  ];

  return (
    <div className="dashboard-overview" id="subject-dashboard">
      <div className="dashboard-overview_panorama">
        <div className="d-flex justify-content_between align-items_center pb-2">
          <h3 className="font-bold">Panorama Geral</h3>
          <Button
            appearance="primary"
            size="sm"
            iconPosition="right"
            icon={<ArrowCircleDown2 color="#FFFFFF" size={18} />}
            onClick={() => () => printDocument('subject-dashboard')}
          >
            Exportar dashboard
          </Button>
        </div>
        <div className="dashboard-overview_panorama-card tv-card">
          {panoramaData?.map((item, index) => {
            return (
              <div
                className="dashboard-overview_panorama-card-item"
                key={index}
              >
                {/* <div className="card-divisor" /> */}
                {/* {item.divisor ? <div className="card-divisor" /> : null} */}
                <div>
                  <h5 className="font-bold mb-1">{item.value}</h5>
                  <span>{item.description}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content_between align-items_center pt-2 pb-1">
          <h5 className="font-bold assuntos-relacionados">
            Assuntos relacionados
          </h5>
        </div>
        <div className="dashboard-overview_panorama-card tv-card mt-2">
          {dashboardData.assuntosComuns?.map((item, index) => {
            return (
              // <div>
              <div
                className="dashboard-overview_panorama-card-item"
                key={index}
              >
                {/* <div className="card-divisor" /> */}
                {/* {item.divisor ? <div className="card-divisor" /> : null} */}
                <div>
                  <h5 className="font-bold mb-1">{item.value}</h5>
                  <span>{item.subject}</span>
                </div>
              </div>
              // </div>
            );
          })}
        </div>
      </div>
      <div className="dashboard-overview_insights">
        <h3 className="font-bold pt-4 pb-2">Análises & Insights</h3>
        {dashboardData.charts?.map((chart, index) => {
          // console.log("chart", chart)
          return (
            <div div className="py-3" key={index}>
              <ChartsCards chart={chart} />
            </div>
          );
        })}
      </div>
      {/* {!loadingSubjectAcordao && subjectAcordao ? <ProcessList data={subjectAcordao} dashType={'subject'} page={subjectProcessListPage} setPage={setSubjectProcessListPage} /> : null} */}
      {loadingSubjectAcordao || !subjectAcordao ? (
        <div className="tv-card">
          <Skeleton type="square" size="lg" className="p-5" />
        </div>
      ) : (
        <ProcessList
          data={subjectAcordao}
          dashType={'subject'}
          page={subjectProcessListPage}
          setPage={setSubjectProcessListPage}
        />
      )}
    </div>
  );
};

export default SubjectDashContainer;
