import { useState } from 'react';
import { Input } from 'rsuite';

import CreateNewMonitoringButton from './Buttons/CreateNewMonitoring';
import { Button } from '../../../ds-components';
import { FilterSearch } from 'iconsax-react';

const MonitoringSearch = ({
  title,
  shared,
  onSearch,
  whereSearch,
  entities,
  onCreateMonitoring,
  getMonitoring,
  id,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  function search() {
    onSearch({ where: whereSearch, term: searchTerm });
  }

  function keyPressSearch(e) {
    if (e.key === 'Enter') {
      search();
    }
  }

  return (
    <div className="monitoring-search">
      <div className="monitoring-search_header">
        <h5 className="font-medium" id={id}>
          {title}
        </h5>
        <CreateNewMonitoringButton
          shared={shared}
          entities={entities}
          onCreateMonitoring={onCreateMonitoring}
          getMonitoring={getMonitoring}
        />
      </div>
      <div className="monitoring-search_group">
        <Input
          placeholder={`Buscar em "${title}"`}
          value={searchTerm}
          onChange={setSearchTerm}
          onKeyUp={(e) => keyPressSearch(e)}
          size="lg"
        />
        <Button
          appearance="secondary"
          size="sm"
          onClick={search}
          icon={<FilterSearch color="#FFFFFF" size={18} />}
        >
          Filtrar
        </Button>
      </div>
    </div>
  );
};
export default MonitoringSearch;
