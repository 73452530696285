import { requester } from '../requesters';

export const exportCollectionToPDF = async (
  userId,
  collectionId,
  collectionName,
  isDefault,
) => {
  try {
    const pdf = await fetch(
      `${process.env.REACT_APP_COLLECTIONS}reports/${userId}/${collectionId}${isDefault ? '?use_default_config=true' : ''}`,
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.href = url;
        link.setAttribute('download', `${collectionName}.pdf`);
        link.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
    return pdf;
  } catch (err) {
    console.error(err);
  }
};

export const createCollectionOnboarding = async (
  setLoading,
  setCollections,
) => {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const trabalhista = await requester.collections.createCollection(
    profile.id,
    collectionsOnboarding['trabalhista'].name,
    collectionsOnboarding['trabalhista'].description,
  );
  const trabalhistaUpdate = await requester.collections.addCardsToCollection(
    profile.id,
    trabalhista.id,
    collectionsOnboarding['trabalhista'].cards,
  );
  const tributario = await requester.collections.createCollection(
    profile.id,
    collectionsOnboarding['tributario'].name,
    collectionsOnboarding['tributario'].description,
  );
  const tributarioUpdate = await requester.collections.addCardsToCollection(
    profile.id,
    tributario.id,
    collectionsOnboarding['tributario'].cards,
  );
  const civil = await requester.collections.createCollection(
    profile.id,
    collectionsOnboarding['civil'].name,
    collectionsOnboarding['civil'].description,
  );
  const civilUpdate = await requester.collections.addCardsToCollection(
    profile.id,
    civil.id,
    collectionsOnboarding['civil'].cards,
  );

  requester.collections
    .searchCollections('my_collections', '', 1)
    .then((response) => setCollections(response))
    .catch((err) => console.log(err))
    .finally(async () => {
      setLoading(false);
      // updTour();
    });
};

const collectionsOnboarding = {
  trabalhista: {
    name: 'Modelo de coleção Trabalhista',
    description:
      'Análise das decisões proferidas pelos Tribunais Regionais do Trabalho da região sul do país quanto ao tema: vínculo de emprego de trabalhador com aplicativo de transporte ou entrega.',
    cards: [
      '2967267172',
      '1695610732',
      'bhT1xoYB5v9yDuSFscsk',
      'kTaTSoIBL-eLj1PNqCw2',
      '3187872898',
      '3402349296',
      'vJT7QIIBQIvv7dmZGzRB',
      '5RROx4YB5v9yDuSFvdLe',
      '279349621',
      'ByFoToIB1HlAM4ZJSO5X',
      '519250535',
      'JhWXzIYB5v9yDuSFTAgs',
      '787194907',
      'm9vjv4YB2omT4qDiCNf0',
      'NZLoO4IBQIvv7dmZCN3p',
      '892795384',
      'F0aHEIgBFtF1EiBVZcf0',
      '1174106902',
      'x1z3w4YBgfXdXy0GR3WA',
      '465383474',
      'l5BVOIIBQIvv7dmZ1J-J',
      '375677650',
      '824134360',
      'ZlGxxoYBWnbbPe96Jeo3',
      'bFGxxoYBWnbbPe96Jeo3',
      '1343380276',
      '2412551577',
      'ySwEUYIBmknpLhd2yvda',
      'fhSEwIYB5v9yDuSFbi4U',
      '27231489',
      '3763978080',
      '3774459174',
      '1745546987',
      '2345835533',
      '3lz2xoYBgfXdXy0Gsa_U',
      'dja2SoIBL-eLj1PN9VlH',
      '344635536',
      '627182073',
    ],
  },
  tributario: {
    name: 'Modelo de coleção Tributário',
    description:
      'Análise de acórdãos que tratam de amortização de ágio, com decisões contrárias ao contribuinte.',
    cards: [
      '2814611',
      '19130',
      '310619879',
      '410689445',
      '66378',
      '2672825',
      '2753307620',
      '2676864',
      '2748012',
      '740420123',
      '359228328',
      '2758490',
      '65237',
      '2594591706',
      '23016',
      '2786325',
      '1192542684',
      '2747012',
      '3048678764',
      '497562',
      '2797283',
      '24795',
      '4009999493',
      '497933',
    ],
  },
  civil: {
    name: 'Modelo de coleção cível',
    description:
      'Precedentes favoráveis e desfavoráveis a indenização por danos morais decorrentes de vazamento de dados em diversos tribunais do País. ',
    cards: [
      'L1bK5IgBzdCXr1h-2agh',
      '2444418003',
      '2668267800',
      '630044762',
      'WuIGgIcB2omT4qDiD1t3',
      '1282087885',
      '461605662',
      '3592211507',
      '3266717869',
      'U0NUuocBt_LFgi7fqpSA',
      '2200062817',
      '2445861604',
      '917662900',
      'Nlb1tYgBzdCXr1h-dSlD',
      '2355821697',
      'HFQVIYgBzdCXr1h-X2Sb',
      '67747746',
      '4175457060',
      '3220366969',
      '2602021977',
    ],
  },
};
