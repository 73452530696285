import { useState } from 'react';
import {
  IconButton,
  Uploader,
  toaster,
  Loader,
  Message,
  Tooltip,
  Whisper,
} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { requester } from '../../../../../requesters';
import './style.scss';
import NewUserButton from '../NewUserButton';

const AvatarChange = ({ companyName, getUsers, tabActive, ...props }) => {
  const { company } = JSON.parse(localStorage.getItem('profile'));

  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(
    `data:image/*;base64, ${company.logo}`,
  );
  const [companyProfiles, setCompanyProfiles] = useState([]);

  function patchImageMethod(photo) {
    setUploading(true);
    requester.profiles
      .updateCompanyImage(localStorage.getItem('company'), photo)
      .then((response) => {
        getProfile();
        toaster.push(
          <Message
            header="Dados atualizados"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Imagem atualizada com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente.
          </Message>,
        );
        console.error(err);
      })
      .finally(() => {
        setUploading(false);
      });
  }

  function getProfile() {
    requester.profiles
      .getById(localStorage.getItem('user'))
      .then((response) => {
        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));
      });
  }

  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      canvas.width = 200;
      canvas.height = 200;

      let image = new Image();
      image.onload = () => {
        const aspect = image.height / image.width;
        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          (canvas.width - canvas.width / aspect) / 2,
          0,
          canvas.width / aspect,
          canvas.height,
        );
        let photo = canvas.toDataURL().split(',')[1];
        patchImageMethod(photo);
      };

      image.src = e.target.result;
      setFileInfo(reader.result);
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
      <div className="avatar-content">
        <div className="avatar">
          <img
            alt={'Turivius'}
            src={fileInfo ? fileInfo : companyLogo ? companyLogo : 'avatar.png'}
            style={{
              maxWidth: 110,
              maxHeight: 110,
              borderRadius: '15px 15px 3px 15px',
            }}
          />

          <div className="change-button-content">
            <Uploader
              fileListVisible={false}
              accept=".png, .jpg, .jpeg"
              action=""
              onUpload={(file) => {
                setUploading(true);
                previewFile(file.blobFile, (value) => {
                  setFileInfo(value);
                });
              }}
              onError={() => {
                setFileInfo(null);
              }}
            >
              <IconButton
                className="change-button"
                loading={uploading}
                icon={<FontAwesomeIcon icon={faCamera} />}
              />
            </Uploader>
          </div>
          <div className="company-content">
            <div className="company-line" />

            <Whisper
              placement="bottom"
              trigger="hover"
              speaker={<Tooltip>{companyName || <Loader />}</Tooltip>}
            >
              <h4 className="company-name">{companyName || <Loader />}</h4>
            </Whisper>
          </div>
        </div>
        {tabActive === 'users' ? (
          <div className="btn-new-user">
            <NewUserButton getUsers={getUsers} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AvatarChange;
