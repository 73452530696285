import React from 'react';
import { LogoutCurve } from 'iconsax-react';
import { Dropdown } from 'rsuite';
import { withRouter } from 'react-router-dom';

const LogoutOption = ({ eventKey, ...props }) => {
  return (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        onClick={() => props.history.push('/logout')}
      >
        <LogoutCurve size="20" color="#0069F5" />
        <label> Sair </label>
      </button>
    </Dropdown.Item>
  );
};

export default withRouter(LogoutOption);
