import React from 'react';
import './ButtonRadio.scss';
import { User } from 'iconsax-react';
import { Radio, RadioGroup, Form, Checkbox, CheckboxGroup } from 'rsuite';

const ButtonRadio = ({ type, options, onChange, defaultValue }) => {
  switch (type) {
    case 'radio':
      return (
        <RadioGroup
          name="radioList"
          id="radioSetup"
          inline
          appearance="picker"
          defaultValue={defaultValue ? defaultValue : null}
          onChange={onChange}
        >
          {options?.map((item, i) => {
            return (
              <Radio value={item.value} name={item.name}>
                {item.label}
              </Radio>
            );
          })}
        </RadioGroup>
      );
    case 'checkbox':
      return (
        <CheckboxGroup
          id="checkBoxSetup"
          inline
          value={defaultValue}
          name="checkboxList"
          onChange={(value) => {
            onChange(value);
          }}
        >
          {options?.map((item, i) => {
            return (
              <Checkbox value={item.value} name={item.name}>
                {item.label}
              </Checkbox>
            );
          })}
        </CheckboxGroup>
      );
  }
};

export default ButtonRadio;
