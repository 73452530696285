import { ArrowDown2, ArrowUp2, CloseCircle } from 'iconsax-react';
import React, { useState, useEffect, useRef } from 'react';
// import { options } from "../../data/mocks/selectOptions";

const MultiSelect = ({ placeholder, label, data, fieldName, onChange }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsGroupRef = useRef(null);

  useEffect(() => {
    const clearTempOptions = () => {
      setSelectedOptions([]);
    };

    window.addEventListener('beforeunload', clearTempOptions);

    return () => {
      window.removeEventListener('beforeunload', clearTempOptions);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        optionsGroupRef.current &&
        !optionsGroupRef.current.contains(event.target)
      ) {
        setOpenMenu(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleInputClick = (e) => {
    e.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleOptionSelected = (e, name) => {
    const isOptionAlreadySelected = selectedOptions.some(
      (option) => option === e,
    );

    if (!isOptionAlreadySelected) {
      var index = selectedOptions.indexOf(e);
      if (index > -1) {
        return;
      } else {
        setSelectedOptions([...selectedOptions, e]);
        onChange(e, name, true);
        // setSelectedOptions([...selectedOptions, e]);
      }
      // onChange(e, name, true)
    }
  };

  const removeOption = (option) => {
    onChange(option, fieldName, false);
    var index = selectedOptions.indexOf(option);
    if (index > -1) {
      selectedOptions.splice(index, 1);
      setSelectedOptions(selectedOptions);
    }
  };

  return (
    <div className="tv-multiselect_container pb-2">
      <p className="tv-multiselect_label pb-1">{label}</p>
      <div
        className="tv-multiselect_input--group mb-2"
        onClick={(e) => handleInputClick(e)}
        ref={optionsGroupRef}
      >
        <span>Selecione</span>
        {openMenu ? (
          <ArrowUp2 size={16} color="#475467" />
        ) : (
          <ArrowDown2 size={16} color="#475467" />
        )}
      </div>
      {openMenu ? (
        <div className="tv-multiselect_options--group">
          {data?.map((item, index) => {
            return (
              <div
                className="tv-multiselect_options--item"
                key={index}
                onClick={() => handleOptionSelected(item.value, fieldName)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      ) : null}
      {selectedOptions.length !== 0 ? (
        <div className="tv-multiselect_selectedgroup pt-1 pb-3">
          {selectedOptions?.map((option, index) => {
            return (
              <div className="tv-multiselect_selectedgroup-item" key={index}>
                <span>{option}</span>
                <CloseCircle
                  size="16px"
                  color="#F97066"
                  variant="Bold"
                  onClick={() => removeOption(option, index)}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default MultiSelect;
