import React from 'react';
import {
  DocumentText,
  Driving,
  LoginCurve,
  Map1,
  Messages1,
  VideoSquare,
} from 'iconsax-react';

const resources = [
  {
    label: 'Fale conosco',
    icon: <Messages1 color="#ffffff" />,
    link: process.env.REACT_APP_HIRE_TOOL,
  },
  {
    label: 'Trilha do sucesso',
    icon: <VideoSquare color="#ffffff" />,
    link: process.env.REACT_APP_SUCCESS_TRAIL,
  },
  {
    label: 'Ajuda',
    icon: <Map1 color="#ffffff" />,
    link: process.env.REACT_APP_HELPLESS,
  },
  {
    label: 'Artigos de convidados',
    icon: <DocumentText color="#ffffff" />,
    link: process.env.REACT_APP_GUEST_WRITER,
  },
  {
    label: 'Seja um beta tester',
    icon: <Driving color="#ffffff" />,
    link: process.env.REACT_APP_BETA_TESTER,
  },
];

const ButtonAdditionalResources = ({ icon, label, onClick }) => (
  <div className="btn-additional-resources">
    <button onClick={onClick}>
      {icon}
      <label>{label}</label>
    </button>
    <LoginCurve
      size={20}
      color="#839be4"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    />
  </div>
);

const AdditionalResources = () => {
  return (
    <div className="additional-resources">
      <label className="additional-resources_title">Recursos Adicionais</label>

      <div>
        {resources?.map((item) => (
          <ButtonAdditionalResources
            icon={item.icon}
            label={item.label}
            onClick={() => window.open(item.link)}
          />
        ))}
      </div>
    </div>
  );
};

export default AdditionalResources;
