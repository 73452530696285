import React from 'react';
// import MultiSelect from "../../../jurisprudential-search/components/results/Inputs/MultiSelect";
import CollapsableSelect from '../../../jurisprudential-search/components/results/Inputs/CollapsableSelect';
import MultiSelect from '../Inputs/MultiSelect';
import DatePicker from '../Inputs/DatePicker';
import Checkbox from '../Inputs/Checkbox';
import { useEffect } from 'react';
import { posthog } from 'posthog-js';

const FilterWrapper = ({ filter, filters, setTempFilters, tempFilters }) => {
  // useEffect(() => { console.log("tempFilters", tempFilters) }, [tempFilters])

  const onChangeMultiSelect = (e, name, add) => {
    if (add) {
      //verifica se existe algum filtro temporário dos outros multiselects
      if (tempFilters) {
        //verifica se já existe algum filtro com o mesmo fieldName
        if (tempFilters[name]) {
          return setTempFilters({
            ...tempFilters,
            [name]: [...tempFilters[name], e],
          });
        } else {
          return setTempFilters({ ...tempFilters, [name]: [e] });
        }
      } else {
        return setTempFilters({ [name]: [e] });
      }
    } else {
      const updateTempFilters = tempFilters[name].filter((item) => item !== e);
      setTempFilters({ ...tempFilters, [name]: updateTempFilters });
    }
    posthog.capture(`Filtro: ${e}`);
  };

  const onChangeFavoralidade = (e, name) => {
    const selectedValue = e.target.value;

    if (tempFilters) {
      if (tempFilters[name]) {
        const updatedValues = tempFilters[name].includes(selectedValue)
          ? tempFilters[name].filter((value) => value !== selectedValue)
          : [...tempFilters[name], selectedValue];

        if (updatedValues.length > 0) {
          setTempFilters({ ...tempFilters, [name]: updatedValues });
        } else {
          const newTempFilters = { ...tempFilters };
          delete newTempFilters[name];
          setTempFilters(newTempFilters);
        }
      } else {
        setTempFilters({ ...tempFilters, [name]: [selectedValue] });
      }
    } else {
      setTempFilters({ [name]: [selectedValue] });
    }

    posthog.capture(`Filtro: ${selectedValue}`);
  };

  // const onChangeFavoralidade = (e, name) => {

  //     if (tempFilters) {
  //         if (tempFilters[name]) {
  //             setTempFilters({ ...tempFilters, [name]: [...tempFilters[name], e.target.value] })
  //             return
  //         } else {
  //             setTempFilters({ ...tempFilters, [name]: [e.target.value] })

  //         }

  //     } else {
  //         // console.log("não tem tempFilter", { [name]: [e.target.value] })
  //         setTempFilters({ [name]: [e.target.value] })

  //     }

  //     posthog.capture(`Filtro: ${e.target.value}`);
  //     return;
  // };

  // const onChangeFavoralidade = (e, name) => {
  //     const selectedValue = e.target.value;

  //     if (tempFilters) {
  //         if (tempFilters[name]) {
  //             const updatedValues = tempFilters[name].filter(value => value !== selectedValue);

  //             if (updatedValues.length > 0) {
  //                 setTempFilters({ ...tempFilters, [name]: updatedValues });
  //             } else {
  //                 const newTempFilters = { ...tempFilters };
  //                 delete newTempFilters[name];
  //                 setTempFilters(newTempFilters);
  //             }
  //         } else {
  //             setTempFilters({ ...tempFilters, [name]: [selectedValue] });
  //         }
  //     } else {
  //         setTempFilters({ [name]: [selectedValue] });
  //     }

  //     posthog.capture(`Filtro: ${selectedValue}`);
  // };

  const onChangeDate = (value) => {
    if (tempFilters) {
      if (tempFilters['date_range']) {
        setTempFilters({ ...tempFilters, date_range: value });
        return;
      } else {
        setTempFilters({ ...tempFilters, date_range: value });
        return;
      }
    } else {
      setTempFilters({ date_range: value });
    }
    return;
  };

  const renderFilterType = (filterType) => {
    switch (filterType) {
      case 'multiple_select':
        return (
          <MultiSelect
            label={filter.label}
            data={filter.options}
            fieldName={filter.field_name}
            onChange={onChangeMultiSelect}
          />
        );
      case 'date':
        return (
          <DatePicker
            label={filter.label}
            name={filter.field_name}
            startDate={filter.options[0]}
            endDate={filter.options[1]}
            value={{
              start_date: filter.options[0],
              end_date: filter.options[1],
            }}
            onChange={onChangeDate}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            label={filter.label}
            name={filter.field_name}
            onChange={onChangeFavoralidade}
            options={filter.options}
          />
        );

      default:
        return null;
    }
  };

  return <div>{renderFilterType(filter.type)}</div>;
};

export default FilterWrapper;
