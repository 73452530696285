import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  toggleActiveProfile: ['id'],
  setCompanyProfiles: ['companyProfiles'],
  toggleFetchingCompanyProfiles: ['fetchingCompanyProfiles'],
  setProfile: ['profile'],
  setPermissions: ['permissions'],
  setCompany: ['company'],
  loadCompanyAndProfile: [],
  resetProfiles: [],
});

/*
 * Handlers
 */
const INITIAL_STATE = {
  company: null,
  profile: null,
  permissions: {},
  permissionServices: ['tributario'],
  companiesProfiles: {},
  fetchingCompaniesProfiles: {},
};

const loadCompanyAndProfile = (state = INITIAL_STATE) => {
  const profile = JSON.parse(localStorage.getItem('profile'));
  if (profile) {
    const company = profile.company;
    return {
      ...state,
      profile,
      company,
    };
  } else {
    return { ...state };
  }
};

const setProfile = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    profile: { ...action.profile },
  };
};

const setPermissions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    permissions: { ...action.permissions },
  };
};

const setCompany = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    company: { ...action.company },
  };
};

const setCompanyProfiles = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    companiesProfiles: {
      ...state.companiesProfiles,
      ...action.companyProfiles,
    },
  };
};

const toggleFetchingCompanyProfiles = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    fetchingCompaniesProfiles: {
      ...state.fetchingCompaniesProfiles,
      ...action.fetchingCompanyProfiles,
    },
  };
};

const toggleActiveProfile = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...state.officeProfiles,
  };
};

const resetProfiles = (state = INITIAL_STATE) => {
  return {
    company: null,
    profile: null,
    companiesProfiles: {},
    fetchingCompaniesProfiles: {},
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.TOGGLE_ACTIVE_PROFILE]: toggleActiveProfile,
  [Types.SET_COMPANY_PROFILES]: setCompanyProfiles,
  [Types.TOGGLE_FETCHING_COMPANY_PROFILES]: toggleFetchingCompanyProfiles,
  [Types.SET_PROFILE]: setProfile,
  [Types.SET_PERMISSIONS]: setPermissions,
  [Types.SET_COMPANY]: setCompany,
  [Types.LOAD_COMPANY_AND_PROFILE]: loadCompanyAndProfile,
  [Types.RESET_PROFILES]: resetProfiles,
});
