import { Button } from '../../../../components/TuriviusComponents/Buttons';
import { ArrowLeft } from 'iconsax-react';
import { OtpInput } from '../../../../components/TuriviusComponents/Inputs/OtpInput';
import { useSendEmailToken, useTokenLogin } from '../../../../api/auth';
import {
  Dialog,
  DialogTitle,
  DialogClose,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogDescription,
} from '../../../../components/ui/dialog';
import { useTokenLoginStore } from '../../../../store/ducks/tokenLogin';
import { makeTheGoogleLogin } from '../../../../services/authentication';
import { useEffect, useState } from 'react';
import { Whisper, Tooltip } from 'rsuite';

export const EmailToken = ({ goLogin, properties, setStep }) => {
  const { data, mutate, isPending, isSuccess, isError } = useTokenLogin();
  const { email, code } = useTokenLoginStore();
  const { mutate: sendEmailToken } = useSendEmailToken();
  const [seconds, setSeconds] = useState(0);

  const tokenLogin = () => {
    mutate({ code, email });
  };

  const resendEmailToken = () => {
    if (seconds !== 0) return;
    sendEmailToken({ email });
    setSeconds(60);
  };

  useEffect(() => {
    if (isSuccess) {
      return makeTheGoogleLogin(data.data, properties, setStep);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds((state) => state - 1), 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <div className="login-component">
      <div className="login-component_header">
        <Whisper
          trigger={'hover'}
          placement="top"
          speaker={<Tooltip>Voltar</Tooltip>}
        >
          <ArrowLeft
            className="cursor-pointer"
            style={{ color: '#0D91FE', marginBottom: 26 }}
            onClick={goLogin}
          />
        </Whisper>
        <h2>Confirme o seu e-mail</h2>
        <p className="font-poppins text-base leading-7 min-w-[464px]">
          Precisamos verificar seu endereço de e-mail. Enviamos um e-mail para{' '}
          <strong className="font-semibold">{email}</strong> com um código de 6
          dígitos que expira em 15 minutos. Digite esse código abaixo.
        </p>
      </div>
      <div className="login-component_body">
        <label htmlFor="token-email">Insira os 6 dígitos aqui</label>

        <div className="mb-[11px]">
          <OtpInput onSubmit={() => tokenLogin()} />
        </div>

        {isError && (
          <span className="my-2" style={{ color: '#F04438' }}>
            Há algo errado, tente novamente.
          </span>
        )}

        <Button
          type="submit"
          appearance="default"
          onClick={tokenLogin}
          loading={isPending}
        >
          Entrar
        </Button>
        <Button appearance="outline-none" onClick={goLogin}>
          Cancelar
        </Button>

        <Dialog>
          <DialogTrigger disabled={seconds !== 0}>
            <div className="flex gap-1 justify-center items-center">
              <span
                style={{
                  color: '#0069f5',
                  cursor: seconds === 0 ? 'pointer' : 'not-allowed',
                }}
                onClick={resendEmailToken}
              >
                Reenviar
              </span>
              {seconds > 0 && (
                <span className="ml-1">(aguarde {seconds} segundos)</span>
              )}
            </div>
          </DialogTrigger>
          <DialogContent
            style={{
              width: 350,
              height: 286,
              padding: '54px 53px 48px',
            }}
          >
            <DialogHeader>
              <DialogTitle
                className="text-center"
                style={{ fontSize: 16, marginBottom: 11 }}
              >
                Enviamos um novo e-mail
              </DialogTitle>
              <DialogDescription
                className="text-center"
                style={{
                  fontSize: 14,
                  color: '#475467',
                }}
              >
                Verifique sua caixa de spam do email:{' '}
                <b style={{ color: '#101828' }}>{email}</b>
              </DialogDescription>
            </DialogHeader>
            <div className="gap-[10px] flex flex-col">
              <DialogClose
                className="gap-[10px] flex w-[244px] h-9 rounded-md border border-gray-300 py-2 px-4 justify-center items-center"
                style={{
                  backgroundColor: '#053AC8',
                  color: 'white',
                  fontSize: 12,
                }}
              >
                Ok
              </DialogClose>
              <a
                className="gap-[10px] flex w-[244px] h-9 rounded-md py-2 px-4 justify-center items-center m-0"
                style={{
                  backgroundColor: 'white',
                  color: '#475467',
                  fontSize: 12,
                  border: '1px solid #D0D5DD',
                  textDecoration: 'none',
                }}
                href="https://api.whatsapp.com/send?phone=%2B554891239699&text=Ol%C3%A1%21+Preciso+de+ajuda%21"
              >
                Falar com atendimento
              </a>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
