import React from 'react';
import { Setting4 } from 'iconsax-react';
import { Dropdown } from 'rsuite';
import { withRouter } from 'react-router-dom';

const AccountSettingsOption = ({ eventKey, isAdmin, ...props }) => {
  return isAdmin ? (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        onClick={() => props.history.push('/admin')}
      >
        <Setting4 size="20" color="#0069F5" />
        <label> Configurações da Conta </label>
      </button>
    </Dropdown.Item>
  ) : null;
};

export default withRouter(AccountSettingsOption);
