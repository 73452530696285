import { InfoCircle, TickCircle, CloseCircle } from 'iconsax-react';
import React from 'react';

export type AlertProps = {
  type: 'info' | 'warning' | 'success' | 'error';
  headerTitle?: string;
  children: string | React.ReactElement;
  className?: string;
};

export const Alert: React.FC<AlertProps> = ({
  type,
  headerTitle,
  children,
  className,
}) => {
  return (
    <div
      className={`container-alert tv-alert tv-alert-${type} ${
        className ? `${className}` : ''
      }`}
      role="alert"
    >
      <div className={`tv-alert_header`}>
        {type === 'info' && (
          <InfoCircle variant="Bold" color="#18A0FB" size={18} />
        )}
        {type === 'warning' && (
          <InfoCircle variant="Bold" color="#F79009" size={18} />
        )}
        {type === 'success' && (
          <TickCircle variant="Bold" color="#1BC47D" size={18} />
        )}
        {type === 'error' && (
          <CloseCircle variant="Bold" color="#F24822" size={18} />
        )}
        <span className="font-semibold">{headerTitle}</span>
      </div>
      <p className={`tv-alert_text`}>{children}</p>
    </div>
  );
};
export default Alert;
