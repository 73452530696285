import React from 'react';
import { Grid, Row, Col, ButtonGroup } from 'rsuite';
import TuriviusEntityIndicator from '../turivius-entity-indicator/TuriviusEntityIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import TuriviusIconButton from '../../buttons/TuriviusIconButton';
import TuriviusAlertMessage from '../../component-encapsulation/turivius-message/TuriviusAlertMessage';

const TuriviusCardHeader = ({ entity }) => {
  const onWhole = () => {
    TuriviusAlertMessage.push({ message: 'Abrindo íntegra...' });
  };

  const onQuote = () => {
    TuriviusAlertMessage.push({
      message: 'Copiado para a área de transferência',
    });
  };

  return (
    <div className="turivius-card-header-container">
      <Grid fluid>
        <Row>
          <Col sm={20}>
            <TuriviusEntityIndicator className="indicator" initials={entity} />
            <p className="name">{name}</p>
          </Col>
          <Col sm={4}>
            <ButtonGroup vertical>
              <TuriviusIconButton
                className="icon-btn-action"
                icon={
                  <FontAwesomeIcon className={'rs-icon'} icon={faDownload} />
                }
                appearance={'ghost'}
                onClick={onWhole}
              >
                ÍNTEGRA
              </TuriviusIconButton>
              <TuriviusIconButton
                className="icon-btn-action"
                icon={
                  <FontAwesomeIcon className={'rs-icon'} icon={faQuoteRight} />
                }
                appearance={'ghost'}
                onClick={onQuote}
              >
                CITAR
              </TuriviusIconButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default TuriviusCardHeader;
