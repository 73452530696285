import React, { Children, useEffect } from 'react';

export type TagProps = {
  type: 'default' | 'plain';
  size: 'sm' | 'md';
  // children: React.ReactElement;
  children: string;
  color:
    | 'primary'
    | 'gray'
    | 'success'
    | 'warning'
    | 'danger'
    // | "yellow"
    | 'orange'
    | 'violet'
    | 'azure';
  dot?: boolean;
  avatar?: React.ReactElement;
  iconPosition?: 'left' | 'right';
  className?: string;
};

export const Tag: React.FC<TagProps> = ({
  type,
  size,
  children,
  color,
  className,
}) => {
  return (
    <div
      className={`tv-tag tv-tag-${size} tv-tag-${type}_${color} ${
        className ? `${className}` : ''
      }`}
    >
      <div className="font-semibold flex items-center h-7">{children}</div>
    </div>
  );
};

export default Tag;
