import React from 'react';
import MonitoringSearch from './MonitoringSearch';
import OrderBy from './OrderBy';
import MonitoringCardsContainer from './MonitoringCardsContainer';

const MyMonitoringSession = ({
  entities,
  monitoringList,
  monitoringCount,
  pageMonitoring,
  loadingMyMonitoring,
  getMonitoring,
  onChangeFilters,
  onCreateMonitoring,
  onDeleteMonitoring,
  onSharedWith,
  onEditMonitoring,
}) => {
  return (
    <div className="monitoring-my-session">
      <MonitoringSearch
        title="Meus Monitoramentos"
        whereSearch="my-monitoring"
        entities={entities}
        getMonitoring={getMonitoring}
        onCreateMonitoring={onCreateMonitoring}
        onSearch={onChangeFilters}
        id="my-monitoring"
      />
      <OrderBy
        whereSearch="my-monitoring"
        changeFilters={onChangeFilters}
        monitoringCount={monitoringCount}
      />

      <MonitoringCardsContainer
        whereSearch="my-monitoring"
        terms={monitoringList}
        monitoringCount={monitoringCount}
        pageMonitoring={pageMonitoring}
        loading={loadingMyMonitoring}
        entities={entities}
        getMonitoring={getMonitoring}
        changePage={onChangeFilters}
        onDeleteMonitoring={onDeleteMonitoring}
        onSharedWith={onSharedWith}
        onEditMonitoring={onEditMonitoring}
        onCreateMonitoring={onCreateMonitoring}
      />
    </div>
  );
};

export default MyMonitoringSession;
