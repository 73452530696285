import { Turivius } from '../../../ui';
import './style.scss';

const SLATable = ({ title, description, data }) => {
  const tableFormatted = {};
  let cols = [];
  let rows = [];

  const colsValue = Object.keys(data).filter((value) => value != 'index');
  colsValue.forEach((c) => {
    cols.push({
      key: c,
      label: c,
      fixed: true,
      flexGrow: 1,
      align: 'center',
    });
  });

  const values = Object.values(data[colsValue[0]]);

  values.forEach((_, index) => {
    let newObj = {};

    colsValue?.map((col) => {
      let obj = col;
      newObj[obj] = data[col][index];
    });
    rows.push(newObj);
  });

  tableFormatted['cols'] = cols;
  tableFormatted['rows'] = rows;

  return (
    <div className="sla">
      <h5 className="sla-title">{title}</h5>
      <div className="sla-description">{description}</div>
      <Turivius.TuriviusTable
        autoHeight
        columns={tableFormatted.cols}
        data={tableFormatted.rows}
        paginate={false}
      />
    </div>
  );
};

export default SLATable;
