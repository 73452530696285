import React from 'react';

const Checkbox = ({ label, options, onChange, name }) => {
  return (
    <div className="tv-checkbox_container">
      <p className="tv-checkbox_label pb-1">{label}</p>
      <div className="tv-checkbox_checkgroup">
        {options?.map((option, index) => {
          return (
            <div key={index} className="tv-checkbox_checkgroup--item">
              <input
                type="checkbox"
                value={option.value}
                className="tv-checkbox_checkgroup--item_check "
                id={option.value}
                onChange={(e) => onChange(e, name)}
              />
              <label
                className="tv-checkbox_checkgroup--item_label pl-1"
                htmlFor={option.value}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Checkbox;
