import { useEffect, useState } from 'react';
import { InputWithIcon } from '../../../../components/TuriviusComponents/Inputs';
import { ArrowLeft, Lock1, Sms } from 'iconsax-react';
import { Divider, Whisper, Tooltip } from 'rsuite';
import OthersLoginOptions from './OthersLoginOptions';
import { Button } from '../../../../components/TuriviusComponents/Buttons';
import { onLogIn } from '../../../../services/authentication';
import { useSendEmailToken } from '../../../../api/auth';
import { useTokenLoginStore } from '../../../../store/ducks/tokenLogin';
import { CreateAccount } from './CreateAccount';
import { requester } from '../../../../requesters';

const stepsInfos = [
  {
    type: 'email',
    step: 'Passo 1',
    title: 'Digite o seu e-mail para continuar',
    subTitle:
      'Entre na sua conta. Se você não tiver conta, precisará criar uma.',
    icon: Sms,
    label: 'Seu e-mail',
    placeholder: 'Insira seu email aqui',
    errorMessage: 'E-mail não cadastrado, confira a ortografia.',
    buttonLabel: 'Continuar',
  },
  {
    type: 'password',
    step: 'Passo 2',
    title: 'Digite a senha',
    subTitle: null,
    icon: Lock1,
    label: 'Sua senha',
    placeholder: 'Insira senha aqui',
    errorMessage:
      'Não foi possível entrar. Digite seu e-mail e senha mais uma vez e tente novamente. Se não se lembrar dos detalhes, use o link "Envie um código de acesso único para o seu e-mail" abaixo.',
    buttonLabel: 'Entrar',
  },
];

export const LoginComponent = ({
  isMob,
  properties,
  forgotPassword,
  emailToken,
  SSOLogin,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [step, setStep] = useState(0);
  const [credencials, setCredencials] = useState({
    email: '',
    password: '',
  });
  const [credencialsError, setCredencialsError] = useState({
    email: false,
    password: false,
  });

  const { mutate } = useSendEmailToken();
  const { setEmail } = useTokenLoginStore();

  function backStep() {
    setStep(0);
  }

  function onChangeCredencials(type, value) {
    setCredencials({
      ...credencials,
      [type]: value,
    });
  }

  const validateCredencials = async () => {
    if (step === 0) {
      try {
        setLoading(true);
        const isEmailValid = await requester.profiles.checkEmail(
          encodeURIComponent(credencials.email),
        );
        if (isEmailValid.exists) {
          setCredencialsError({
            ...credencialsError,
            email: false,
          });
          setStep(1);
        } else {
          setCredencialsError({
            ...credencialsError,
            email: true,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setCredencialsError({
        ...credencialsError,
        password: credencials.password < 8 ? true : false,
      });
      if (!(credencials.password < 8)) {
        onLogIn(
          credencials.email,
          credencials.password,
          setLoading,
          setErrorLogin,
          properties,
        );
      }
    }
  };

  function onPressEnter(e) {
    if (e.key === 'Enter') {
      validateCredencials();
    }
  }

  const sendEmailToken = () => {
    setEmail(credencials.email);
    mutate({ email: credencials.email });
    emailToken();
  };

  useEffect(() => {
    if (!errorLogin) return setCredencialsError({ password: false });
    setCredencialsError({ password: true });
  }, [errorLogin]);

  return (
    <div className="login-component">
      <img className="logo" src="new_logo.png" />
      <div className="login-component_header">
        {step > 0 && (
          <Whisper
            trigger={'hover'}
            placement="top"
            speaker={<Tooltip>Voltar</Tooltip>}
          >
            <ArrowLeft
              className="cursor-pointer"
              style={{ color: '#0D91FE', marginBottom: 26 }}
              onClick={backStep}
            />
          </Whisper>
        )}
        <small>{stepsInfos[step].step}</small>
        <h2>{stepsInfos[step].title}</h2>
        <p>{stepsInfos[step].subTitle}</p>

        {step === 1 ? (
          <div className="login-component_header_back-email">
            <p>Entre na conta com o seu e-mail:</p>
            <p className="login-component_header_back-email_email">
              {credencials.email}
              <button onClick={backStep}>editar</button>
            </p>
          </div>
        ) : null}
      </div>

      <div className="login-component_body">
        <label htmlFor={stepsInfos[step].type}>{stepsInfos[step].label}</label>

        <InputWithIcon
          isMob={isMob}
          id={stepsInfos[step].type}
          type={stepsInfos[step].type}
          icon={stepsInfos[step].icon}
          placeholder={stepsInfos[step].placeholder}
          onChange={(value) =>
            onChangeCredencials(stepsInfos[step].type, value)
          }
          value={credencials[stepsInfos[step].type]}
          errorInput={credencialsError[stepsInfos[step].type]}
          errorMessage={stepsInfos[step].errorMessage}
          forgotPassword={forgotPassword}
          onKeyUp={(e) => onPressEnter(e)}
        />

        {credencialsError.email && step === 0 ? (
          <div className="mb-[11px]"></div>
        ) : null}

        {credencialsError.password && step === 1 ? (
          <small
            className="text-xs font-light  mb-[13px]"
            style={{ color: '#4d4d4d' }}
          >
            (Maiúsculas e minúsculas)
          </small>
        ) : null}

        <Button
          loading={loading}
          appearance="default"
          onClick={() => validateCredencials()}
        >
          {stepsInfos[step].buttonLabel}
        </Button>
      </div>

      <div className="login-component_footer">
        {step === 0 ? (
          <>
            <Divider>ou</Divider>
            <OthersLoginOptions SSOLogin={SSOLogin} />
          </>
        ) : (
          <div className="single-login-message">
            Não consegue entrar?
            <a onClick={sendEmailToken}>
              {' '}
              <span style={{ color: '#0069f5' }}>
                Envie um código de acesso único para o seu e-mail
              </span>
            </a>
          </div>
        )}
      </div>
      {step === 0 && <CreateAccount />}
    </div>
  );
};
