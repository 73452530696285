import { request } from './requester';

function getSSO(host) {
  return request({
    type: 'GET',
    microservice: 'sso',
    action: 'host_dict?host=' + host,
  }).then((data) => {
    return data;
  });
}

function getUser() {
  return request({
    type: 'GET',
    microservice: 'sso',
    action: 'user',
    auth: 'token',
  }).then((data) => {
    return data;
  });
}

export const sso = {
  getSSO,
  getUser,
};
