export const MICROSERVICES = {
  webservice: process.env.REACT_APP_WEBSERVICE,
  cards: process.env.REACT_APP_CARDS,
  collections: process.env.REACT_APP_COLLECTIONS,
  jurimetrics: process.env.REACT_APP_JURIMETRICS,
  profiles: process.env.REACT_APP_PROFILES,
  help: process.env.REACT_APP_HELP,
  notifications: process.env.REACT_APP_NOTIFICATIONS,
  notifications_ws: process.env.REACT_APP_NOTIFICATIONS_WS,
  search_notifications: process.env.REACT_APP_SEARCH_NOTIFICATIONS,
  monitoring: process.env.REACT_APP_MONITORING,
  reports: process.env.REACT_APP_REPORTS,
};
