import React from 'react';
import { RadioGroup, Radio } from 'rsuite';
import PropTypes from 'prop-types';

import './TuriviusRadioButton.scss';

const TuriviusRadioButton = ({
  helperText,
  radioOption,
  context,
  posthog,
  inteiroTeor,
  label,
  ...props
}) => {
  function handleChange(newOption) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
    }
    props.changeInteiroTeor(newOption);
  }

  // useEffect(() => {
  //     if (!inteiroTeor) {
  //         props.changeInteiroTeor(['ementa'])
  //     }
  // })

  return (
    <div className="inteiro-teor-filter">
      <div className="title">
        <label>{label}</label>
      </div>

      <RadioGroup
        name="radioList"
        inline
        appearance="picker"
        onChange={() => handleChange()}
        value={JSON.stringify(inteiroTeor)}
      >
        {radioOption?.map((option, i) => (
          <Radio key={i} value={option?.value}>
            {option?.title}
          </Radio>
        ))}
      </RadioGroup>
      {helperText ? (
        <div className="input-field-header" style={{ marginBottom: 12 }}>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

TuriviusRadioButton.propTypes = {
  context: PropTypes.string,
  radioOption: PropTypes.object,
  posthog: PropTypes.object,
  helperText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  // REDUX
  filters: PropTypes.object,
};

TuriviusRadioButton.defaultProps = {
  label: '',
};

export default TuriviusRadioButton;
