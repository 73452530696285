import React from 'react';
import { withRouter } from 'react-router-dom';

const NotificationHeader = function (props) {
  return (
    <div className="rn-header">
      <h5 style={{ display: 'inline-flex', alignItems: 'center' }}>
        {props.children}
      </h5>
    </div>
  );
};

export default withRouter(NotificationHeader);
