import PropTypes from 'prop-types';
import './style.scss';
import { operators } from '../../../../../config/operadores';
import OperatorCard from './OperatorCard';

const OperatorsHelperOpen = ({ addOperator }) => {
  return (
    <div className="operators">
      <h6 className="font-semibold mt-2">Conheça nossos operadores</h6>
      <span>
        Operadores são formas mais inteligentes de encontrar os melhores
        resultados
      </span>
      <div className="operators-cards-container mt-2">
        {operators?.map((item, index) => {
          return (
            <OperatorCard
              operator={item}
              key={index}
              addOperator={addOperator}
            />
          );
        })}
      </div>
    </div>
  );
};

OperatorsHelperOpen.propTypes = {
  addOperator: PropTypes.func,
};

OperatorsHelperOpen.defaultProps = {
  addOperator: () => {},
};

export default OperatorsHelperOpen;
