import { request } from './requester';

function valid(finger) {
  return request({
    type: 'POST',
    microservice: 'block',
    action: 'fingerprint_active_user',
    auth: 'token',
    data: {
      fingerprint: finger,
    },
  }).then((data) => {
    return data;
  });
}
function remove() {
  return request({
    type: 'DELETE',
    microservice: 'block',
    action: 'fingerprint_last_action',
    auth: 'token',
  }).then((data) => {
    return data;
  });
}
function sessionStore(finger, system, browser) {
  return request({
    type: 'POST',
    microservice: 'block',
    action: `fingerprints/${finger}`,
    auth: 'token',
    data: {
      fingerprint: finger,
      os: system,
      browser: browser,
    },
  }).then((data) => {
    return data;
  });
}
function sessionDeletionCode(finger, email) {
  return request({
    type: 'DELETE',
    microservice: 'block',
    action: `fingerprints/${finger}`,
    auth: 'token',
    data: {
      email: email,
    },
  }).then((data) => {
    return data;
  });
}
function sessionDeletion(finger, delete_code) {
  return request({
    type: 'DELETE',
    microservice: 'block',
    action: `fingerprints/${finger}/${delete_code}`,
    auth: 'token',
  }).then((data) => {
    return data;
  });
}
function sessionManager() {
  return request({
    type: 'GET',
    microservice: 'block',
    action: `fingerprints`,
    auth: 'token',
  }).then((data) => {
    return data;
  });
}

export const block = {
  valid,
  remove,
  sessionStore,
  sessionDeletionCode,
  sessionDeletion,
  sessionManager,
};
