import React from 'react';

export type SkeletonProps = {
  type?: 'circle' | 'square' | 'default';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
};

export const Skeleton: React.FC<SkeletonProps> = ({
  type = 'default',
  size,
  className,
}) => {
  return (
    <div
      className={`tv-skeleton ${type && `tv-skeleton_${type}`} ${
        size && `tv-skeleton_${type}-${size} ${className && `${className}`}`
      }`}
    />
  );
};

export default Skeleton;
