import {
  faCompass,
  faArrowTrendDown,
  faBuildingColumns,
} from '@fortawesome/free-solid-svg-icons';
export const topics = [
  'Férias',
  'Horas Extras',
  'FGTS',
  'Aviso Prévio',
  'Desconto Sindical',
  'Base de Cálculo',
  'Multa do Artigo 477 da CLT',
  'Adicional de Insalubridade',
  'Danos Morais',
  'Periculosidade',
  'Acidente de Trabalho',
];

export const listing = [
  {
    id: 'cnpj',
    title: 'Jurimetria por CNPJ',
    icon: 'ri-building-4-line',
    description:
      'Tome <b>decisões estratégicas</b> com base no contencioso da empresa nos Tribunais.<br/> <br/> Aplique filtros por assunto e/ou data e <b>personalize gráficos de jurimetria<b>.',
    backgroundColor: '#84D4FF',
    fontColor: '#02032F',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Qual o CNPJ a ser pesquisado?',
        description:
          'Analise o contencioso da empresa nos Tribunais por meio de filtros personalizáveis.',
        field: 'cnpj',
        type: 'cnpj-input',
      },
    ],
  },
  {
    id: 'assunto',
    title: 'Jurimetria por Assunto',
    icon: 'ri-swap-line',
    description:
      'Gere <b>gráficos da tendência</b> do número de relcamatórias envolvendo determinado pedido nos Tribunais. <br/><br/> Potencialize a sua <b>estratégia com base em dados</b> e filtros personalizáveis.',
    backgroundColor: '#02032F',
    fontColor: '#84D4FF',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Qual o assunto a ser pesquisado?',
        description:
          'Selecione de 1 a 5 assuntos para análise de jurimetria nos Tribunais do Trabalho.',
        type: 'tag-input',
        field: 'assunto',
      },
    ],
  },
  {
    id: 'estado',
    title: 'Jurimetria por Estado',
    icon: 'ri-compass-3-line',
    description:
      'Visualize a <b>tendência de julgamentos</b> de determinado Tributal Regional do Trabalho. <br/><br/> Realize a <b>análise comparativa</b> de dois ou mais Tribunais conforme os filtros escolhidos.',
    backgroundColor: '#84D4FF',
    fontColor: '#02032F',
    questions: [
      {
        questionId: 1,
        required: true,
        question: 'Quais os tribunais de interesse?',
        description:
          'Selecione um ou mais tribunais e crie dashboards de jurimetria por tribunal.',
        field: 'estado',
        type: 'toggle-button',
      },
    ],
  },
];
