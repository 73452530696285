import { useEffect, useState } from 'react';
import { Button, Message, Modal, toaster } from 'rsuite';
import SelectSharePeopleMonitoring from '../../components/MonitoringConfigs/SelectSharePeopleMonitoring';
import { Button as TuringButton } from '../../../../ds-components';
import { Share } from 'iconsax-react';

const ShareModalMonitoring = ({ monitoring, onSharedWith, getMonitoring }) => {
  const [modal, setModal] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModal = () => setModal(!modal);

  const setData = () => {
    setSharedWith(monitoring.shared_with);
  };
  const onShared = () => {
    setLoading(true);
    onSharedWith(monitoring.id, sharedWith)
      .then(() => {
        toaster.push(
          <Message
            showIcon
            closable
            type="success"
            header="Monitoramento compartilhado com sucesso"
            duration={4000}
          >
            O termo {monitoring.query} foi compartilhado.
          </Message>,
        );
        handleModal();
        getMonitoring();
      })
      .catch((err) => {
        toaster.push(
          <Message
            showIcon
            closable
            type="error"
            header="Erro ao compartilhar monitoramento"
            duration={4000}
          >
            Ocorreu um erro ao compartilhar o termo. Tente novamente mais tarde.
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <>
      {/* <IconButton
                block
                className="menu-button"
                onClick={handleModal}
                icon={<i className="ri-share-line menu-button_icon rs-icon" />}
            >
                Compartilhar
            </IconButton> */}
      <TuringButton
        appearance="secondary"
        size="sm"
        icon={<Share color="#667085" size={16} />}
        onClick={handleModal}
        fullWidth
      >
        Compartilhar{' '}
      </TuringButton>

      <Modal open={modal} onClose={handleModal}>
        <Modal.Title>Compartilhar Termo</Modal.Title>
        <Modal.Body>
          <p style={{ padding: '0 0 0 10px' }}>
            Você está compartilhando o termo <b>{monitoring.query}</b>.
            Selecione um ou mais usuário para monitorar este termo:
          </p>

          <SelectSharePeopleMonitoring
            menu
            sharePeople={sharedWith}
            setSharePeople={setSharedWith}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModal}>Cancelar</Button>
          <Button loading={loading} onClick={onShared}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareModalMonitoring;
