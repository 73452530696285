import { Col, Grid, Pagination, Row } from 'rsuite';
import { Turivius } from '../../../../../ui';
import CollectionFolder from '../CollectionFolder/CollectionFolder';
import CollectionWithoutResults from '../CollectionWithoutResults';
import NoCollection from '../NoCollection';

const CollectionCenterCard = ({
  onSearch,
  onChangePage,
  onCleanFilter,
  loading,
  collections,
  page,
  filter,
  shared,
}) => {
  if (!collections?.results?.length) return;
  return (
    <Grid fluid>
      <Row>
        {loading ? (
          <Col xs={24} sm={24} style={{ height: 150 }}>
            <Turivius.TuriviusPreloader />
          </Col>
        ) : collections?.results?.length > 0 ? (
          <>
            {filter ? (
              <p className="information-collection-search">
                {collections?.count} resultado(s) encontrado(s) para a pesquisa{' '}
                <b>{filter}</b>
              </p>
            ) : null}
            {collections?.results?.map((collection, index) =>
              index === 0 ? (
                <CollectionFolder
                  key={collection.id}
                  id={'collection-folder-selected'}
                  shared={shared}
                  onSearch={onSearch}
                  collection={collection}
                  page={page}
                  loader={'userCollectionsLoading'}
                />
              ) : (
                <CollectionFolder
                  key={collection.id}
                  id="collection-folder"
                  shared={shared}
                  onSearch={onSearch}
                  collection={collection}
                  page={page}
                  loader={'userCollectionsLoading'}
                />
              ),
            )}
          </>
        ) : collections?.results?.length === 0 && filter ? (
          <CollectionWithoutResults
            onCleanFilter={onCleanFilter}
            filter={filter}
          />
        ) : (
          <NoCollection getCollection={onSearch} />
        )}
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Pagination
          prev
          last
          next
          first
          className="collections-paginator"
          total={collections.count}
          limit={12}
          maxButtons={5}
          activePage={page}
          onChangePage={(page) => onChangePage(page)}
        />
      </Row>
    </Grid>
  );
};
export default CollectionCenterCard;
