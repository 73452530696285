import { Posthog } from '../posthog/posthog';
import { request } from './requester';

function changePassword(userId, oldPassword, newPassword, etContext) {
  return request({
    type: 'POST',
    microservice: 'webservice',
    action: `platform/change_password/`,
    auth: 'token',
    data: {
      old_password: oldPassword,
      new_password: newPassword,
    },
  }).then((response) => {
    Posthog.capture(etContext, {
      action: 'change_password',
      id: userId,
    });
    return response;
  });
}

function login(username, password) {
  return request({
    type: 'POST',
    microservice: 'webservice',
    action: 'platform/login/',
    data: {
      username,
      password,
    },
  }).then((credentials) => {
    return credentials;
  });
}

function loginWithHash(hash, password) {
  return request({
    type: 'POST',
    microservice: 'webservice',
    action: 'platform/new_login/',
    data: {
      hash,
      password,
    },
  }).then((credentials) => {
    return credentials;
  });
}

function recoverPassword(email) {
  return request({
    type: 'POST',
    microservice: 'webservice',
    action: 'platform/forgotten_password/',
    data: {
      email,
    },
  }).then((response) => {
    return response;
  });
}

export const authentication = {
  changePassword,
  login,
  loginWithHash,
  recoverPassword,
};
