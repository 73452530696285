import { useEffect } from 'react';
import SearchCollection from '../Collections/SearchCollection';
import { useState } from 'react';
import CollectionCenterCard from '../Collections/CollectionCenterCard';
import CollectionOrderBy from '../Collections/CollectionOrderBy';

const MyCollectionSession = ({ getCollections }) => {
  const [collections, setCollections] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-last_update');

  function onSearch(value = '', page = 1) {
    setPage(page);
    getCollections(
      'my_collections',
      value,
      page,
      ordering,
      setCollections,
      setLoading,
    );
  }

  function onChangePage(page) {
    setPage(page);
    getCollections(
      'my_collections',
      filter,
      page,
      ordering,
      setCollections,
      setLoading,
    );
  }

  function onCleanFilter() {
    setFilter('');
    getCollections(
      'my_collections',
      '',
      page,
      ordering,
      setCollections,
      setLoading,
    );
  }

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <div style={{ padding: ' 0 24px 24px 24px' }}>
      <SearchCollection
        title="Minhas Coleções"
        setFilter={setFilter}
        onSearch={onSearch}
        filter={filter}
        onChangeFilter={setFilter}
        id="my-collections"
      />
      <CollectionOrderBy
        ordering={ordering}
        setOrdering={setOrdering}
        onOrdering={onSearch}
        collectionCount={collections.results?.length}
      />
      <CollectionCenterCard
        loading={loading}
        collections={collections}
        page={page}
        filter={filter}
        shared={true}
        onCleanFilter={onCleanFilter}
        onChangePage={onChangePage}
        onSearch={onSearch}
      />
    </div>
  );
};

export default MyCollectionSession;
