import { request } from './requester';
import { Posthog } from '../posthog/posthog';

// function getSearchPlots(dataQuery) {
//     return request({
//         type: 'GET',
//         microservice: 'jurimetrics',
//         action: `search_jurimetrics`,
//         auth: 'token',
//         data: dataQuery
//     })
//         .then(plots => {
//             return plots
//         })
// }

function getTokenTrabalhista() {
  return request({
    type: 'GET',
    microservice: 'jurimetrics_trabalhista',
    action: `token`,
    auth: 'token',
  }).then((b64) => {
    return b64;
  });
}

function getAssuntos() {
  return request({
    type: 'GET',
    microservice: 'jurimetrics_trabalhista',
    action: `assuntos`,
    auth: 'token',
  }).then((b64) => {
    return b64;
  });
}

function getEstados() {
  return request({
    type: 'GET',
    microservice: 'jurimetrics_trabalhista',
    action: `trts`,
    auth: 'token',
  }).then((b64) => {
    return b64;
  });
}

function getSearchPlots(dataQuery) {
  return request({
    type: 'GET',
    microservice: 'jurimetrics',
    action: `search_jurimetrics`,
    auth: 'token',
    data: {
      params: btoa(JSON.stringify(dataQuery)),
    },
  }).then((plots) => {
    return plots;
  });
}

function getCardPlots(relatorName) {
  return request({
    type: 'GET',
    microservice: 'jurimetrics',
    action: `relator_jurimetrics_by_name/${relatorName}`,
    auth: 'token',
  }).then((plots) => {
    return plots;
  });
}

function getClassificationSuggestions(cardId) {
  return request({
    type: 'GET',
    microservice: 'cards',
    action: `user_correction/${cardId}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function suggestNewClassification(cardId, option) {
  return request({
    type: 'POST',
    microservice: 'cards',
    action: `user_correction/${cardId}`,
    auth: 'token',
    data: {
      suggestion: option,
    },
  }).then((response) => {
    Posthog.capture('SuggestNewClassification', {
      action: 'suggest_new_classification',
      id: option,
      to: cardId,
    });
    return response;
  });
}

function reviewClassification(cardId, acceptedOption, accepted, refused) {
  return request({
    type: 'PATCH',
    microservice: 'cards',
    action: `user_correction/${cardId}/review`,
    auth: 'token',
    data: {
      accepted_class: acceptedOption,
      suggestion_ids: accepted,
      rejected_ids: refused,
    },
  }).then((response) => {
    return response;
  });
}

export const jurimetrics = {
  getSearchPlots,
  getCardPlots,
  getTokenTrabalhista,
  getAssuntos,
  getEstados,
  getClassificationSuggestions,
  suggestNewClassification,
  reviewClassification,
};
