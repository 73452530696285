import React from 'react';

export type NavProps = {
  navItems: [{ id: number; title: string; 'data-testid'?: string }];
  activeTab: number;
  handleChange: (activeTab: number) => void;
};

export const Nav: React.FC<NavProps> = ({
  navItems,
  activeTab,
  handleChange,
}) => {
  return (
    <div className="tv-nav">
      <ul className="tv-nav_tabs">
        {navItems.map((item) => {
          return (
            <li
              key={item.id}
              className={`tv-nav_tabs-items${
                activeTab === item.id ? ' tv-nav_tabs-items-active' : ''
              }`}
              {...(item['data-testid'] && {
                'data-testid': item['data-testid'],
              })}
            >
              <a onClick={() => handleChange(item.id)}>{item.title}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
