export const CreateAccount = () => {
  return (
    <div className="flex right-[55px] top-[55px] sm:absolute">
      <span>
        Não tem conta?{' '}
        <a
          href="https://checkout.turivius.com"
          className="text-blue-600 font-semibold"
        >
          Criar conta
        </a>
      </span>
    </div>
  );
};
