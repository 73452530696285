import React from 'react';
import { Button } from 'rsuite';
import { Modal } from 'rsuite';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class RemoveCommentButton extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <>
        <Modal open={open} onClose={this.handleClose} role="alertdialog">
          <Modal.Header>
            <Modal.Title>Remover Comentário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza que deseja remover este comentário?{' '}
            <b>Essa ação não poderá ser desfeita</b>.
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-margin secondary" onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button
              className="btn-margin danger"
              onClick={() =>
                this.props.removeComment(this.props.commentId, this.handleClose)
              }
            >
              Remover
            </Button>
          </Modal.Footer>
        </Modal>
        <IconButton edge="end" onClick={this.handleOpen}>
          <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: 16 }} />
        </IconButton>
      </>
    );
  }
}

export default withRouter(RemoveCommentButton);
