import { useEffect } from 'react';
import { Col, Grid } from 'rsuite';
import { connect } from 'react-redux';
import { AnyAction, Dispatch, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Creators as LoginActions } from '../../../../store/ducks/login';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import { FirstLoginComponent } from '../components/FirstLogin';

const ResetPassword = (props: any) => {
  useEffect(() => {
    document.title = 'Resetar Senha';
  }, []);

  return (
    <Grid
      fluid
      className="login-container"
      style={{ padding: 0, display: 'flex' }}
    >
      <Col
        className="login-container_brand-container"
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{ padding: 0 }}
        xsHidden
        smHidden
      >
        <img
          className="login-container_brand-container_brand"
          src="new_logo-white.png"
        />
      </Col>
      <Col
        className="login-container_login"
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{ padding: 0 }}
      >
        <FirstLoginComponent {...props} />
      </Col>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  loggingIn: state.login.loggingIn,
  email: state.login.email,
  hash: state.login.hash,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(Object.assign(ProfilesActions, LoginActions), dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResetPassword));
