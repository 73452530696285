import { useState } from 'react';
import FlagSystem from '../../../../components/FlagSystem';
import { Button } from '../../../../ds-components';
import { useEffect } from 'react';
import { ArrowLeft2 } from 'iconsax-react';
import OverviewFiltersContainer from '../../components/overview/OverviewFiltersContainer';
import OverviewDashContainer from '../../components/overview/OverviewDashContainer';
import { useHistory } from 'react-router-dom';
import DashboardLoader from '../../core/DashboardLoader';
import FiltersLoader from '../../core/Filters/FiltersLoader';
import { requester } from '../../../../requesters';
import { formatObjDate } from '../../utils/formatDate';

const OverviewDash = () => {
  const [overviewData, setOverviewData] = useState({});
  const [overviewAcordao, setOverviewAcordao] = useState();
  const [processListPage, setProcessListPage] = useState(1);
  const [overviewFilter, setOverviewFilter] = useState({});
  const [encodeFilter, setEncodeFilter] = useState();
  const [loadingOverviewData, setLoadingOverviewData] = useState(false);
  const [loadingOverviewAcordao, setLoadingOverviewAcordao] = useState(false);
  const [loadingOverviewFilter, setLoadingOverviewFilter] = useState(false);
  const companyId = localStorage.getItem('company');
  const [tempFilters, setTempFilters] = useState({
    trt: [],
    varas: [],
    assuntos: [],
    adv_empresa: [],
    adv_empregado: [],
    date_range: { 0: '01/01/2018', 1: new Date().toLocaleDateString() },
  });

  const history = useHistory();

  useEffect(() => {
    getFilter();
  }, []);

  useEffect(() => {
    getOverviewDashboard(encodeFilter);
  }, [encodeFilter]);

  useEffect(() => {
    getAcordao(processListPage);
  }, [processListPage]);

  const getFilter = () => {
    setLoadingOverviewFilter(true);
    requester.nestle
      .getFilters(companyId)
      .then((response) => {
        const date = new Date().toLocaleDateString('pt-BR');
        const dateRange = ['01/01/2018', date];
        setOverviewFilter([
          {
            label: 'TRT',
            options: response.trt,
            field_name: Object.keys(response)[4],
            type: 'multiple_select',
          },
          {
            label: 'Varas',
            options: response.varas,
            field_name: Object.keys(response)[0],
            type: 'multiple_select',
          },
          {
            label: 'Assuntos',
            options: response.assuntos,
            field_name: Object.keys(response)[3],
            type: 'multiple_select',
          },
          {
            label: 'Advogado da empresa',
            options: response.adv_empresa,
            field_name: Object.keys(response)[1],
            type: 'multiple_select',
          },
          {
            label: 'Advogado do reclamante',
            options: response.adv_empregado,
            field_name: Object.keys(response)[2],
            type: 'multiple_select',
          },
          {
            label: 'Período',
            options: dateRange,
            field_name: 'date_range',
            type: 'date',
          },
          {
            label: 'Resultado do pedido',
            options: [
              {
                label: 'Favorável à empresa',
                value: 'favoravel',
              },
              {
                label: 'Desfavoravel à empresa',
                value: 'desfavoravel',
              },
              {
                label: 'Parcialmente favorável à empresa',
                value: 'parcialmente',
              },
            ],
            field_name: Object.keys(response)[5],
            type: 'checkbox',
          },
        ]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingOverviewFilter(false));
    return;
  };

  const getOverviewDashboard = (filters) => {
    if (!filters) {
      setLoadingOverviewData(true);
      const formatObjDateRequest = formatObjDate(tempFilters.date_range);
      const newFiltersObj = {
        ...tempFilters,
        date_range: formatObjDateRequest,
      };
      var filt = JSON.stringify(newFiltersObj);
      requester.nestle
        .getDashboard(`query=&filters=${btoa(filt)}`, companyId)
        .then((response) => {
          setOverviewData({
            panorama: response.panorama,
            charts: [
              {
                title: 'Distribuição de processos no país',
                data: [['Estado', 'Processos'], ...response.brasil],
                highlights: response?.highlights?.hl_brasil,
                highlightsPosition: 'left',
                type: 'cloropleth',
              },
              {
                title: 'Quantidade de decisões publicadas ao longo do tempo',
                data: [
                  [
                    'Ano',
                    'Decisões favoráveis',
                    'Decisões desfavoráveis',
                    'Decisões parcialmente favoráveis',
                  ],
                  response.decisoes_publicadas[1],
                  response.decisoes_publicadas[2],
                  response.decisoes_publicadas[3],
                ],
                total: response.decisoes_publicadas[0],
                totalHighlight: response?.highlights?.hl_decisoes_publicadas[0],
                highlights: response?.highlights?.hl_decisoes_publicadas[1],
                highlightsPosition: 'right',
                type: 'line',
              },
              {
                title: 'Distribuição total de reclamatórias por assunto',
                data: response.treemaps[0][0],
                highlights: response?.highlights?.hl_treemaps[0],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: 'Distribuição de processos por advogados do reclamante',
                data: response.treemaps[1][0],
                highlights: response?.highlights?.hl_treemaps[1],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: '5 assuntos com maior percentual de decisões favoráveis',
                data: response.pedidos.favoravel,
                type: 'bars',
              },
              {
                title: '5 assuntos com maior percentual de decisões contrárias',
                data: response.pedidos.desfavoravel,
                type: 'bars',
              },
              {
                title: 'Ranking de varas mais julgadoras',
                data: response.bars,
                highlights: response?.highlights?.hl_bars,
                type: 'bars',
              },
            ],
          });
          setLoadingOverviewData(false);
          getAcordao(processListPage);
          return;
        })
        .catch((err) => console.error(err));
    } else {
      setLoadingOverviewData(true);
      setOverviewData();
      requester.nestle
        .getDashboard(`query=&filters=${filters}`, companyId)
        .then((response) => {
          setLoadingOverviewData(true);
          setOverviewData({
            panorama: response.panorama,
            charts: [
              {
                title: 'Distribuição de processos no país',
                data: [['Estado', 'Processos'], ...response.brasil],
                highlights: response?.highlights?.hl_brasil,
                highlightsPosition: 'left',
                type: 'cloropleth',
              },
              {
                title: 'Quantidade de decisões publicadas ao longo do tempo',
                data: [
                  [
                    'Ano',
                    'Decisões favoráveis',
                    'Decisões desfavoráveis',
                    'Decisões parcialmente favoráveis',
                  ],
                  response.decisoes_publicadas[1],
                  response.decisoes_publicadas[2],
                  response.decisoes_publicadas[3],
                ],
                total: response.decisoes_publicadas[0],
                totalHighlight: response?.highlights?.hl_decisoes_publicadas[0],
                highlights: response?.highlights?.hl_decisoes_publicadas[1],
                highlightsPosition: 'right',
                type: 'line',
              },
              {
                title: 'Distribuição total de reclamatórias por assunto',
                data: response.treemaps[0][0],
                highlights: response?.highlights?.hl_treemaps[0],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: 'Distribuição de processos por advogados do reclamante',
                data: response.treemaps[1][0],
                highlights: response?.highlights?.hl_treemaps[1],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: '5 assuntos com maior percentual de decisões favoráveis',
                data: response.pedidos.favoravel,
                type: 'bars',
              },
              {
                title: '5 assuntos com maior percentual de decisões contrárias',
                data: response.pedidos.desfavoravel,
                type: 'bars',
              },
              {
                title: 'Ranking de varas mais julgadoras',
                data: response.bars,
                highlights: response?.highlights?.hl_bars,
                type: 'bars',
              },
            ],
          });
          setLoadingOverviewData(false);

          getAcordao(processListPage);
          return;
        })
        .catch((err) => console.error(err));
    }
  };

  const getAcordao = (currentPage) => {
    setLoadingOverviewAcordao(true);
    const formatObjDateRequest = formatObjDate(tempFilters.date_range);
    const newFiltersObj = { ...tempFilters, date_range: formatObjDateRequest };
    var filt = JSON.stringify(newFiltersObj);
    if (!encodeFilter) {
      requester.nestle
        .getAcordaos(
          `query=&filters=${btoa(filt)}&page=${currentPage}`,
          companyId,
        )
        .then((response) => {
          setOverviewAcordao({
            totalPages: response.n_pages,
            table: response.table,
          });
          setLoadingOverviewAcordao(false);
        })
        .catch((err) => {
          console.log(err);
          // toast.error()
        });
      return;
    } else {
      setLoadingOverviewAcordao(true);
      requester.nestle
        .getAcordaos(
          'query=&filters=' + encodeFilter + '&page=' + currentPage,
          companyId,
        )
        .then((response) => {
          setOverviewAcordao({
            totalPages: response.n_pages,
            table: response.table,
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setLoadingOverviewAcordao(false));

      return;
    }
  };

  const handleFilter = (tempFilters) => {
    const formatObjDateRequest = formatObjDate(tempFilters.date_range);
    const newFiltersObj = { ...tempFilters, date_range: formatObjDateRequest };
    var filt = JSON.stringify(newFiltersObj);
    setEncodeFilter(btoa(filt));
    return;
  };

  return (
    <div className="business-dashboard">
      <FlagSystem current="dashboard-empresarial" />
      <div className="dashboard-container">
        <div className="dashboard-container_filters">
          <Button
            appearance="secondary"
            size="sm"
            icon={<ArrowLeft2 color="#667085" size={16} />}
            onClick={() => history.push('/dashboard-empresarial')}
          >
            Voltar
          </Button>
          {loadingOverviewFilter || Object.keys(overviewFilter).length === 0 ? (
            <FiltersLoader />
          ) : (
            <OverviewFiltersContainer
              filters={overviewFilter}
              handleFilter={handleFilter}
              tempFilters={tempFilters}
              setTempFilters={setTempFilters}
            />
          )}
        </div>
        <div className="dashboard-container_content">
          {loadingOverviewData || Object.keys(overviewData).length === 0 ? (
            <DashboardLoader />
          ) : (
            <OverviewDashContainer
              dashboardData={overviewData}
              dashboardAcordao={overviewAcordao}
              loadingData={loadingOverviewData}
              loadingAcordao={loadingOverviewAcordao}
              processListPage={processListPage}
              setProcessListPage={setProcessListPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewDash;
