import './CardOption.scss';
import { Tag } from '../../../../ds-components';

const CardOption = ({ icon, text, recomended, onClick }) => {
  return (
    <div
      className={`card-option mb-4 ${recomended ? 'recomended' : null}`}
      onClick={onClick}
    >
      {recomended ? (
        <Tag type="default" size="md" color="orange" className="tag">
          Recomendamos para você
        </Tag>
      ) : null}
      <div className="icon">{icon}</div>
      <div className="text">{text}</div>
    </div>
  );
};

export default CardOption;
