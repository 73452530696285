import { ArrowRight2 } from 'iconsax-react';
import { Form } from 'rsuite';
import { Button } from '../../../ds-components';
import ButtonRadio from './ButtonRadio/index';
const Step1 = ({
  formRef,
  formError,
  loading,
  handleSubmit,
  setFormError,
  workOptions,
  setWork,
  areaOptions,
  setArea,
  setSearch,
  work,
  area,
  search,
}) => {
  return (
    <Form
      fluid
      ref={formRef}
      onCheck={setFormError}
      formError={formError}
      disabled={loading}
      onSubmit={handleSubmit}
    >
      <Form.Group>
        <Form.ControlLabel>Você trabalha em?</Form.ControlLabel>
        <ButtonRadio
          type="radio"
          onChange={setWork}
          options={workOptions}
          defaultValue={work}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Para quais áreas você vai utilizar a ferramenta?
        </Form.ControlLabel>
        <ButtonRadio
          type="checkbox"
          onChange={setArea}
          options={areaOptions}
          defaultValue={area}
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Você realiza buscas jurisprudenciais?
        </Form.ControlLabel>
        <ButtonRadio
          type="radio"
          onChange={setSearch}
          defaultValue={search}
          options={[
            { value: true, name: 'realiza-busca', label: 'Sim' },
            { value: false, name: 'realiza-busca', label: 'Não' },
          ]}
        />
      </Form.Group>

      <Button
        type="submit"
        appearance="primary"
        loading={loading}
        icon={<ArrowRight2 size="18" color="#FFF" />}
        iconPosition={'left'}
      >
        Avançar
      </Button>
    </Form>
  );
};

export default Step1;
