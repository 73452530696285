import React from 'react';

const CardWithHeaderActions = ({ title, actions, children, id }) => {
  return (
    <div className="tv-card" id={id}>
      <div className="tv-card-header d-flex justify-content_between align-items_center">
        <h6 className="font-semibold">{title}</h6>
        <div>{actions}</div>
      </div>
      <div className="tv-card-content">{children}</div>
    </div>
  );
};

export default CardWithHeaderActions;
