import { ArrowLeft, DirectboxSend, Sms } from 'iconsax-react';
import { useCallback, useState } from 'react';
import { InputWithIcon } from '../../../../components/TuriviusComponents/Inputs';
import { toast, Bounce } from 'react-toastify';
import { useChangePassword } from '../../../../api/auth';
import { Whisper, Tooltip } from 'rsuite';
import isEmail from '../../../../utils/ValidateEmail';
import { Button } from '../../../../components/TuriviusComponents/Buttons';

export const ForgotPassword = ({ goLogin }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const { mutateAsync, isPending, isSuccess } = useChangePassword();

  const makePasswordRedefinition = useCallback(async () => {
    setError(false);
    try {
      if (!isEmail(email)) return setError(true);
      await mutateAsync({ email });
      toast(
        <span className="gap-4 flex items-center">
          {' '}
          <DirectboxSend color="#12B76A" size={24} /> E-mail enviado para{' '}
          {email}
        </span>,
        {
          style: {
            right: 85,
          },
          progressStyle: {
            background: '#053AC8',
          },
          className: 'w-96',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        },
      );
    } catch (error) {
      switch (error.status) {
        case 400:
          toast.error(
            ' Não foi possível alterar sua senha. Verifique as informações etente novamente.',
            {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Bounce,
            },
          );
        default:
          toast.error('Houve um problema de comunicação, tente novamente', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          });
      }
    }
  }, [email]);

  const onPressEnter = (e) => {
    if (e.key === 'Enter') {
      makePasswordRedefinition();
    }
  };

  return (
    <div className="login-component">
      {!isSuccess ? (
        <div className="login-component_header">
          <Whisper
            trigger={'hover'}
            placement="top"
            speaker={<Tooltip>Voltar</Tooltip>}
          >
            <ArrowLeft
              className="cursor-pointer"
              style={{ color: '#0D91FE', marginBottom: 26 }}
              onClick={goLogin}
            />
          </Whisper>
          <h2>Redefinição de senha</h2>
        </div>
      ) : (
        <div>
          <div className="gap-2 flex mb-11">
            <ArrowLeft
              className="cursor-pointer"
              color="#0D91FE"
              onClick={() => replace('/login')}
            />
            <span className="cursor-pointer text-[#0069F5]" onClick={goLogin}>
              Voltar ao login
            </span>
          </div>
          <div className="login-component_header font-rubik">
            <h2>Redefinição de senha</h2>
          </div>
        </div>
      )}

      {!isSuccess ? (
        <>
          <p className="max-w-[430px] mt-[18px]">
            Insira seu endereço de e-mail que você usou para se registrar. Vamos
            enviar um e-mail com um link para você redefinir sua senha
          </p>

          <div className="login-component_body">
            <label htmlFor="email">Endereço de e-mail</label>

            <InputWithIcon
              id={'email'}
              type={'email'}
              icon={Sms}
              placeholder={'Insira seu e-mail aqui'}
              onChange={(value) => setEmail(value)}
              value={email}
              errorInput={error}
              errorMessage={'Esse campo é obrigatório'}
              onKeyUp={onPressEnter}
            />

            <div className="mb-[13px]" />

            <Button
              appearance="default"
              loading={isPending}
              onClick={makePasswordRedefinition}
              disabled={isPending}
            >
              Enviar
            </Button>
          </div>
        </>
      ) : (
        <p className="max-w-[430px]" style={{ margin: '18px 0' }}>
          Enviamos um e-mail para você. Siga as instruções para redefinir sua
          senha.
        </p>
      )}
      <div className="flex justify-start" style={{ marginTop: 11 }}>
        <span className="text-sm leading-5" style={{ color: '#101828' }}>
          Se ainda precisar de ajuda,{' '}
          <a
            href="mailto:contato@turivius.com"
            className="cursor-pointer"
            style={{ color: '#0069f5' }}
          >
            fale com nosso suporte
          </a>
        </span>
      </div>
    </div>
  );
};
