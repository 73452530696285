import React from 'react';
import { Grid, Row, Col, Button } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Turivius } from '../../../.././../ui';
import './style.scss';

class TuriviusPanel extends React.Component {
  state = {
    expanded: false,
  };

  toggleExpand = () => {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  };

  componentDidMount() {
    const { defaultExpanded } = this.props;
    if (defaultExpanded) {
      this.setState({
        expanded: defaultExpanded,
      });
    }
  }

  render() {
    const { expanded } = this.state;
    const { header, content, inside, hideToggleButton, loading } = this.props;
    return (
      <div className="turivius-panel">
        {loading ? (
          <div className="turivius-panel-header load">
            <Turivius.SimplePreloader size="md" />
          </div>
        ) : (
          <div className="turivius-panel-header">
            <Grid fluid>
              <Row>
                <Col xs={20} sm={20} md={20} lg={20}>
                  {inside ? header : expanded ? null : header}
                </Col>
                {inside ? (
                  !hideToggleButton ? (
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      onClick={this.toggleExpand}
                    >
                      {content ? (
                        expanded ? (
                          <FontAwesomeIcon
                            className="turivius-panel-btn-toggle-expand"
                            size="lg"
                            icon={faChevronUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="turivius-panel-btn-toggle-expand"
                            size="lg"
                            icon={faChevronDown}
                          />
                        )
                      ) : null}
                    </Col>
                  ) : null
                ) : (
                  <div style={{ position: 'relative' }}>
                    {expanded ? (
                      <div className="turivius-group-actions">
                        <Button
                          className="turivius-btn-actions eye-slash"
                          onClick={this.toggleExpand}
                        >
                          <FontAwesomeIcon
                            className="turivius-panel-btn-toggle-expand"
                            size="lg"
                            icon={faEyeSlash}
                          />
                        </Button>
                      </div>
                    ) : (
                      <div className="turivius-group-actions">
                        <Button
                          className="turivius-btn-actions"
                          onClick={this.toggleExpand}
                        >
                          <FontAwesomeIcon
                            className="turivius-panel-btn-toggle-expand"
                            size="lg"
                            icon={faEye}
                          />
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Row>
            </Grid>
          </div>
        )}
        {expanded ? <div className="turivius-panel-body">{content}</div> : null}
      </div>
    );
  }
}

export default TuriviusPanel;
