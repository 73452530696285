import React from 'react';
import { Flag, Map1 } from 'iconsax-react';
import { Panel } from 'rsuite';
import { tourAvaliable } from './mock';

const PanelHeader = ({ item, icon }) => (
  <div className="panel-header main">
    {icon}
    <label>{item}</label>
  </div>
);

const MainActivities = ({ handleDrawer, openSuccessChecklist }) => {
  const tour = JSON.parse(localStorage.getItem('tour'));

  const getCheckTour = (tourItem) => {
    const tour = JSON.parse(localStorage.getItem('tour'));

    return tour.tour[tourItem];
  };

  return (
    <>
      <button
        className="panel-header button"
        onClick={() => {
          handleDrawer();
          openSuccessChecklist();
        }}
      >
        <Flag size={24} color="#FFFFFF" />
        <label>Faça o checklist de sucesso</label>
      </button>

      {/* <Panel
                collapsible
                eventKey={`knowledge-main-2`}
                className='knowledge-panel'
                header={
                    <PanelHeader
                        item="Veja os Tour disponíveis"
                        icon={<Map1 size={24} color="#FFFFFF" />}
                    />
                }
            >
                <div className='knowledge-panel_body main'>

                    <ul>
                        {tourAvaliable?.map(item => (
                            

                            

                            
                            <li key={item.id}>
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </Panel> */}
    </>
  );
};

export default MainActivities;
