import { Button } from '../../../../../../ds-components';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
} from '../../../../../../components/ui/popover';
import { ArrowCircleDown2, DocumentText1 } from 'iconsax-react';
import { ChooseLogo } from './ChooseLogo';
import { ChooseColor } from './ChooseColor';
import { ChooseTextColor } from './ChooseTextColor';
import pdfIcon from '../../../../../../assets/svgs/pdf-icon.svg';
import closeCircleIcon from '../../../../../../assets/svgs/close-circle.svg';
import { Tooltip, Whisper } from 'rsuite';
import {
  useChangeColor,
  useCreateBoard,
  useFetchReportInfo,
} from '../../../../../../api/reports';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useExportReports } from '../../../../../../store/ducks/exportReports';
import { toast } from 'react-toastify';

type ExportReportProps = {
  handleClickExport: any;
};

export const ExportReport = ({ handleClickExport }: ExportReportProps) => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const board_id = useMemo(() => Number(collectionId), [collectionId]);
  const {
    isError: isErrorFetchReportInfo,
    data,
    isSuccess: isSuccessFetchReportInfo,
  } = useFetchReportInfo({ board_id });
  const { mutate, isSuccess: isSuccessCreateBoard } = useCreateBoard();
  const { mutateAsync: mutateAsyncChangeColor } = useChangeColor();

  const { report, setReport, setError, clear } = useExportReports();

  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    setLoading(true);
    try {
      if (
        report?.main_color === '' ||
        report?.text_color === '' ||
        report?.logo_url === ''
      ) {
        toast.error(
          'Selecione todas as opções para finalizar seu relatório personalizado',
        );
        return setError({
          main_color: report?.main_color === '',
          text_color: report?.text_color === '',
          logo_url: report?.logo_url === '' && report?.file === undefined,
        });
      }
      if (report?.logo_url === undefined) return;
      if (report?.main_color && report.text_color)
        await mutateAsyncChangeColor({
          board: board_id,
          main_color: report.main_color,
          text_color: report.text_color,
        });
      if (report?.main_color && report?.text_color && report?.logo_url) {
        await handleClickExport(board_id, false);
        toast.success('Relatório baixado com sucesso');
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        'Houve um problema de conexão, tente novamente em instantes.',
      );
      setError({
        main_color: !report?.main_color,
        text_color: !report?.text_color,
        logo_url: !report?.logo_url || !report?.file,
      });
    } finally {
      setLoading(false);
    }
  }, [report]);

  const handleDownloadDefault = async () => {
    setLoading(true);
    try {
      await handleClickExport(board_id, true);
      toast.success('Relatório baixado com sucesso');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isErrorFetchReportInfo && !isSuccessCreateBoard)
      mutate({ board: board_id });
  }, [isErrorFetchReportInfo, isSuccessCreateBoard]);

  useEffect(() => {
    setReport({ logo_url: data?.logo_url || '' });
    if (isSuccessFetchReportInfo && !report) setReport(data);
  }, [isSuccessFetchReportInfo, data]);

  useEffect(() => {
    clear();
  }, []);

  return (
    <div>
      <Popover>
        <PopoverTrigger>
          <Whisper
            placement="top"
            speaker={<Tooltip>Exportar relatório</Tooltip>} 
          >
            <div className="flex items-center bg-[#053ac8] rounded-sm text-center space-x-2 text-white whitespace-nowrap p-[12px] gap-1">
              <DocumentText1 size={16} color="#ffffff" />
              Exportar relatório
            </div>
          </Whisper>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="w-[430px] mt-[18px] py-[35px] px-10 rounded-lg border border-solid border-[#E4E7EC]"
        >
          {isSuccessFetchReportInfo ? (
            <div>
              <span className="font-semibold text-[#344054]">
                Personalize o seu relatório
              </span>

              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Fechar</Tooltip>}
              >
                <PopoverClose asChild>
                  <img
                    src={closeCircleIcon}
                    className="absolute top-[34px] right-[18px] cursor-pointer"
                  />
                </PopoverClose>
              </Whisper>
              <hr className="bg-[#E4E7EC] opacity-50 my-5" />
              <div className="flex flex-col gap-[22px]">
                <ChooseLogo logo_url={report?.logo_url} />
                <ChooseColor main_color={report?.main_color} />
                <ChooseTextColor text_color={report?.text_color} />
              </div>
              <hr className="bg-[#E4E7EC] opacity-50 my-5" />

              <div className="gap-3 flex items-center">
                <span className="font-semibold text-[#344054]">
                  Download do relatório em pdf
                </span>
                <img src={pdfIcon} />
              </div>

              <div className="gap-3 flex items-center mt-5">
                <Button
                  className="h-7"
                  appearance="primary"
                  size="sm"
                  icon={<ArrowCircleDown2 size={16} color="#fff" />}
                  onClick={handleDownload}
                  disabled={loading}
                  loading={loading}
                >
                  <span className="text-xs">Download</span>
                </Button>

                <Whisper
                  trigger="hover"
                  placement="top"
                  speaker={
                    <Tooltip>
                      Baixe com as configurações padrões da Turivius
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      className="h-7"
                      appearance="minimal"
                      size="sm"
                      loading={loading}
                      disabled={loading}
                      onClick={handleDownloadDefault}
                    >
                      <span className="text-xs text-[#0D91FE] font-normal">
                        Baixar sem personalização
                      </span>
                    </Button>
                  </div>
                </Whisper>
              </div>
            </div>
          ) : (
            <span>Carregando ...</span>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};
