import React from 'react';
import { Col, Grid, Modal, Row } from 'rsuite';
import TuriviusPreloader from '../../../../../app/TuriviusPreloader';
import { Turivius } from '../../../../../ui';

import BarChart from '../JurimetricsChart/BarCharts';
import LineChart from '../JurimetricsChart/LineChart';
import NoJurimetrics from '../JurimetricsChart/NoJurimetrics';
import html2canvas from 'html2canvas';
import './SearchJurimetrics.scss';
import { posthog } from 'posthog-js';
import { Alert, Button } from '../../../../../ds-components';
import { ArrowCircleDown2 } from 'iconsax-react';
import { postHubSpotEvent } from '../../../../../services/hubspot';

class SearchJurimetrics extends React.Component {
  state = {
    openModal: false,
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  handleOpen = () => {
    this.setState({ openModal: true });
  };

  printDocument() {
    const input = document.getElementById('grafico');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      var link = document.createElement('a');
      link.download = 'jurimetria-tributária.png';
      link.href = imgData;
      link.click();
    });

    posthog.capture('download_jurimetria_tributaria', {
      action: 'download_jurimetria_tributaria',
      search: window.location.search,
    });

    postHubSpotEvent('app_downloadjurimetriatributaria', {
      hs_page_url: window.location.href,
    });
  }

  render() {
    const { openModal } = this.state;
    const {
      searchPlots,
      requesting,
      entities,
      noJurimetricEntities,
      countResults,
    } = this.props;

    return countResults && countResults > 0 ? (
      <div className="jurimetrics-card bottom">
        <div className="jurimetrics-search-card-header jurimetrics-card-header">
          <h6 className="font-bold">Jurimetria Tributária</h6>

          <div className="jurimetrics-search-card-header_actions">
            <span onClick={this.handleOpen} className="font-medium">
              Como interpretar os gráficos?
            </span>

            <Button
              appearance="secondary"
              size="sm"
              disabled={!(searchPlots?.length > 6)}
              onClick={this.printDocument}
              icon={<ArrowCircleDown2 color="#667085" size={16} />}
              className="ml-3"
              data-testid="download-jurimetrics"
            >
              Download
            </Button>
          </div>
        </div>

        <div className="jurimetrics-card-content">
          <div>
            <Modal open={openModal} onClose={this.handleClose}>
              <Modal.Header>
                <Turivius.TuriviusPopover
                  trigger="hover"
                  message={
                    <p>
                      O Vision é a Inteligência Artificial Tributária da
                      Turivius.
                      <br /> Clique aqui e saiba mais
                    </p>
                  }
                >
                  <div
                    className="premium-filter-identifier-animated"
                    onClick={() =>
                      window.open(
                        'https://turivius.com/portal/conheca-o-vision-inteligencia-artificial-tributaria/',
                        '__blank',
                      )
                    }
                  >
                    <div className="premium-filter-identifier">
                      <img src="/premium.png" alt="premium filter" />
                      <p>POWERED BY VISION</p>
                    </div>
                  </div>
                </Turivius.TuriviusPopover>
              </Modal.Header>
              <Modal.Body>
                <h5>Como interpretar os gráficos? </h5>
                <br />
                <p>
                  Os gráficos mostram com precisão estatística como as cortes
                  decidiram sobre o tema da sua pesquisa ao longo dos anos.
                  <br />
                  Use os gráficos para:
                </p>
                <ul style={{ listStyleType: 'disc', paddingLeft: 40 }}>
                  <li style={{ listStyleType: 'disc' }}>
                    avaliar estatisticamente as chances de sucesso de um
                    processo,
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    descobrir quais cortes são mais receptivas àquele tema, e
                  </li>
                  <li style={{ listStyleType: 'disc' }}>
                    sofisticar a forma que você apresenta seus produtos e
                    soluções para seus clientes.
                  </li>
                </ul>
                <p>
                  A jurimetria te ajuda a se diferenciar da sua concorrência e
                  refina sua estratégia jurídica.
                  <br />
                  Os gráficos são simples. Veja o vídeo abaixo e entenda como
                  interpretá-los.
                </p>
                <br />
                <div
                  style={{
                    height: 315,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <iframe
                    title="search jurimetrics explain"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/lLUZ-GC1OWA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Turivius.TuriviusButton onClick={this.handleClose}>
                  Fechar
                </Turivius.TuriviusButton>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            {requesting ? (
              <div style={{ height: 150 }}>
                <TuriviusPreloader />
              </div>
            ) : (
              <Grid fluid id="grafico">
                {searchPlots?.length > 6 ? (
                  <Row className="mt-2">
                    0{' '}
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {searchPlots
                        .slice(0, 5)
                        ?.filter((sp) => sp.type === 'line')
                        ?.map((sp, i) => (
                          <LineChart
                            key={`search-${i}`}
                            id={`search-${i}`}
                            data={sp.data.data}
                            getColors={sp.data.config.colors}
                            axisLeftlegend={sp.data.config.labels.y}
                          />
                        ))}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      {searchPlots
                        .slice(0, 5)
                        ?.filter((sp) => sp.type === 'bar')
                        ?.map((sp, i) => (
                          <Col
                            key={`search-${i}`}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            className="my-2"
                          >
                            <BarChart
                              id={`search-${i}`}
                              title={sp.data.config.labels.top}
                              legend={sp.data.config.labels.left}
                              data={sp.data.data}
                              keys={sp.data.config.keys}
                              indexBy={sp.data.config.indexBy}
                              maxValue={sp.data.config.maxValue}
                              getColors={sp.data.config.colors}
                            />
                          </Col>
                        ))}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} className="p-4">
                      {searchPlots
                        ?.filter((sp) => sp.type === 'params')
                        ?.map((sp, i) => (
                          <Alert type="warning" headerTitle="Atenção" key={i}>
                            Os gráficos correspondem às datas aplicadas nos
                            filtros e ao(s) órgão(s)&nbsp;
                            {sp?.data?.used_entities
                              ?.map((ue) => entities[ue]?.initials)
                              .join(', ')}
                          </Alert>
                        ))}
                    </Col>
                  </Row>
                ) : (
                  <NoJurimetrics jurimetricEntities={noJurimetricEntities} />
                )}
              </Grid>
            )}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default SearchJurimetrics;
