import { useEffect, useState } from 'react';
import { Col, Grid, Input, Radio, RadioGroup, Row, Panel } from 'rsuite';
import { Turivius } from '../../../../../ui';
import NewUserButton from '../NewUserButton';
import UserCard from '../UserCard';
import './style.scss';

export const UserList = ({ getUsers, ...props }) => {
  const [userStatusFilter, setUserStatusFilter] = useState('');
  const [userNameEmailFilter, setUserNameEmailFilter] = useState('');

  useEffect(() => {
    getUsers();
  }, ['']);

  const { users, loadUsers } = props;

  let filteredUsers =
    userStatusFilter !== ''
      ? users?.filter((u) => u.active === userStatusFilter)
      : users;
  filteredUsers = userNameEmailFilter
    ? filteredUsers?.filter(
        (u) =>
          u.name.includes(userNameEmailFilter) ||
          u.email.includes(userNameEmailFilter),
      )
    : filteredUsers;

  return users !== null ? (
    users?.length > 0 && !loadUsers ? (
      <Grid fluid>
        <Row>
          <Col xs={24} sm={24} md={16}>
            <Input
              value={userNameEmailFilter}
              onChange={setUserNameEmailFilter}
              placeholder="Filtrar por nome ou email de usuário"
            />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <RadioGroup
              style={{ marginBottom: 10, backgroundColor: '#ffffff' }}
              name="radioList"
              inline
              appearance="picker"
              value={userStatusFilter}
              onChange={setUserStatusFilter}
            >
              <span
                style={{
                  padding: '8px 2px 8px 10px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              >
                Status:{' '}
              </span>
              <Radio value={''}>Todos</Radio>
              <Radio value={true}>Ativos</Radio>
              <Radio value={false}>Inativos</Radio>
            </RadioGroup>
          </Col>
        </Row>
        {filteredUsers?.map((user, index) => {
          return (
            <Row key={index}>
              <UserCard data={user} />
            </Row>
          );
        })}
      </Grid>
    ) : (
      <Turivius.TuriviusPreloader />
    )
  ) : (
    <Panel bordered className="container-no-data">
      <h4>Nenhum usuário criado</h4>
      <span>Entre em contato com o suporte para criar novos usuários</span>
      <NewUserButton getUsers={getUsers} />
    </Panel>
  );
};

export default UserList;
