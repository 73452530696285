import React, { useState } from 'react';

const Autocomplete = ({ suggestions, onChangeText }) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (e) => {
    const input = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(input.toLowerCase()),
    );

    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(input);
    onChangeText(input);
  };

  const handleSuggestionClick = (suggestion) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion);
    onChangeText(suggestion);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (filteredSuggestions.length > 0) {
        setActiveSuggestion(0);
        setShowSuggestions(false);
        setUserInput(filteredSuggestions[activeSuggestion]);
        onChangeText(filteredSuggestions[activeSuggestion]);
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
      console.log('activeSuggestion - 1', activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
      console.log('activeSuggestion + 1', activeSuggestion + 1);
    }
  };

  const renderSuggestions = () => {
    if (showSuggestions && userInput && filteredSuggestions.length > 0) {
      return (
        <ul className="business-home_suggestion">
          {filteredSuggestions?.map((suggestion, index) => {
            const className =
              index === activeSuggestion
                ? 'business-home_suggestion-active'
                : '';

            return (
              <li
                className={`${className} business-home_suggestion-item`}
                key={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <>
      <input
        className="business-home_input"
        type="text"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={userInput}
      />
      {renderSuggestions()}
    </>
  );
};

export default Autocomplete;
