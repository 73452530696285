import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../requesters';

const InteiroTeorFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, setInteiroTeorFilter } = props;

    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
    }

    setInteiroTeorFilter(JSON.parse(newOption));
  };

  return (
    <div className="inteiro-teor-filter">
      <span className="title-option">Buscar em</span>
      <RadioGroup
        name="radioList"
        inline
        appearance="picker"
        onChange={handleChange}
        value={JSON.stringify(props.inteiroTeorFilter)}
      >
        <Radio value='["ementa","full_text"]'>Todos</Radio>
        <Radio value='["ementa"]'>Ementa</Radio>
        <Radio value='["full_text"]'>Inteiro Teor</Radio>
      </RadioGroup>
    </div>
  );
};

export default InteiroTeorFilter;
