import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

interface MarkdownFormatterProps {
  markdownText: string;
}

const MarkdownFormatter = ({ markdownText }: MarkdownFormatterProps) => {
  return (
    <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
      {markdownText}
    </ReactMarkdown>
  );
};

export default MarkdownFormatter;
