import React from 'react';
import { Input, Modal, Message, toaster } from 'rsuite';
import { withRouter } from 'react-router-dom';
import { requester } from '../../../../../requesters';
import './EditCollectionButton.scss';
import { Add, ArrowLeft2, CloseCircle, Edit2 } from 'iconsax-react';
import { Button, SpinningLoader } from '../../../../../ds-components';

const MAX_DESCRIPTION_LENGTH = 350;

class EditCollectionButton extends React.Component {
  state = {
    collectionTitle: '',
    collectionDescription: '',
    open: false,
    requesting: false,
  };

  componentDidMount() {
    this.setState({
      collectionTitle: this.props.collectionTitle,
      collectionDescription: this.props.collectionDescription,
      collectionId: this.props.collectionId,
    });
  }

  editCollectionTitleAndDescription = () => {
    const { onSearch } = this.props;
    const toast = toaster.push(
      <Message duration={300000} type="info">
        Salvando alterações...
      </Message>,
    );

    this.setState({ requesting: true });
    requester.collections
      .updateCollection(
        localStorage.getItem('user'),
        this.state.collectionId,
        this.state.collectionTitle,
        this.state.collectionDescription,
        this.props.context,
      )
      .then((response) => {
        onSearch();
        toaster.push(
          <Message
            header="Dados atualizados"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Coleção atualizada com sucesso
          </Message>,
        );
        toaster.remove(toast);
        this.handleModal();
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente.
          </Message>,
        );
      })
      .finally(() => {
        this.setState({ requesting: false });
      });
  };

  onDescriptionChange = (s, e) => {
    if (s.length <= MAX_DESCRIPTION_LENGTH) {
      this.setState({ collectionDescription: s });
    } else {
      this.setState({ collectionDescription: s.substring(0, 350) });
    }
  };

  onTitleChange = (s, e) => {
    this.setState({ collectionTitle: s });
  };

  handleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { collectionTitle, collectionDescription, open, requesting } =
      this.state;
    const { small } = this.props;
    return (
      <>
        <Button
          fullWidth
          style={{
            borderColor: 'red !important',
            justifyContent: 'flex-start', 
            display: 'flex', 
          }}
          size="sm"
          appearance="minimal"
          icon={<Edit2 size={24} color=' #475467' />}
          onClick={this.handleModal}
        >
          {small ? '' : 'Editar título e descrição'}
        </Button>

        <Modal
          className="tv-modal-default"
          open={open}
          onClose={this.handleModal}
        >
          <Modal.Header closeButton={false} className="tv-modal-default_header">
            <div style={{ textAlign: 'end' }}>
              <CloseCircle
                size="24"
                color="#98A2B3"
                onClick={this.handleModal}
                style={{ cursor: 'pointer' }}
              />
            </div>

            <h6 className="tv-modal-default_header_title">Editar Coleção</h6>
          </Modal.Header>

          <Modal.Body className="tv-modal-default_body">
            <label className="tv-modal-default_body_label">Título *</label>
            <Input
              className="tv-modal-default_body_input"
              placeholder="Insira um titulo para coleção"
              value={collectionTitle}
              onChange={this.onTitleChange}
            />

            <label className="tv-modal-default_body_label">Descrição</label>
            <Input
              className="tv-modal-default_body_input"
              placeholder="Insira uma descrição para coleção"
              value={collectionDescription}
              onChange={this.onDescriptionChange}
            />
          </Modal.Body>
          <Modal.Footer className="tv-modal-default_footer">
            <Button
              size="sm"
              fullWidth
              icon={<ArrowLeft2 />}
              appearance="secondary"
              onClick={this.handleModal}
              disabled={requesting}
            >
              Cancelar
            </Button>
            <Button
              size="sm"
              fullWidth
              icon={<Add />}
              onClick={this.editCollectionTitleAndDescription}
            >
              {requesting ? (
                <SpinningLoader color="white" size="sm" />
              ) : (
                'Salvar'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(EditCollectionButton);
