import { Redirect } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TURIVIUS_VERSION } from '../../../../app/App';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import { toaster } from 'rsuite';
import { posthog, requester } from '../../../../requesters';

const Logout = (props) => {
  const { emptyCacheStorage } = useClearCache();

  if (String(localStorage.TURIVIUS_VERSION) !== String(TURIVIUS_VERSION)) {
    emptyCacheStorage();
  }

  toaster.clear();
  props.resetProfiles();
  posthog.reset();
  requester.block
    .remove()
    .then((response) => {
      // console.log(response);
    })
    .catch((err) => console.log(err));
  localStorage.clear();

  setTimeout(() => {
    localStorage.setItem('TURIVIUS_VERSION', String(TURIVIUS_VERSION));
  }, 2000);

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location },
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProfilesActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
