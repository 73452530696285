import { posthog } from '../requesters/';

function posthogData(profile) {
  posthog.identify(profile.id);
  posthog.people.set({
    email: profile.email,
    company: profile.company.id,
    company_name: profile.company.name,
    name: profile.name,
    is_trial: !!profile.company.asaas_url?.match('^https://www.asaas.com/.*'),
  });
}

export { posthogData };
