import React from 'react';
import { Grid, Row, Col, Checkbox } from 'rsuite';
import { Turivius } from '../../../../../../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { posthog, requester } from '../../../../../../../requesters';
import './TuriviusCheckboxGroupFilter.scss';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

class TuriviusCheckboxGroupFilter extends React.Component {
  state = {
    requesting: false,
    items: null,
    filters: null,
    selected: null,
    open: false,
  };

  handleOpenClose = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  getOptions = () => {
    if (this.mounted) {
      this.setState({ requesting: true });
      requester.cards
        .getAllEntities()
        .then((response) => {
          if (this.mounted) {
            this.setState({
              items: this.groupArray(response, 'class'),
              requesting: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  groupArray = (arr, groupBy) => {
    /*SEGMENTATION */
    const allowedEntities = this.props.permissions;
    let groupedArray = [];
    let categories = {};

    arr.forEach((elem) => {
      categories[elem[groupBy]] = '';
    });
    Object.keys(categories).forEach((category, i) => {
      groupedArray.push([]);
      arr.forEach((elem) => {
        /*SEGMENTATION */
        elem.enabled = allowedEntities[elem.id] ?? false;
        // elem.enabled = true
        elem[groupBy] === category ? groupedArray[i].push(elem) : null;
      });
    });
    return groupedArray;
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.getOptions('');
    if (this.mounted) {
      this.setState({
        filters: this.props.filters['entity'],
        selected: this.props.filtersProps.filters['entities'].filter(
          (e) => this.props.permissions[e],
        ),
        placeholder: this.props.placeholder,

        /*SEGMENTATION */
        // selected: this.props.filtersProps.filter['entities']?.filter(e => this.props.permissions[e]),
      });
    }
  }
  componentDidUpdate() {
    const propsFilters = this.props.filters && this.props.filters['entity'];
    const stateFilters = this.state && this.state.filters;
    if (!stateFilters && propsFilters && this.mounted) {
      this.setState({
        filters: [...propsFilters],

        /*NO SEGMENTATION */
        // selected: [...propsFilters],

        /*SEGMENTATION */
        selected: [...propsFilters].filter((e) => this.props.permissions[e]),
      });
    } else if (
      stateFilters &&
      propsFilters &&
      stateFilters.length !== propsFilters.length &&
      this.mounted
    ) {
      this.setState({
        filters: null,
        selected: null,
      });
      setTimeout(() => {
        this.setState({
          filters: [...propsFilters],

          /*NO SEGMENTATION */
          // selected: [...propsFilters],

          /*SEGMENTATION */
          selected: [...propsFilters].filter((e) => this.props.permissions[e]),
        });
      }, 100);
    } else if (
      Array.isArray(propsFilters) &&
      Array.isArray(stateFilters) &&
      this.mounted
    ) {
      let doUpdate = false;
      propsFilters.forEach((propFilter) => {
        const found = stateFilters.filter((stateFilter) => {
          return (
            (typeof stateFilter === 'object'
              ? parseInt(stateFilter.id)
              : parseInt(stateFilter)) ===
            (typeof propFilter === 'object'
              ? parseInt(propFilter.id)
              : parseInt(propFilter))
          );
        }).length;
        if (found === 0) {
          doUpdate = true;
        }
      });
      if (doUpdate && this.mounted) {
        this.setState({
          filters: null,
          selected: null,
        });
        setTimeout(() => {
          this.setState({
            /*NO SEGMENTATION */
            filters: [...propsFilters],
            // selected: [...propsFilters],

            /*SEGMENTATION */
            selected: [...propsFilters].filter(
              (e) => this.props.permissions[e],
            ),
          });
        }, 100);
      }
    }
  }

  selectionChange = (value, checked) => {
    let { selected } = this.state;
    let selection = value;
    let findSelection = selected.indexOf(parseInt(selection));

    if (findSelection === -1) {
      selected = [...selected, parseInt(selection)];
    } else {
      selected.splice(findSelection, 1);
    }
    this.setState({
      selected: [...selected].filter((e) => this.props.permissions[e]),
    });
    this.props.filtersProps.setTmpFilters('entities', [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: 'entities',
      });
      postHubSpotEvent('app_changefilters', {
        app_changefilters: this.props.context,
      });
    }
  };

  changeSelectionGroup = (group, action) => {
    let { selected } = this.state;
    for (let option of group) {
      let findSelection = selected.indexOf(option.id);
      if (findSelection === -1 && action === 'add') {
        selected.push(option.id);
      } else if (findSelection !== -1 && action === 'remove') {
        selected.splice(findSelection, 1);
      }
    }
    this.setState({
      selected: [...selected].filter((e) => this.props.permissions[e]),
    });
    this.props.filtersProps.setTmpFilters('entities', [...selected]);
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: 'entities',
      });
      postHubSpotEvent('app_changefilters', {
        app_changefilters: this.props.context,
      });
    }
  };

  globalSelection = (action) => {
    let { selected, items } = this.state;
    if (action === 'remove') {
      selected = [];
    } else {
      selected = items
        ?.reduce((acc, item) => [...acc, ...item], [])
        ?.map((item) => item.id);
    }
    this.setState({
      selected: [...selected].filter((e) => this.props.permissions[e]),
    });

    this.props.filtersProps.setTmpFilters(
      'entities',
      [...selected].filter((e) => this.props.permissions[e]),
    );
    if (this.props.context) {
      posthog.capture(this.props.context, {
        action: 'change_filter',
        id: 'entities',
      });
      postHubSpotEvent('app_changefilters', {
        app_changefilters: this.props.context,
      });
    }
  };

  getSelectionString = () => {
    const { selected, items } = this.state;
    const totalLength = items?.reduce((acc, item) => acc + item.length, 0);
    if (selected?.length === 0) {
      return 'Selecionar órgãos';
    } else if (selected?.length === totalLength) {
      return (
        <span style={{ width: '100%', textAlign: 'center' }}>
          Todos órgãos selecionados ({selected?.length})
        </span>
      );
    } else if (selected?.length <= 3) {
      return (
        <span style={{ width: '100%' }}>
          {items
            ?.reduce((acc, item) => [...acc, ...item], [])
            .filter((item) => selected?.indexOf(item.id) !== -1)
            .reduce((acc, item) => [...acc, item.initials], [])
            .join(', ')}
        </span>
      );
    } else {
      let groupedSelections = [];
      items?.forEach((item) =>
        groupedSelections.push({
          group: item[0].class,
          total: item.length,
          selected: item
            ?.filter((item) => selected?.indexOf(item.id) !== -1)
            .reduce((acc, item) => [...acc, item.initials], []).length,
        }),
      );
      return (
        <span style={{ width: '100%' }}>
          {groupedSelections
            .filter((gs) => gs.selected !== 0)
            ?.map(
              (gs) =>
                `${gs.group} (${
                  gs.selected === gs.total ? 'Todos' : gs.selected
                })`,
            )
            .join(', ')}
        </span>
      );
    }
  };

  verifyGroupSelection = (group) => {
    let { selected } = this.state;
    selected = selected.filter((e) => this.props.permissions[e]);

    let allSelected = true;
    let allDisabled = true;
    for (let option of group.filter((e) => this.props.permissions[e.id])) {
      let verifySelection = selected.indexOf(option.id);
      if (option.enabled) {
        allDisabled = false;
      }
      if (verifySelection === -1) {
        allSelected = false;
        break;
      }
    }
    return !allDisabled ? (
      allSelected ? (
        <p
          className="checkbox-group-category-action"
          onClick={() => this.changeSelectionGroup(group, 'remove')}
        >
          (remover todos)
        </p>
      ) : (
        <p
          className="checkbox-group-category-action"
          onClick={() => this.changeSelectionGroup(group, 'add')}
        >
          (selecionar todos)
        </p>
      )
    ) : null;
  };

  render() {
    const { items, requesting, selected } = this.state;
    const { entities_counter } = this.props.filtersProps;
    const { labelKey, label, helperText, error, onClick, rows } = this.props;

    return (
      <div
        className="turivius-checkbox-group-filter MuiFormControl-root MuiTextField-root"
        onClick={onClick ? onClick : () => {}}
      >
        {label ? (
          <label className="rs-entities-input-label">{label}</label>
        ) : null}
        <div className={`checkbox-input-field ${error ? 'error' : ''}`}>
          {requesting ? (
            <Turivius.SimplePreloader color="inherit" size="md" />
          ) : items && selected ? (
            <Grid fluid>
              <Row>
                <Col xs={24} sm={24}>
                  <label className="checkbox-group-category-label">
                    Global{' '}
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection('add')}
                    >
                      selecionar todos
                    </p>{' '}
                    <p
                      className="checkbox-group-category-action"
                      onClick={() => this.globalSelection('remove')}
                    >
                      remover todos
                    </p>
                  </label>
                </Col>
                {items?.map((group, i) => {
                  return (
                    <Row className="checkbox-group-category-container" key={i}>
                      <Col xs={24} sm={24}>
                        <label className="checkbox-group-category-label">
                          {' '}
                          <p>{group[0].class}</p>{' '}
                          {this.verifyGroupSelection(group)}
                        </label>
                      </Col>
                      {group?.map((elem, j) => (
                        <Col
                          xs={rows ? 24 / rows : 12}
                          sm={rows ? 24 / rows : 12}
                          md={10}
                          key={j}
                        >
                          <Checkbox
                            id={`Checkbox_${elem.id}`}
                            key={`Checkbox_${elem.id}`}
                            value={`${elem.id}`}
                            disabled={!elem.enabled}
                            onChange={this.selectionChange}
                            checked={
                              selected
                                ? selected.filter((option) =>
                                    typeof option === 'object'
                                      ? option.id === elem.id
                                      : option === elem.id,
                                  ).length > 0
                                : false
                            }
                          >
                            <div className="checkbox-group-label-container">
                              <div className="first-line">
                                {elem.is_new ? (
                                  <Turivius.TuriviusTooltip
                                    trigger={'hover'}
                                    message={'Base de dados nova!'}
                                    placement={'right'}
                                  >
                                    <div>
                                      <span className="new-badge">new</span>
                                    </div>
                                  </Turivius.TuriviusTooltip>
                                ) : elem.is_partial ? (
                                  <Turivius.TuriviusTooltip
                                    trigger={'hover'}
                                    message={'Base de dados parcial'}
                                    placement={'right'}
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faStarHalf}
                                        className="tiny partial"
                                      />
                                    </div>
                                  </Turivius.TuriviusTooltip>
                                ) : null}
                                <div>
                                  {elem[labelKey]
                                    .replace('(novo)', '')
                                    .replace('(parcial)', '')}
                                </div>
                              </div>
                              <p className="counter">
                                {entities_counter && entities_counter[elem.id]
                                  ? `${entities_counter[elem.id]} resultados`
                                  : ''}
                              </p>
                            </div>
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  );
                })}
              </Row>
            </Grid>
          ) : null}
        </div>
        {helperText ? (
          <p
            className={`MuiFormHelperText-root MuiFormHelperText-contained ${
              error ? 'error' : ''
            }`}
          >
            {helperText}
          </p>
        ) : null}
      </div>
    );
  }
}

export default TuriviusCheckboxGroupFilter;
