import React from 'react';
import { Panel } from 'rsuite';
import './style.scss';

const SignatureCard = ({ active, onSelect, ...props }) => {
  return (
    <Panel bordered className="signature-panel">
      <p>
        Para tirar dúvidas, ou resolver qualquer problema relacionado à
        assinatura ou pagamentos, envie um e-mail para{' '}
        <a href="mailto:suporte@turivius.com">suporte@turivius.com</a>.
      </p>
    </Panel>
  );
};

export default SignatureCard;

/**
 *
 * style={{ fontSize: 16, lineHeight: "24px", marginTop: 20 }}
 */
