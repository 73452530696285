import React from 'react';
import PropTypes from 'prop-types';
import './TuriviusBarChart.scss';
import { ResponsiveBar } from '@nivo/bar';

const TuriviusBarChart = ({
  id,
  className,
  style,
  keys,
  indexBy,
  data,
  margin,
  padding,
  maxValue,
  minValue,
  groupMode,
  layout,
  valueScale,
  indexScale,
  getColors,
  borderColor,
  axisTop,
  axisRight,
  axisBottom,
  axisLeft,
  enableGridX,
  enableGridY,
  gridYValues,
  enableLabel,
  labelSkipWidth,
  labelSkipHeight,
  labelTextColor,
  layers,
  onClick,
  tooltip,
  isInteractive,
  legends,
  animate,
  ...params
}) => (
  <div
    id={id}
    data-testid={`bar-chart-${id}`}
    className={`turivius-bar-chart ${className}`}
    style={style}
  >
    <ResponsiveBar
      keys={keys}
      indexBy={indexBy}
      data={data}
      margin={margin}
      padding={padding}
      maxValue={maxValue}
      minValue={minValue}
      groupMode={groupMode}
      layout={layout}
      valueScale={valueScale}
      indexScale={indexScale}
      colors={getColors}
      borderColor={borderColor}
      axisTop={axisTop}
      axisRight={axisRight}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      enableGridX={enableGridX}
      enableGridY={enableGridY}
      gridYValues={gridYValues}
      enableLabel={enableLabel}
      labelSkipWidth={labelSkipWidth}
      labelSkipHeight={labelSkipHeight}
      labelTextColor={labelTextColor}
      layers={layers}
      onClick={onClick}
      tooltip={tooltip}
      isInteractive={isInteractive}
      legends={legends}
      animate={animate}
      {...params}
    />
  </div>
);

TuriviusBarChart.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  keys: PropTypes.object,
  indexBy: PropTypes.string,
  data: PropTypes.array,
  margin: PropTypes.object,
  padding: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  groupMode: PropTypes.string,
  layout: PropTypes.string,
  valueScale: PropTypes.object,
  indexScale: PropTypes.object,
  getColors: PropTypes.func,
  borderColor: PropTypes.object,
  axisTop: PropTypes.object,
  axisRight: PropTypes.object,
  axisBottom: PropTypes.object,
  axisLeft: PropTypes.object,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  gridYValues: PropTypes.object,
  enableLabel: PropTypes.bool,
  labelTextColor: PropTypes.string,
  labelSkipWidth: PropTypes.number,
  labelSkipHeight: PropTypes.number,
  layers: PropTypes.object,
  onClick: PropTypes.func,
  tooltip: PropTypes.func,
  isInteractive: PropTypes.bool,
  legends: PropTypes.array,
  animate: PropTypes.bool,
};

TuriviusBarChart.defaultProps = {
  id: undefined,
  className: '',
  style: {
    width: 400,
    height: 300,
  },
  keys: [],
  indexBy: '',
  data: [],
  margin: {
    top: 20,
    right: 50,
    bottom: 50,
    left: 50,
  },
  padding: 0.3,
  maxValue: undefined,
  minValue: undefined,
  groupMode: 'grouped',
  layout: 'vertical',
  valueScale: {
    type: 'linear',
  },
  indexScale: {
    type: 'band',
    round: true,
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 30,
    legend: 'xTitle',
    legendPosition: 'middle',
    legendOffset: 40,
  },
  axisLeft: {
    tickValues: [],
    legend: 'leftLegend',
  },
  borderColor: {
    from: 'color',
    modifiers: [['darker', 1.6]],
  },
  getColors: undefined,
  onClick: () => {},
  tooltip: undefined,
  enableGridX: true,
  enableGridY: true,
  gridYValues: undefined,
  enableLabel: true,
  labelTextColor: {
    from: 'color',
    modifiers: [['darker', 3]],
  },
  labelSkipWidth: 4,
  labelSkipHeight: 6,
  layers: ['grid', 'axes', 'bars', 'markers', 'annotations'],
  isInteractive: true,
  legends: [
    {
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
  animate: true,
};

export default TuriviusBarChart;
