import { Grid, Col, Row } from 'rsuite';
import UserStatusToggleButton from '../UserStatusToggleButton';
import { Turivius } from '../../../../../ui';
import './style.scss';
import TuriviusPanel from '../TuriviusPanel';

const UserCard = ({ data }) => {
  return (
    <TuriviusPanel
      key={data.id}
      bordered
      className="user-card"
      inside={true}
      header={
        <div className="header-content">
          <Grid fluid>
            <Row>
              <Col xs={24} md={5} sm={24}>
                <div className="header-item">
                  <label>Nome</label>
                  <span className="header-text">{data.name}</span>
                </div>
              </Col>
              <Col xs={24} md={9} sm={24}>
                <div className="header-item">
                  <label>E-Mail</label>
                  <span className="header-text-contracted">
                    <Turivius.TuriviusTooltip
                      trigger="hover"
                      message={`Clique para enviar e-mail para ${data.email}`}
                      placement="top"
                    >
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </Turivius.TuriviusTooltip>
                  </span>
                </div>
              </Col>
              <Col xs={24} md={5} sm={24}>
                <div className="header-item">
                  <label>Administrador</label>
                  <span className="header-text bold">
                    {data.is_admin ? 'Sim' : 'Não'}
                  </span>
                </div>
              </Col>
              <Col xs={24} md={5} sm={24}>
                <div className="header-item">
                  <label>Status</label>
                  <UserStatusToggleButton data={data} table />
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      }
    />
  );
};

export default UserCard;
