const SendIcon = ({ fill = '#0D91FE', width = 14, height = 11 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78409 10.4034V3.41477L13.2955 6.90909L9.78409 10.4034ZM0.590909 7.60795V0.363636H1.98295V7.60795H0.590909ZM0.625 7.60795V6.21023H10.875V7.60795H0.625Z"
      fill={fill}
    />
  </svg>
);

export default SendIcon;
