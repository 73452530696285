import React from 'react';
import { Chart } from 'react-google-charts';
const BrazilCloropleth = ({ data }) => {
  const options = {
    region: 'BR',
    resolution: 'provinces',
    // width: 700,
    height: 400,
    colorAxis: {
      colors: ['#72B2D8', '#072F6B'],
    },
  };
  return (
    <Chart
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      options={options}
      data={data}
    />
  );
};

export default BrazilCloropleth;
