import CreateCollection from './CreateCollection';
import { Col, Grid } from 'rsuite';

const NoCollection = ({ getCollection }) => {
  return (
    <Grid fluid className="empty-collection">
      <Col sm={24}>
        <div className="flex justify-center items-center">
          <img
            style={{ width: 400 }}
            src="https://ouch-cdn2.icons8.com/nC3HaCGSAW-r9xtJ9b1iDjFqhqxykpUZxThkxKwePnk/rs:fit:684:456/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMy82/YTk5NTJiMi1mNWVh/LTRkNDAtYjZlMi1h/ZGQzODUwYTIwMjUu/c3Zn.png"
          />
        </div>
      </Col>

      <Col sm={24}>
        <div className="empty-collection_informations">
          <h4>Você não possui coleções</h4>
          <p>Clique no botão abaixo para criar uma coleção.</p>
          <CreateCollection getCollection={getCollection} />
        </div>
      </Col>
    </Grid>
  );
};

export default NoCollection;
