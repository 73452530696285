import React, { useState } from 'react';
import { Button, Row, Col, Form, Schema, toaster, Message } from 'rsuite';
import './style.scss';

const passwordModel = Schema.Model({
  old_password: Schema.Types.StringType().isRequired(
    'Este campo é obrigatório.',
  ),
  new_password: Schema.Types.StringType()
    .isRequired('Este campo é obrigatório.')
    .minLength(8, 'Sua senha deve ter ao menos 8 caracteres'),
});

const ChangePassword = (props) => {
  const { changePasswordMethod, onSucessAction, loading } = props;
  const [passwordFormError, setPasswordFormError] = useState({});
  const [passwordFormValue, setPasswordFormValue] = useState({});

  const validateAndSavePassword = () => {
    const { old_password, new_password } = passwordFormValue;
    if (
      old_password !== '' &&
      old_password !== null &&
      new_password !== '' &&
      new_password !== null
    ) {
      if (old_password !== new_password) {
        changePasswordMethod(
          {
            old_password,
            new_password,
          },
          onSucessAction,
        );
      } else {
        toaster.push(
          <Message
            header="Atenção"
            duration={4000}
            type="warning"
            showIcon
            closable
          >
            As senhas coincidem.
          </Message>,
        );
      }
    } else {
      setPasswordFormError(old_password === '' || new_password === '');
      toaster.push(
        <Message
          header="Campos inválidos"
          duration={4000}
          type="warning"
          showIcon
          closable
        >
          Verifique os campos e tente novamente.
        </Message>,
      );
    }
  };

  return (
    <div className="change-password">
      <h4 className="title">Alterar senha</h4>
      <Form
        fluid
        model={passwordModel}
        onChange={(formValue) => {
          setPasswordFormValue(formValue);
        }}
        formValue={passwordFormValue}
        formError={passwordFormError}
        disabled={loading}
      >
        <Row gutter={32}>
          <Col lg={12} md={12} xs={24}>
            <Form.Group>
              <Form.ControlLabel>Senha atual</Form.ControlLabel>
              <Form.Control
                name="old_password"
                type="password"
                placeholder="Insira sua senha atual aqui"
              />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} xs={24}>
            <Form.Group>
              <Form.ControlLabel>Senha nova</Form.ControlLabel>
              <Form.Control
                name="new_password"
                type="password"
                placeholder="Insira sua senha nova aqui"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="button">
          <Button
            loading={loading}
            appearance="primary"
            onClick={validateAndSavePassword}
          >
            Alterar senha
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default ChangePassword;
