import { combineReducers } from 'redux';

import profiles from './profiles';
import collections from './collections';
import jurimetrics from './jurimetrics';
import searchTributario from './searchTributario';
import searchTrabalhista from './searchTrabalhista';
import login from './login';
import notifications from './notifications';
import collectionsBulkaction from './collectionsBulkaction';

export default combineReducers({
  profiles,
  collections,
  collectionsBulkaction,
  jurimetrics,
  login,
  notifications,
  searchTributario,
  searchTrabalhista,
});
