import React from 'react';
import { Turivius } from '../ui';

class TuriviusPreloader extends React.Component {
  render() {
    const { backdrop, style, className } = this.props;
    return (
      <div className={className}>
        <Turivius.TuriviusPreloader backdrop={backdrop} style={style} />
      </div>
    );
  }
}

export default TuriviusPreloader;
