import { ArrowLeft, Sms } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { InputWithIcon } from '../../../../components/TuriviusComponents/Inputs';
import { Bounce, toast } from 'react-toastify';
import {
  SSOInformation,
  onLogInSSO,
} from '../../../../services/authentication';
import isEmail from '../../../../utils/ValidateEmail';
import { Button } from '../../../../components/TuriviusComponents/Buttons';
import { CreateAccount } from './CreateAccount';
import { Whisper, Tooltip } from 'rsuite';

export const SSOLogin = ({ goLogin, properties }) => {
  const [loading, setLoading] = useState(false);
  const [emailSSO, setEmailSSO] = useState('');
  const [emailSSOError, setEmailSSOError] = useState(false);

  function validateCredencials() {
    if (isEmail(emailSSO)) {
      setEmailSSOError(false);
      onLogInSSO(emailSSO, setLoading, setEmailSSOError);
    } else {
      setEmailSSOError(true);
    }
  }

  useEffect(() => {
    document.title = 'Login SSO';
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token && token !== 'None') {
      SSOInformation(token, setLoading, properties);
    } else if (token === 'None') {
      setTimeout(() => {
        toast.error(' Não conseguimos realizar o login com esse email.', {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      }, 2000);
    }
  }, []);

  return (
    <div className="login-component">
      <div className="login-component_header">
        <Whisper
          trigger={'hover'}
          placement="top"
          speaker={<Tooltip>Voltar</Tooltip>}
        >
          <ArrowLeft
            className="cursor-pointer"
            style={{ color: '#0D91FE', marginBottom: 26 }}
            onClick={goLogin}
          />
        </Whisper>
        <img src="waving-hand.png" />
        <h2>Bem-vindo de volta!</h2>
        <p>Entre com seu e-mail corporativo e tal tal.</p>
      </div>

      <div className="login-component_body">
        <label htmlFor="email">Endereço de e-mail</label>

        <InputWithIcon
          id={'email'}
          type={'email'}
          icon={Sms}
          placeholder={'Insira seu email corporativo do SSO aqui'}
          onChange={(value) => setEmailSSO(value)}
          value={emailSSO}
          tooltip="Insira seu email somente se sua empresa estiver cadastrada com esse formato de login"
          errorInput={emailSSOError}
          errorMessage='Esse e-mail não tem SSO, confira a ortografia'
        />

        <div className='mt-2' />

        <Button
          loading={loading}
          appearance="default"
          onClick={() => validateCredencials()}
        >
          Enviar
        </Button>
      </div>

      <div className="login-component_footer">
        <div className="single-login-message">
          Logar sem SSO?{' '}
          <a onClick={goLogin} style={{ color: '#0069F5' }}>
            Clique aqui
          </a>
        </div>
      </div>
      <CreateAccount />
    </div>
  );
};
