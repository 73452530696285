import React from 'react';
import './style.scss';
class DevicesCard extends React.Component {
  toDate(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  }

  render() {
    return this.props.session ? (
      <div className="card">
        <i class="ri-checkbox-circle-fill ri-3x green"></i>
        <p className="os">
          {this.props.session.os.split(' ')[0]} . {this.props.session.browser}
        </p>
        <p className="date">
          Último acesso em: {this.toDate(this.props.session.timestamp)}
        </p>
        <button onClick={this.props.onDisconnect} className="disconnect">
          Desconectar <i class="ri-computer-line ri-lg"></i>
        </button>
      </div>
    ) : (
      <div className="card blocked">
        <i class="ri-close-circle-fill ri-3x red"></i>
        <p className="os">Este dispositivo</p>
        <p className="date">(desativado)</p>
      </div>
    );
  }
}

export default DevicesCard;
