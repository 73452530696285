import React from 'react';
import { Toggle } from 'rsuite';

const ToggleSendNotificationMonitoring = ({
  sendNotification,
  setSendNotification,
}) => {
  return (
    <div className="monitoring-settings-content_toggle">
      <Toggle
        size="lg"
        name="send_notification"
        onChange={setSendNotification}
        checked={sendNotification}
        checkedChildren={<i className="ri-check-line" />}
        unCheckedChildren={<i className="ri-close-fill" />}
      />
      <label className="title-toggle">Enviar notificação</label>
    </div>
  );
};

export default ToggleSendNotificationMonitoring;
