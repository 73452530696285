import { Folder2 } from 'iconsax-react';
import './style.scss';

const AvatarFolder = ({ data }) => {
  return (
    <div className="avatar-folder">
      <div className="avatar-folder-due">
        <Folder2 size="20" color="#292d32" />
      </div>
    </div>
  );
};

export default AvatarFolder;
