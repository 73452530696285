import { Button } from '../../../../ds-components';
import { ArrowRight } from 'iconsax-react';
import { useState } from 'react';
import { Chart } from 'react-google-charts';
import { useHistory } from 'react-router-dom';
import { Modal } from 'rsuite';

function TreemapChart({ data }) {
  const [show, setShow] = useState(false);
  const [assunto, setAssunto] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  const options = {
    minColor: '#4870E0',
    midColor: '#053AC8',
    maxColor: '#021B61',
    maxHighlightColor: '#FFF',
    headerHeight: 0,
    fontColor: 'white',
    showScale: false,
    height: 400,
    maxDepth: 1,
    legend: 'none',
    generateTooltip: (_row, _size, value) => {
      return (
        '<div style="background:#fff; padding:10px; border-style:solid; border:none"> ' +
        _size +
        ' Processos' +
        '</div>'
      );
    },
  };

  const chartEvents = [
    {
      eventName: 'ready',
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        const data = chartWrapper.getDataTable();
        google.visualization.events.addListener(chart, 'select', function () {
          var selection = chart.getSelection();
          if (selection.length > 0) {
            let tipo = data.getValue(selection[0].row, 1);
            let assuntoSelecionado = data.getValue(selection[0].row, 0);
            setAssunto(assuntoSelecionado);
            if (tipo === 'Assunto') {
              handleShow();
            }
          }
        });
      },
    },
  ];
  return (
    <>
      <Modal open={show} onClose={() => setShow(false)}>
        {/* <Modal show={show} onHide={handleClose} size="lg" > */}

        <Modal.Body className="dashboard-overview_navigate-subject">
          <img
            src="/favorite-chart.svg"
            alt="Ícone representando gráfico favorito"
            className="pb-5"
          />
          <p>Você deseja visualizar o dashboard do assunto:</p>
          <h5 className="font-semibold">{assunto}</h5>
          <div className="dashboard-overview_navigate-subject-footer mt-3">
            <Button
              appearance="danger"
              size="sm"
              outline
              // type="button"
              onClick={handleClose}
              // className={`modal-button-cancel`}
            >
              Cancelar
            </Button>
            <Button
              appearance="primary"
              size="sm"
              icon={<ArrowRight size={18} color="#fff" />}
              iconPosition="right"
              // type="button"
              onClick={() => history.push('/visao-assunto/' + assunto)}
              // className={`modal-button`}
            >
              Ver o dashboard
              {/* <i className="ri-arrow-right-line submit-button_icon" /> */}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Chart
        chartType="TreeMap"
        data={data}
        options={options}
        width="100%"
        // height="400px"
        chartEvents={chartEvents}
      />
    </>
  );
}

export default TreemapChart;
