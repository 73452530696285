import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  ListItemSecondaryAction,
} from '@material-ui/core';
import RemoveCommentButton from './RemoveCommentButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  fonts: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'inline',
  },
}));

const Comment = ({ comments, companiesProfiles, removeComment }) => {
  const classes = useStyles();
  let commentsWithProfiles =
    comments !== null &&
    comments !== undefined &&
    companiesProfiles[localStorage.getItem('company')] !== undefined
      ? comments
          .sort((a, b) => b.id - a.id)
          ?.map((comment) => {
            return {
              ...comment,
              user: companiesProfiles[localStorage.getItem('company')].find(
                (profile) => profile.id === comment.user_id,
              ),
            };
          })
      : [];
  return (
    <List className={classes.root} style={{ height: 200, overflow: 'auto' }}>
      {comments !== null && comments !== undefined ? (
        comments.length > 0 ? (
          commentsWithProfiles?.map((comment) => {
            return (
              <div key={comment.id}>
                <ListItem key={comment.id} alignItems="flex-start">
                  <ListItemAvatar>
                    {/* <Avatar alt="avatar" src={'avatar.png'} /> */}
                    {comment && comment.user && comment.user.photo ? (
                      <Avatar
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '4px',
                        }}
                        alt="foto de perfil"
                        src={`data:image/*;base64, ${comment.user.photo}`}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: '30px',
                          height: '30px',
                          marginRight: '4px',
                        }}
                        alt="foto de perfil"
                        src="avatar.png"
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={classes.fonts}>
                        {/* {comment.name} */}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {comment.user?.name || 'Usuário Desconhecido'}
                        </Typography>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${comment.text}`,
                          }}
                        ></span>
                      </>
                    }
                  />
                  {parseInt(comment.user_id) ===
                  parseInt(localStorage.getItem('user')) ? (
                    <ListItemSecondaryAction>
                      <RemoveCommentButton
                        removeComment={removeComment}
                        commentId={comment.id}
                      />
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
                <Divider />
              </div>
            );
          })
        ) : (
          <p className="comments-message">
            Nenhum comentário encontrado para esse acórdão.
          </p>
        )
      ) : (
        <p className="comments-message">
          Houve um erro ao carregar os comentários. Tente novamente mais tarde.
        </p>
      )}
    </List>
  );
};

export default Comment;
