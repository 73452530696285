import { useState, useEffect, useRef } from 'react';
import { Avatar, Drawer, Tooltip, Whisper } from 'rsuite';
import { Close } from '@rsuite/icons';
import { useHistory } from 'react-router-dom';
import './DrawerCollection.scss';
import SuggestionCards from '../SuggestionCards/SuggestionCards';
import { Dislike, Like1, Refresh2, TickCircle } from 'iconsax-react';
import { DrawerCollectionSkeleton } from './DrawerCollectionSkeleton';
import SendIcon from './Icons/SendIcon';
import ReloadIcon from './Icons/ReloadIcon';
import MarkdownFormatter from './components/MarkdownFormatter';
import { toast } from 'react-toastify';

type DrawerCollectionProps = {
  open: boolean;
  onClose: () => void;
  message: string;
  collectionId?: any;
};

type ChatData = {
  chat_session_id: string;
};

const DrawerCollection: React.FC<DrawerCollectionProps> = ({
  open,
  onClose,
  message,
  collectionId,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [inputText, setInputText] = useState('');
  const [userQuestion, setUserQuestion] = useState<string>('');
  const [messageDate, setMessageDate] = useState<string | null>(null);
  const [chatData, setChatData] = useState<ChatData | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [searchHistory, setSearchHistory] = useState<boolean>(false);
  const [copiedId, setCopiedId] = useState<number | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToLastMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (drawerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = drawerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
      setShowScrollButton(!isAtBottom);
    }
  };

  useEffect(() => {
    const drawer = drawerRef.current;
    if (drawer) {
      drawer.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        drawer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const ragUrl =
    'https://boards-showroom-539523194959.southamerica-east1.run.app';

  const userID = localStorage.getItem('user');
  const boardId = collectionId;

  const fetchMessages = async (sessionId: string) => {
    try {
      const response = await fetch(
        `${ragUrl}/api/messages/${sessionId}/get_messages/`,
      );
      const data = await response.json();
      setMessages(data);

      if (data.length > 0) {
        setSearchHistory(true);
        const lastMessage = data[data.length - 1];
        formatAndSetDate(lastMessage.created_at);
      } else {
        setSearchHistory(false);
      }
      setTimeout(scrollToLastMessage, 100);
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
  };

  const formatAndSetDate = (dateString: string) => {
    const timestamp = Date.parse(dateString);
    const date = new Date(timestamp);

    if (!isNaN(date.getTime())) {
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} às ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
      setMessageDate(formattedDate);
    } else {
      console.error('Data inválida:', dateString);
      setMessageDate('Data inválida');
    }
  };

  const sendMessage = async (question: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${ragUrl}/api/messages/send_message/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ board_id: boardId, user_id: userID, question }),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Erro: ${errorMessage}`);
      }

      const data = await response.json();
      setMessages((prev) => [
        ...prev,
        {
          type: 'ai',
          created_at: Date.now(),
          content: data.message,
        },
      ]);
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      alert('Ocorreu um erro ao enviar sua mensagem. Tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetch(`${ragUrl}/api/conversations/${boardId}/last_chat/`)
        .then((response) => response.json())
        .then((data) => {
          setChatData(data);
          setIsLoading(false);
          fetchMessages(data.chat_session_id).then(() => {
            setIsLoading(false);
            scrollToLastMessage();
            setShowScrollButton(false);
          });
        })
        .catch((error) => {
          console.error('Erro ao buscar dados da conversa:', error);
          setIsLoading(false);
        });
    }
  }, [open]);

  const allSuggestions = [
    'Qual o entendimento predominante sobre o tema tratado nesses acórdãos?',
    'Houve alguma alteração na tendência de posicionamento dos julgadores?',
    'Nesses acórdãos, quais os pequenos detalhes que fazem toda a diferença?',
    'Qual precedente foi mais citado nessas decisões?',
    'Quais leis e artigos foram mais mencionados?',
    'Quais julgados mais recentes podem impactar outros casos semelhantes?',
    'Quais os principais temas secundários discutidos nesses acórdãos?',
    'Quais os principais argumentos vencedores?',
    'Qual precedente mais diverge dos demais no entendimento do tema? Qual o relator dele?',
    'Quais são os pontos de divergência mais frequentes entre os julgadores nesses acórdãos?',
    'Quais decisões seguiram uma linha de argumentação inovadora?',
    'Existe alguma diferença de entendimento entre os Tribunais?',
  ];

  const profile = JSON.parse(localStorage.getItem('profile') || '{}');

  const getFirstName = (data: string | undefined) => {
    if (data !== undefined) {
      if (data.split(' ').length >= 2) {
        return `${data.split(' ')[0]} ${data.split(' ')[1].substring(0, 1)}`;
      } else {
        return data;
      }
    } else {
      return 'Turivius';
    }
  };

  const [suggestions, setSuggestions] = useState<string[]>(
    allSuggestions.slice(0, 6),
  );
  const [currentIndex, setCurrentIndex] = useState<number>(6);

  const reloadSuggestions = () => {
    let nextIndex = currentIndex;

    if (nextIndex >= allSuggestions.length) {
      nextIndex = 0;
    }

    const newSuggestions = allSuggestions.slice(nextIndex, nextIndex + 6);
    setSuggestions(newSuggestions);

    setCurrentIndex(nextIndex + 6);
  };

  const handleClose = () => {
    onClose();
    history.push({
      pathname: window.location.pathname,
      search: '?openDrawer=false',
    });
  };

  const handleCopyClick = (id: number, content: string) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedId(id);
        setTimeout(() => {
          setCopiedId(null);
        }, 3000);
      })
      .catch((err) => {
        console.error('Erro ao copiar para a área de transferência: ', err);
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputText.trim()) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      requestAnimationFrame(() => scrollToLastMessage());
    }
  }, [messages]);

  const handleSubmit = () => {
    if (inputText.trim()) {
      setMessages((prev) => [
        ...prev,
        {
          type: 'human',
          created_at: Date.now(),
          content: inputText,
        },
      ]);

      sendMessage(inputText);
      setUserQuestion(inputText);
      setInputText('');
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setMessages((prev) => [
      ...prev,
      {
        type: 'human',
        created_at: Date.now(),
        content: suggestion,
      },
    ]);
    sendMessage(suggestion);
    setUserQuestion(suggestion);
  };

  const handleClearChat = async () => {
    if (!chatData) {
      alert('Nenhum chat para limpar.');
      return;
    }

    const sessionId = chatData.chat_session_id;

    try {
      const response = await fetch(
        `${ragUrl}/api/messages/${sessionId}/reset_chat/`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        },
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Erro ao limpar o chat: ${errorMessage}`);
      }
      if (response) {
        setMessages([]);
        toast.success('Chat limpo com sucesso');
      }
    } catch (error) {
      console.error('Erro ao limpar o chat:', error);
      alert('Ocorreu um erro ao limpar o chat. Tente novamente.');
    }
  };

  return (
    <Drawer open={open} onClose={onClose} placement="right" size="65%">
      <div
        ref={drawerRef}
        className={
          messages.length > 0 ? 'container-history' : 'container-modal'
        }
      >
        {messages.length === 0 ? (
          <>
            <div className="title-modal">
              <img
                className="icon-stars"
                src="/stars-group.png"
                alt="Ícone de estrelas"
              />
              <h1>Turivius IA</h1>
              <span>beta</span>

              <button className="close-button" onClick={handleClose}>
                <Close className="close-icon-modal" />
                <p>Fechar</p>
              </button>
            </div>

            <span>Como podemos te ajudar?</span>
            <p>Comece com uma das sugestões abaixo ou faça a sua pergunta</p>
          </>
        ) : (
          <>
            <div className="modal-search-history">
              <button
                className="close-button-search-history"
                onClick={handleClose}
              >
                <Close className="close-icon-modal" />
                <p>Fechar</p>
              </button>
            </div>

            <div className="card-search-history">
              <div className="title-modal-search-history">
                <img
                  className="icon-stars"
                  src="/stars-group.png"
                  alt="Ícone de estrelas"
                />
                <h1>Turivius IA</h1>
                <span>beta</span>
              </div>

              {messages.map((item) => (
                <div key={item.id} className="card-container">
                  <div className="card-user">
                    {item.type === 'human' ? (
                      <>
                        <div className="box-user">
                          <div className="box-profile">
                            <Whisper
                              placement="bottom"
                              trigger="hover"
                              speaker={<Tooltip>Seu perfil</Tooltip>}
                            >
                              <Avatar
                                size="sm"
                                circle
                                alt="Foto de perfil"
                                src={`data:image/*;base64, ${profile?.photo}`}
                              />
                            </Whisper>
                            <h2>{getFirstName(profile?.name)}</h2>
                          </div>
                          <div className="data-search">
                            <img
                              className="icon-stars"
                              src="/calendar.png"
                              alt="Ícone de calendário"
                            />
                            <span>
                              {new Date(item.created_at).toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <span>
                          <MarkdownFormatter markdownText={item.content} />
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="content-card">
                          <div className="title-modal-search-result">
                            <img
                              src="/stars-group.png"
                              alt="Ícone de estrelas"
                            />
                            <h1>Turivius IA</h1>
                            <span>beta</span>
                          </div>
                          <div className="data-search-results">
                            <img
                              src="/calendar.png"
                              alt="Ícone de calendário"
                            />
                            <span>
                              {new Date(item.created_at).toLocaleString()}
                            </span>
                          </div>
                        </div>

                        <span>
                          <MarkdownFormatter markdownText={item.content} />
                        </span>

                        <div className="buttons">
                          <div className="copy-reload-buttons">
                            <button
                              className={`button-copy ${copiedId === item.id ? 'copied' : ''}`}
                              onClick={() =>
                                handleCopyClick(item.id, item.content)
                              }
                            >
                              {copiedId === item.id ? (
                                <TickCircle size="20" color="#12b76a" />
                              ) : (
                                <img
                                  src="/icon-copy.png"
                                  alt="Ícone de copiar"
                                />
                              )}
                              {copiedId === item.id ? 'Copiado' : 'Copiar'}
                            </button>
                            <button
                              className="reload-button-history"
                              onClick={async () => {
                                setIsLoading(true);
                                try {
                                  await fetchMessages(item.chat_session_id);
                                  setTimeout(() => {
                                    setIsLoading(false);
                                  }, 4000);
                                } catch (error) {
                                  console.error(
                                    'Erro ao carregar mensagens',
                                    error,
                                  );
                                  setTimeout(() => {
                                    setIsLoading(false);
                                  }, 4000);
                                }
                              }}
                              disabled={isLoading}
                            >
                              <ReloadIcon />
                            </button>
                          </div>
                          <div className="copy-reload-buttons">
                            <span>Essa resposta foi útil?</span>
                            <button className="button-like">
                              <Like1 size="15" color="#0d91fe" />
                            </button>
                            <button className="button-dislike">
                              <Dislike size="15" color="#667085" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {isLoading && <DrawerCollectionSkeleton visible={isLoading} />}
        {messages.length === 0 ? (
          <>
            <div className="button-reload-history">
              <button onClick={reloadSuggestions} className="reload-button">
                <h2>Sugestões</h2>
                <img
                  className="icon-reload"
                  src="/Vector.png"
                  alt="icone de carregar"
                />
              </button>
            </div>
            <div className="card-container">
              {suggestions.length > 0 &&
                suggestions.map((text, index) => (
                  <SuggestionCards
                    key={index}
                    text={text}
                    onClick={handleSuggestionClick}
                  />
                ))}
            </div>
          </>
        ) : (
          ''
        )}

        {showScrollButton && (
          <button className="button-history" onClick={scrollToLastMessage}>
            <img src="/arrow-down.png" alt="Ícone do botão" />
          </button>
        )}

        {!isLoading && messages.length > 0 && (
          <div>
            <button className="clear-chat-button" onClick={handleClearChat}>
              <Refresh2 size="20" color="#98A2B3 " />
              <span>Limpar chat</span>
            </button>
          </div>
        )}

        <div className="search-modal">
          <div className="input-container">
            <input
              type="text"
              placeholder="Mensagem para Turivius IA"
              value={inputText}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <button
              type="submit"
              className={`send-button ${inputText ? 'active' : ''}`}
              onClick={inputText ? handleSubmit : () => setInputText('')}
            >
              <SendIcon fill={inputText ? '#0D91FE' : '#98A2B3'} />
              Enviar
            </button>
            {/* <span style={{ fontSize: '12px', color: '#98A2B3' }}>
              Essa ferramenta de IA tem uma acurácia de x sobre às decisões y e
              z
            </span> */}
          </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
    </Drawer>
  );
};

export default DrawerCollection;
