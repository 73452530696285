import { Turivius } from '../../../../../ui';

const LineChart = ({ key, id, data, getColors, axisLeftlegend }) => {
  return (
    <Turivius.TuriviusLineChart
      style={{
        width: '100%',
        height: 350,
      }}
      key={key}
      id={id}
      data={data}
      getColors={(value) => {
        return getColors[value.id];
      }}
      axisLeft={{
        legendOffset: -45,
        legend: axisLeftlegend,
        legendPosition: 'middle',
        tickValues: 6,
        format: (value) => {
          value = Number(value);
          if (value >= 1000000) {
            value = value / 1000000 + 'M';
          } else if (value >= 1000) {
            value = value / 1000 + 'K';
          }
          return value;
        },
      }}
      axisBottom={{
        legendOffset: 0,
        tickRotation: 30,
        // legend: sp.data.config.labels.left,
        // legendPosition: 'middle',
      }}
      legends={[]}
      //legends={sp.data.config.legends}
      yFormat=" >-.1d"
      tooltip={(d) => {
        return (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <div>
              <div
                key={d.point.id}
                style={{
                  color: d.point.serieColor,
                  margin: '4px 0',
                  width: 12,
                  height: 12,
                  display: 'inline-block',
                  backgroundColor: d.point.serieColor,
                  position: 'absolute',
                }}
              ></div>
              <span style={{ paddingLeft: 15 }}>{d.point.serieId}</span>
            </div>
            <span>{d.point.data.xFormatted}:</span>{' '}
            <strong>{d.point.data.y}</strong>
          </div>
        );
      }}
      enableSlices="x"
      enableCrosshair={true}
    />
  );
};

export default LineChart;
