import React, { useEffect, useState } from 'react';
import { Grid, Row } from 'rsuite';
import { requester } from '../../../../requesters';
import CompanyInformations from '../components/CompanyInformations';
import SignatureCard from '../components/SignatureCard';
import CustomNav from '../components/CustomNav';
import AvatarChange from '../components/AvatarChange';
import UserList from '../components/UsersList';

//Components
// import CompanyInformation from "../organisms/Administration/CompanyInformations";
// import AvatarChange from "../components/AvatarChange";
// import SignatureCard from "../organisms/Administration/SignatureCard";
// import CustomNav from "../organisms/Administration/CustomNav";
// import UserList from "../organisms/Administration/UsersList";

//Settings
import './Administration.scss';

const tabMenu = [
  {
    eventKey: 'users',
    title: 'Usuários',
  },
  {
    eventKey: 'company',
    title: 'Empresa',
  },
  {
    eventKey: 'contract',
    title: 'Minha Assinatura',
  },
];

const Admin = () => {
  const [active, setActive] = useState('users');
  const [companyName, setCompanyName] = useState('');
  const [users, setUsers] = useState([]);
  const [loadUsers, setLoadUsers] = useState(false);

  const getProfile = () => {
    requester.profiles
      .getById(localStorage.getItem('user'))
      .then((response) => {
        setCompanyName(response.company.name);
        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));
      });
  };

  const getUsers = () => {
    setLoadUsers(true);
    requester.profiles
      .getCompanyProfiles(localStorage.getItem('company'))
      .then((response) => {
        setUsers(response);
      })
      .catch((err) => console.error)
      .finally(() => setLoadUsers(false));
  };

  useEffect(() => {
    document.title = 'Administração';
    getProfile();
  }, ['']);

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'auto',
        paddingTop: 0,
        backgroundColor: '#fff',
      }}
    >
      <Grid className="admin-principal" id="admin-principal" fluid>
        <Row
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 6,
          }}
        >
          <Row>
            <AvatarChange
              tabActive={active}
              companyName={companyName}
              getUsers={() => getUsers()}
            />
          </Row>
          <Row className="admin-menu">
            <CustomNav
              appearance="subtle"
              items={tabMenu}
              active={active}
              onSelect={setActive}
            />
          </Row>
        </Row>
        <Row className="admin-body">
          {active === 'users' ? (
            <UserList getUsers={getUsers} users={users} loadUsers={loadUsers} />
          ) : active === 'company' ? (
            <CompanyInformations updateCompany={getProfile} />
          ) : (
            <SignatureCard />
          )}
        </Row>
      </Grid>
    </div>
  );
};

export default Admin;
