import React from 'react';

class RedirectFirstLogin extends React.Component {
  componentDidMount() {
    window.location.href = this.props.location.hash.replace('#', '/');
  }

  render() {
    return <div />;
  }
}

export default RedirectFirstLogin;
