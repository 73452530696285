import React from 'react';
import { SelectPicker, InputNumber } from 'rsuite';

const frequencyData = [
  { label: 'Diária', value: 'D' },
  { label: 'Semanal', value: 'W' },
  { label: 'Mensal', value: 'M' },
];
const weekDayData = [
  { label: 'Segunda-Feira', value: 0 },
  { label: 'Terça-Feira', value: 1 },
  { label: 'Quarta-Feira', value: 2 },
  { label: 'Quinta-Feira', value: 3 },
  { label: 'Sexta-Feira', value: 4 },
  { label: 'Sábado', value: 5 },
  { label: 'Domingo', value: 6 },
];

const SelectFrequencyMonitoring = ({
  setMonitoringMonthDay,
  setMonitoringWeekDay,
  setFrequency,
  frequency,
  monitoringWeekDay,
  monitoringMonthDay,
}) => {
  return (
    <div className="monitoring-settings-content">
      <div className="monitoring-settings-content">
        <label className="monitoring-settings-label">Frequência</label>
        <SelectPicker
          size="lg"
          name="frequency"
          className=""
          onChange={(value) => {
            setFrequency(value);
            if (value === 'D') {
              setMonitoringMonthDay(null);
              setMonitoringWeekDay(null);
            }
          }}
          value={frequency}
          data={frequencyData}
          cleanable={false}
          block
        />
      </div>
      {frequency === 'W' ? (
        <div className="monitoring-settings-content">
          <label className="monitoring-settings-label">Dia da semana</label>
          <SelectPicker
            size="lg"
            className="monitoring-settings-content_input-drop"
            name="monitoring_week_day"
            onChange={setMonitoringWeekDay}
            value={monitoringWeekDay}
            data={weekDayData}
            cleanable={false}
            block
          />
        </div>
      ) : frequency === 'M' ? (
        <div className="monitoring-settings-content">
          <label className="monitoring-settings-label">Dia do mês</label>
          <InputNumber
            block
            size="lg"
            name="monitoring_month_day"
            min={1}
            max={31}
            prefix="Dia"
            className="monitoring-settings-content_input-drop"
            placeholder="Selecione um dia do mês (entre 1 a 31)"
            onChange={setMonitoringMonthDay}
            value={monitoringMonthDay}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SelectFrequencyMonitoring;
