import { useState } from 'react';

import { User } from 'iconsax-react';
import { Avatar } from 'rsuite';
import { SpinningLoader } from '../../../ds-components';
const Card = ({ name, image, imageLoading, work }) => {
  const [workOptions, _] = useState([
    { value: 'DJ', name: 'work', label: 'Departamento Jurídico' },
    { value: 'EA', name: 'work', label: 'Escritório de advocacia' },
  ]);
  const getWork = () => {
    const workLabel = workOptions.find((item) => item.value === work);
    if (workLabel) {
      return workLabel.label;
    } else {
      return '';
    }
  };

  return (
    <div className="card-setup">
      <div className="card-header">
        <div className="image">
          {imageLoading ? (
            <SpinningLoader color="primary" />
          ) : image ? (
            <Avatar
              circle
              alt="foto de perfil"
              src={`data:image/*;base64, ${image}`}
            />
          ) : (
            <User size={36} color="#FFF" />
          )}
        </div>
        <h3 className="name">{name}</h3>
      </div>
      <div className="card-body">
        <div className="work-label">{getWork()}</div>
      </div>
    </div>
  );
};

export default Card;
