import React from 'react';
import AvatarChange from './Avatar';

import './style.scss';

const ProfileHeader = (props) => {
  const { profile, company, patchImageMethod, type, requesting } = props;

  return (
    <div className="header">
      <AvatarChange profile={profile} company={company} />
    </div>
  );
};

export default ProfileHeader;
