import { Loader } from 'rsuite';

const ButtonDefault = ({
  type = 'button',
  loading,
  onClick,
  appearance,
  children,
  ...rest
}) => {
  return (
    <button
      type={type}
      className={`turivius-button ${appearance}`}
      onClick={onClick}
      disabled={loading}
      {...rest}
    >
      {loading ? <Loader color="#FFFFFF" /> : children}
    </button>
  );
};

export default ButtonDefault;
