import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Tooltip, Whisper } from 'rsuite';
// import "./InformativeIcons.scss";

const InformativeIcons = ({ permissions }) => {
  // useEffect(() => {
  //   console.log(permissions);
  // }, []);

  const shared_monitoring = false;
  const send_email = true;
  const send_notification = false;
  const frequencyMock = 'D';
  const entities = true;

  const getFrequency = (frequencyType) => {
    let data = {
      icon: '',
      class: '',
      tooltip: {
        title: '',
      },
    };

    switch (frequencyType) {
      case 'D':
        data.icon = 'ri-24-hours-line';
        data.class = 'informative_button-active';
        data.tooltip.title = 'Monitoramento Diário';
        break;

      case 'W':
        data.icon = 'ri-calendar-2-line';
        data.class = 'informative_button-disabled';
        data.tooltip.title = 'Monitoramento Semanal';
        break;

      case 'M':
        data.icon = 'ri-calendar-event-line';
        data.class = 'informative_button-active-green';
        data.tooltip.title = 'Monitoramento Mensal';
        break;

      default:
        break;
    }

    return data;
  };

  const informative = [
    {
      icon: getFrequency(permissions.frequency).icon,
      class: getFrequency(permissions.frequency).class,
      tooltip: {
        title: getFrequency(permissions.frequency).tooltip.title,
        description: 'Você pode alterar essa opção clicando em “Configurações”',
      },
    },
    {
      icon: permissions.send_email
        ? 'ri-mail-check-line'
        : 'ri-mail-close-line',
      class: permissions.send_email
        ? 'informative_button-active'
        : 'informative_button-disabled',
      tooltip: {
        title: permissions.send_email
          ? 'Envio de e-mail ativado'
          : 'Envio de e-mail desativado',
        description: 'Você pode alterar essa opção clicando em “Configurações”',
      },
    },
    {
      icon: permissions.send_notification
        ? 'ri-notification-4-line'
        : 'ri-notification-off-line',
      class: permissions.send_notification
        ? 'informative_button-active'
        : 'informative_button-disabled',
      tooltip: {
        title: permissions.send_notification
          ? 'Notificações ativadas'
          : 'Notificações desativadas',
        description: 'Você pode alterar essa opção clicando em “Configurações”',
      },
    },
    {
      icon:
        permissions.shared_with.length > 0
          ? 'ri-user-shared-line'
          : 'ri-user-unfollow-line',
      class:
        permissions.shared_with.length > 0
          ? 'informative_button-active'
          : 'informative_button-disabled',
      tooltip: {
        title:
          permissions.shared_with.length > 0
            ? 'Monitoramento compartilhado'
            : 'Monitoramento não compartilhado',
        description: 'Você pode alterar essa opção clicando em “Configurações”',
      },
    },
    {
      icon: 'ri-building-4-line',
      class: permissions.all_entities
        ? 'informative_button-active'
        : 'informative_button-disabled',
      tooltip: {
        title: permissions.all_entities
          ? 'Monitorando todos os orgãos'
          : 'Monitorando alguns orgãos',
        description: 'Você pode alterar essa opção clicando em “Configurações”',
      },
    },
  ];

  return (
    <div className="informative-container">
      {informative?.map((item, index) => {
        return (
          <Whisper
            placement="bottom"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip className="informative-tooltip">
                {/* <div className="informative-tooltip"> */}
                <label className="tooltip-title">{item?.tooltip?.title}</label>
                <p>{item?.tooltip?.description}</p>
                {/* </div> */}
              </Tooltip>
            }
            className="informative"
            key={index}
          >
            <button
              aria-readonly
              className={`informative_button ${item.class}`}
            >
              <i className={`${item.icon} informative_icon`} />
            </button>
          </Whisper>
        );
      })}
    </div>
  );
};

export default InformativeIcons;
