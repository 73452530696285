import { useTokenLoginStore } from '../../../store/ducks/tokenLogin';
import { timeout } from '../../../utils/time';
import { ClipboardEvent, KeyboardEvent, useRef, useState } from 'react';

const maxPinLength = 6;

type OtpInputProps = {
  onSubmit: () => void;
};

export const OtpInput = ({ onSubmit }: OtpInputProps) => {
  const { setCode } = useTokenLoginStore();
  const [value, setValue] = useState<string>('');
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const validation: RegExp = /\d/g;

  const updateValue = () => {
    const newValue: string = Array.from(
      { length: maxPinLength },
      (_, index) => {
        return inputRefs.current[index].value || ' ';
      },
    ).join('');
    setValue(newValue);
    setCode(newValue);
  };

  const handleInput = (pin: HTMLInputElement): void => {
    const pinValue: RegExpMatchArray | null = pin.value.match(validation);

    if (!pinValue || !pinValue.length) {
      pin.value = '';
      return;
    }

    pin.value = pinValue[0];
    updateValue();
    focusNextRef(pin.getAttribute('data-ref'));
  };

  const handlePaste = (event: ClipboardEvent<HTMLFormElement>) => {
    const text: RegExpMatchArray | null = event.clipboardData
      .getData('text')
      .match(validation);
    if (!text || !text.length) return;

    Array.from({ length: maxPinLength }, (_, index) => {
      inputRefs.current[index].value = text[index];
    }).join('');
    const newValue = text.splice(0, 6).toString().replace(/\W+/g, '');
    setValue(newValue);
    setCode(newValue);
  };

  const focusNextRef = (current: string | null): void => {
    if (!current || current === (maxPinLength - 1).toString()) return;
    const next: number = parseInt(current, 10) + 1;
    inputRefs.current[next]?.focus();
    inputRefs.current[next]?.select();
  };

  const focusPreviousRef = (current: string | null): void => {
    if (!current) return;
    const previous: number = parseInt(current, 10) - 1;
    inputRefs.current[previous]?.focus();
    inputRefs.current[previous]?.select();
  };

  const onKeyDown = async (
    event: KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'Enter') return onSubmit();
    if (event.key === 'Backspace' && index === maxPinLength - 1) {
      event.currentTarget.value = '';
      updateValue();
    }
    await timeout(50);
    if (event.key === 'Backspace') focusPreviousRef(index.toString());
  };

  return (
    <form className="flex justify-between" onPaste={handlePaste}>
      {Array.from({ length: maxPinLength }).map((_, index) => (
        <input
          key={index}
          ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
          onChange={(e) => handleInput(e.target as HTMLInputElement)}
          onKeyDown={(e) => onKeyDown(e, index)}
          autoComplete="off"
          aria-label={`Pin ${index + 1}`}
          data-ref={index.toString()}
          type="text"
          maxLength={1}
          className={`w-[71.4px] h-[70px] relative overflow-hidden text-center outline-none rounded-lg text-4xl font-medium bg-gray-50 focus:bg-gray-100`}
        />
      ))}
    </form>
  );
};
