import { useState } from 'react';
import {
  IconButton,
  Uploader,
  toaster,
  Loader,
  Message,
  Row,
  Col,
  Input,
} from 'rsuite';
import { Turivius } from '../../../../../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import '../style.scss';
import { requester } from '../../../../../../requesters';
import CompanyAvatar from './CompanyAvatar';
import { useEffect } from 'react';

const AvatarChange = ({ profile, company }) => {
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [loadPhoto, setLoadPhoto] = useState(false);
  const [profileImg, setProfileImg] = useState(null);

  function patchImageMethod(photo) {
    setUploading(true);
    requester.profiles
      .updateProfileImage(localStorage.getItem('user'), photo)
      .then((response) => {
        getProfilePhoto();
        toaster.push(
          <Message
            header="Dados atualizados"
            duration={4000}
            type="success"
            showIcon
            closable
          >
            Imagem atualizada com sucesso!
          </Message>,
        );
      })
      .catch((err) => {
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente.
          </Message>,
        );
        console.error(err);
      })
      .finally(() => {
        setUploading(false);
      });
  }

  function getProfile() {
    requester.profiles
      .getById(localStorage.getItem('user'))
      .then((response) => {
        localStorage.setItem('company', response.company.id);
        localStorage.setItem('profile', JSON.stringify(response));
      });
  }

  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      canvas.width = 200; // target width
      canvas.height = 200; // target height

      let image = new Image();
      image.onload = () => {
        const aspect = image.height / image.width;
        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          (canvas.width - canvas.width / aspect) / 2,
          0,
          canvas.width / aspect,
          canvas.height,
        );
        let photo = canvas.toDataURL().split(',')[1];
        patchImageMethod(photo);
      };

      image.src = e.target.result;
      setFileInfo(reader.result);
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  function getProfilePhoto() {
    setLoadPhoto(true);
    requester.profiles
      .getProfilePhoto(localStorage.getItem('user'))
      .then((response) => {
        if (response.photo) {
          setProfileImg(`data:image/*;base64, ${response.photo}`);
        } else {
          setProfileImg(null);
        }
      })
      .catch((err) => console.log)
      .finally(() => {
        setLoadPhoto(false);
      });
  }

  useEffect(() => {
    getProfilePhoto();
  }, []);

  return (
    <div className="header-cover">
      <div className="avatar-profile_content">
        <div className="avatar-profile">
          <>
            {loadPhoto ? (
              <Loader />
            ) : (
              <img
                alt={profile?.name}
                src={fileInfo ? fileInfo : profileImg ? profileImg : 'user.png'}
              />
            )}
          </>

          <div className="change-button_content">
            <Uploader
              fileListVisible={false}
              accept=".png, .jpg, .jpeg"
              action=""
              onUpload={(file) => {
                setUploading(true);
                // previewFile(file.blobFile)
                previewFile(file.blobFile, (value) => {
                  //setFileInfo(value);
                });
              }}
              onError={() => {
                setFileInfo(null);
              }}
            >
              <IconButton
                className="change-button"
                loading={uploading}
                icon={<FontAwesomeIcon icon={faCamera} />}
              />
            </Uploader>
          </div>
          <div className="profile-content">
            <div className="profile-line" />
            <h4 className="profile-name">{profile?.name}</h4>
            <p className="user-occupation">
              {profile?.position} em&nbsp;{' '}
              <Turivius.TuriviusPopover
                trigger="hover"
                placement="autoVertical"
                message={
                  <Row style={{ maxWidth: 300 }}>
                    <Col className="align-center" xs={24} sm={24} md={24}>
                      {company?.logo ? (
                        <CompanyAvatar
                          src={`data:image/*;base64, ${company.logo}`}
                          alt={company.name}
                        />
                      ) : null}
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <label>Nome</label>
                      <Input
                        id={`profile-company-popover-name-${Math.random()}`}
                        readOnly
                        placeholder="Nome"
                        xs={12}
                        sm={12}
                        value={company?.name}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                      <label>E-mail Principal</label>
                      <Input
                        id={`profile-company-popover-email-${Math.random()}`}
                        readOnly
                        placeholder="E-mail Principal"
                        xs={12}
                        sm={12}
                        value={company?.email}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <label>Endereço</label>
                      <Input
                        id={`profile-company-popover-address-${Math.random()}`}
                        readOnly
                        placeholder="Endereço"
                        xs={12}
                        sm={12}
                        value={company?.address}
                      />
                    </Col>

                    <Col xs={24} sm={24} md={24}>
                      <label>Descrição</label>
                      <Input
                        readOnly
                        placeholder="Descrição"
                        xs={12}
                        sm={12}
                        value={company?.description}
                      />
                    </Col>
                  </Row>
                }
              >
                <a className="single-collection-popover-button">
                  {company?.name}
                </a>
              </Turivius.TuriviusPopover>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarChange;
