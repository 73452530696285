import { posthog } from 'posthog-js';
import { Pagination } from 'rsuite';

const ProcessList = ({ data, dashType, page, setPage }) => {
  return (
    <div className="tv-card dashboard-process-list">
      <table className="dashboard-process-list_table">
        <thead className={`dashboard-process-list_table-header-${dashType}`}>
          <tr>
            <th>Número do processo</th>
            <th>Tribunal</th>
            <th>Data de distribuição</th>
            <th>Reclamantes</th>
            <th>Reclamados</th>
            <th>Íntegra</th>
          </tr>
        </thead>
        <tbody className="dashboard-process-list_table-content">
          {data.table?.map((acordao, index) => (
            <tr key={index}>
              <td>{acordao?.process}</td>
              <td>{acordao?.court_info?.court}</td>
              <td>{acordao?.distribution_date_format}</td>
              <td>
                {acordao?.authors?.map((author, index) => {
                  if (author.length > 1) {
                    return author.name_author + ',';
                  } else {
                    return author.name_author;
                  }
                })}
              </td>
              <td>
                {acordao?.defendants?.map((defendant, index) => {
                  if (defendant.length > 1) {
                    return defendant.name_author + ',';
                  } else {
                    return defendant.name_author;
                  }
                })}
              </td>
              <td>
                {acordao?.url ? (
                  <a
                    href={acordao?.url}
                    onClick={() => {
                      posthog.capture(`Viu Processo ${acordao.process}`);
                    }}
                    target="_blank"
                    className="dashboard-process-list_table-content-btn"
                    rel="noreferrer"
                  >
                    Ver
                  </a>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-3">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={data.totalPages}
          activePage={page}
          onChangePage={(page) => setPage(page)}
        />
      </div>
    </div>
  );
};

export default ProcessList;
