import { create } from 'zustand';

type Error = {
  main_color?: boolean
  text_color?: boolean
  logo_url?: boolean
}

type Report = {
  file?: File
  logo_url?: string
  main_color?: string
  text_color?: string
}

interface ExportReportState {
  report?: Report
  error: Error
  setReport: (report?: Report) => void
  setError: (error?: Error) => void
  clear: () => void;
}

export const useExportReports = create<ExportReportState>((set) => ({
  report: undefined,
  error: {
    main_color: false,
    logo_url: false,
    text_color: false,
  },
  setReport: (report) =>
    set((state) => ({
      ...state,
      report: {
        ...state.report,
        ...report
      }
    })),
  setError: (error) =>
    set((state) => ({
      ...state,
      error: {
        ...state.error,
        ...error
      }
    })),
  clear: () => set(() => ({
    report: undefined,
    error: {
      main_color: false,
      logo_url: false,
      text_color: false,
    },
  })),
}));
