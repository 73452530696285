import { useCallback, useEffect, useState } from 'react';
import { Col, Grid } from 'rsuite';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as LoginActions } from '../../../../store/ducks/login';
import { Creators as ProfilesActions } from '../../../../store/ducks/profiles';
import { EmailToken } from '../components/EmailToken';
import { ForgotPassword } from '../components/ForgotPassword';
import { SSOLogin } from '../components/SSOLogin';
import { LoginComponent } from '../components/Login';
import { queryStringToObject } from '../../../../utils/query';
import { useGoogleLogin, useOauthMicrosoftLogin } from '../../../../api/auth';
import { makeTheGoogleLogin } from '../../../../services/authentication';
import TuriviusPreloader from '../../../../ui/loaders/TuriviusPreloader';
import { toast } from 'react-toastify';
import { useOauthMicrosoftStore } from '../../../../store/ducks/oauthMicrosoft';
import { clear } from '@testing-library/user-event/dist/clear';

const Login = (props: any) => {
  const location = useLocation();
  const { push, replace } = useHistory();
  const [step, setStep] = useState('login');
  const [isMob, setIsMob] = useState(null);

  const {
    data: dataGoogleLogin,
    mutate: mutateGoogleLogin,
    isError,
  } = useGoogleLogin();
  const { data: dataOauthMicrosoftLogin, mutate: mutateOauthMicrosoftLogin } =
    useOauthMicrosoftLogin();
  const {
    code: codeOauthMicrosoftStore,
    email: emailOauthMicrosoftStore,
    clear: clearOauthMicrosoftStore,
  } = useOauthMicrosoftStore();

  const goBack = useCallback(() => setStep('login'), [step]);

  const RenderComponent = () => {
    switch (step) {
      case 'login':
        return (
          <LoginComponent
            isMob={isMob}
            properties={props}
            forgotPassword={() => setStep('forgot-password')}
            emailToken={() => setStep('email-token')}
            SSOLogin={() => setStep('sso')}
          />
        );
      case 'forgot-password':
        return <ForgotPassword goLogin={goBack} />;
      case 'email-token':
        return (
          <EmailToken goLogin={goBack} properties={props} setStep={setStep} />
        );
      case 'sso':
        return <SSOLogin properties={props} goLogin={goBack} />;
      case 'loading':
        return <TuriviusPreloader />;
      default:
        <p>error</p>;
    }
  };

  const handleResize = () => {
    //@ts-ignore
    setIsMob(window.detectMob());
  };

  useEffect(() => {
    document.title = 'Login';
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (dataGoogleLogin !== undefined && step !== 'loading') {
      setStep('loading');
      return makeTheGoogleLogin(dataGoogleLogin.data, props, setStep);
    }
    const { search } = location;
    const googleLogin = queryStringToObject(search);
    if (!googleLogin.code || !googleLogin.email) return;
    mutateGoogleLogin({
      code: googleLogin.code,
      email: googleLogin.email,
    });
  }, [location, dataGoogleLogin, step]);

  useEffect(() => {
    const { search } = location;
    const newPassword = queryStringToObject(search);

    if (newPassword.email && newPassword.token)
      return push(`/nova-senha${search}`);
  }, [location]);

  useEffect(() => {
    const { search } = location;
    const firstLogin = queryStringToObject(search);

    if (firstLogin.email && firstLogin.hash)
      return push(`/primeiro-acesso${search}`);
  }, [location]);

  useEffect(() => {
    if (dataOauthMicrosoftLogin !== undefined && step !== 'loading') {
      setStep('loading');
      return makeTheGoogleLogin(dataOauthMicrosoftLogin.data, props, setStep);
    }
    if (!codeOauthMicrosoftStore || !emailOauthMicrosoftStore) return;
    mutateOauthMicrosoftLogin({
      code: codeOauthMicrosoftStore,
      email: emailOauthMicrosoftStore,
    });
    clearOauthMicrosoftStore();
  }, [
    dataOauthMicrosoftLogin,
    codeOauthMicrosoftStore,
    emailOauthMicrosoftStore,
    step,
  ]);

  useEffect(() => {
    if (isError) {
      toast.error('Email não cadastrado na Turivius, tente outro email.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      replace('/login');
    }
  }, [isError]);

  return (
    <Grid
      fluid
      className="login-container"
      style={{ padding: 0, display: 'flex' }}
    >
      <Col
        className="login-container_brand-container"
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{ padding: 0 }}
        xsHidden
        smHidden
      >
        <img
          className="login-container_brand-container_brand"
          src="new_logo-white.png"
        />
      </Col>
      <Col
        className="login-container_login"
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{ padding: 0 }}
      >
        <div className="flex flex-col w-full items-center sm:flex-row sm:justify-center">
          <RenderComponent />
        </div>
      </Col>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  loggingIn: state.login.loggingIn,
  email: state.login.email,
  hash: state.login.hash,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(Object.assign(ProfilesActions, LoginActions), dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
