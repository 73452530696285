let MICROSERVICES = {
  cards: '',
  cards_temporary: '',
  collections: '',
  dashboards: '',
  database: '',
  docs: '',
  help: '',
  jurimetrics: '',
  jurimetrics_trabalhista: '',
  monitoring: '',
  nestle: '',
  notifications: '',
  notifications_ws: '',
  profiles: '',
  reports: '',
  rdstation: '',
  segmentation: '',
  search_notifications: '',
  sla_jurimetric: '',
  webservice: '',
  sso: '',
  block: '',
};

let TOKEN = '';
let FINGER = '';

export function init(
  token,
  {
    cards,
    cards_temporary,
    collections,
    dashboards,
    database,
    docs,
    help,
    jurimetrics,
    jurimetrics_trabalhista,
    monitoring,
    nestle,
    sso,
    notifications,
    notifications_ws,
    profiles,
    rdstation,
    reports,
    segmentation,
    search_notifications,
    sla_jurimetric,
    webservice,
    block,
  },
  finger,
) {
  TOKEN = token;
  FINGER = finger;
  MICROSERVICES['cards'] = cards;
  MICROSERVICES['collections'] = collections;
  MICROSERVICES['dashboards'] = dashboards;
  MICROSERVICES['database'] = database;
  MICROSERVICES['docs'] = docs;
  MICROSERVICES['help'] = help;
  MICROSERVICES['jurimetrics'] = jurimetrics;
  MICROSERVICES['jurimetrics_trabalhista'] = jurimetrics_trabalhista;
  MICROSERVICES['cards_temporary'] = cards_temporary;
  MICROSERVICES['monitoring'] = monitoring;
  MICROSERVICES['nestle'] = nestle;
  MICROSERVICES['sso'] = sso;
  MICROSERVICES['notifications'] = notifications;
  MICROSERVICES['notifications_ws'] = notifications_ws;
  MICROSERVICES['profiles'] = profiles;
  MICROSERVICES['rdstation'] = rdstation;
  MICROSERVICES['reports'] = reports;
  MICROSERVICES['segmentation'] = segmentation;
  MICROSERVICES['search_notifications'] = search_notifications;
  MICROSERVICES['sla_jurimetric'] = sla_jurimetric;
  MICROSERVICES['webservice'] = webservice;
  MICROSERVICES['block'] = block;
}

export function setUserToken(token) {
  TOKEN = token;
}

export function setUserFinger(finger) {
  FINGER = finger;
}

function parseJSON(response) {
  if (response.ok) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        }),
      ),
    );
  }
  return new Promise((resolve, reject) =>
    response.json().then((json) => {
      reject({
        status: response.status,
        message: json,
      });
    }),
  );
}

function getQueryParams(data, type) {
  if (type === 'GET' && data) {
    return `?${Object.keys(data)
      ?.map((key) => {
        if (Array.isArray(data[key])) {
          return data[key]?.map((elem) => `${key}[]=${elem}`).join('&');
        } else {
          return `${key}=${data[key]}`;
        }
      })
      .join('&')}`;
  } else {
    return '';
  }
}

function getBody(data, type, contentType) {
  if (type === 'GET') {
    return undefined;
  } else if (
    contentType === 'application/x-www-form-urlencoded; charset=UTF-8' &&
    data
  ) {
    let formData = [];
    Object.keys(data).forEach((key) => {
      formData.push(`${key}=${data[key]}`);
    });
    return formData.join('&');
  } else if (contentType === 'multipart/form-data' && data) {
    return data;
  } else {
    return data ? JSON.stringify(data) : data;
  }
}

function call({ type, action, microservice, auth, data, contentType, finger }) {
  let headers = new Headers();
  if (auth) {
    if (auth === 'token') {
      headers.append('Authorization', `Token ${TOKEN}`);
    } else {
      headers.append('Authorization', `${auth}`);
    }
  }
  if (finger) {
    headers.append('Fingerprint', `${FINGER}`);
  }

  if (contentType !== 'multipart/form-data') {
    headers.append('Content-Type', contentType);
  }

  let init = {
    method: type,
    headers: headers,
    mode: 'cors',
    cache: 'default',
    body: getBody(data, type, contentType),
  };

  return new Promise((resolve, reject) => {
    fetch(`${microservice}${action}${getQueryParams(data, type)}`, init)
      .then((response) => {
        if (contentType === 'multipart/form-data') {
          return response;
        } else {
          return parseJSON(response);
        }
      })
      .then((response) => {
        if (contentType === 'multipart/form-data') {
          return resolve(response);
        }
        if (response.ok) {
          return resolve(response.json);
        }
        return reject(response);
      })
      .catch((error) =>
        reject({
          status: error.status,
          message: error.message,
        }),
      );
  });
}

export function request({
  type = 'GET',
  action,
  microservice,
  auth,
  data = undefined,
  contentType = 'application/json',
  finger,
}) {
  switch (microservice) {
    case 'general':
      return call({
        type,
        action,
        microservice: '',
        auth,
        data,
        contentType,
        finger,
      });
    case 'cards':
    case 'collections':
    case 'dashboards':
    case 'database':
    case 'docs':
    case 'help':
    case 'jurimetrics':
    case 'jurimetrics_trabalhista':
    case 'cards_temporary':
    case 'monitoring':
    case 'nestle':
    case 'sso':
    case 'block':
    case 'notifications':
    case 'profiles':
    case 'rdstation':
    case 'reports':
    case 'segmentation':
    case 'search_notifications':
    case 'sla_jurimetric':
      return call({
        type,
        action,
        microservice: MICROSERVICES[microservice],
        auth,
        data,
        contentType,
        finger,
      });
    default:
      return call({
        type,
        action,
        microservice: MICROSERVICES.webservice,
        auth,
        data,
        contentType,
        finger,
      });
  }
}
