import { useEffect, useRef, useState } from 'react';
import Websocket from 'react-websocket';
import vars from '../../../../../vars.module.scss';
import { NotificationActions } from 'material-ui-notifications';
import { Avatar } from 'material-ui';
import { withRouter } from 'react-router-dom';
import { MICROSERVICES } from '../../../../../data';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as NotificationsActions } from '../../../../../store/ducks/notifications';
import { Icon } from 'react-materialize';
import { requester } from '../../../../../requesters';
import { useToaster } from 'rsuite';
import NotificationPush from './NotificationPush';

const AUTO_HIDE_TIME = 7000;

const NotificationPopUp = (props) => {
  const [subscribed, setSubscribed] = useState(null);
  const refWebSocket = useRef();
  const toaster = useToaster();

  function cutString(st, limit) {
    if (st.length <= limit) {
      return st;
    }
    return st.substring(0, limit - 3) + '...';
  }

  function getLastFromChannel(id) {
    requester.notifications
      .getById(id)
      .then((response) => {
        if (response) {
          const message = JSON.parse(response.message);
          let date = new Date(parseInt(response.timestamp));

          props.appendNotification({
            id: response.id,
            title: message.title,
            subtitle: message.subtitle,
            message: message.message,
            new: true,
            actions: message.actions?.map((action) => {
              return {
                link: action.ref_link,
                link_name: action.button_label,
              };
            }),
            category: message.category,
            icon: message.icon,
            date: `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`,
          });
        }
      })
      .catch((err) => console.error(err));
  }

  const markAsNotified = (ids) => {
    requester.notifications
      .markAsNotified(localStorage.getItem('user'), `${ids}`)
      .then((response) => {})
      .catch((err) => console.error(err));
  };

  function handleData(data) {
    let result = JSON.parse(data);

    if (result.message.type === 'sync') {
      markAsNotified(result.id);
    }

    getLastFromChannel(result.id);

    const dontDisturb =
      localStorage.getItem('dont_disturb') &&
      localStorage.getItem('dont_disturb') === 'true'
        ? true
        : false;

    if (!dontDisturb) {
      const audio = new Audio('notification.mp3');
      audio.play();
    }

    toaster.push(notification(result), {
      placement: 'topEnd',
      duration: '10000',
    });
  }

  function sendMessage(message) {
    refWebSocket.current?.sendMessage(message);
  }

  function subscribeInChannels() {
    sendMessage(
      JSON.stringify({
        request: 'SUBSCRIBE',
        message: '',
        channel: 'ALL',
        authorization: `Token ${localStorage.getItem('token')}`,
      }),
    );
    sendMessage(
      JSON.stringify({
        request: 'SUBSCRIBE',
        message: '',
        channel: `U${localStorage.getItem('user')}`,
        authorization: `Token ${localStorage.getItem('token')}`,
      }),
    );
    sendMessage(
      JSON.stringify({
        request: 'SUBSCRIBE',
        message: '',
        channel: `C${localStorage.getItem('company')}`,
        authorization: `Token ${localStorage.getItem('token')}`,
      }),
    );
  }

  const timestampToDate = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const getAllSyncsNotNofieds = () => {
    let channels = `ALL,U${localStorage.getItem(
      'user',
    )},C${localStorage.getItem('company')}`;

    requester.notifications
      .getAllSyncsNotificationsNotNotified(channels)
      .then((response) => {
        if (response.syncs && response.syncs.length > 0) {
          const dontDisturb =
            localStorage.getItem('dont_disturb') &&
            localStorage.getItem('dont_disturb') === 'true'
              ? true
              : false;
          if (!dontDisturb) {
            const audio = new Audio('notification.mp3');
            audio.play();
          }
          this.markAsNotified(
            response.syncs?.map((notification) => notification.id).join(','),
          );

          response.syncs.forEach((notification, i) => {
            const message = JSON.parse(notification.message);

            NotificationActions.addNotification({
              autoHide: AUTO_HIDE_TIME + (response.syncs.length - i) * 1000,
              avatar: (
                <Avatar>
                  <Icon>{message.icon}</Icon>
                </Avatar>
              ),
              icon: <img src="favicon.ico" alt="turivius_icon" />,
              headerLabel: message.category,
              timestamp: timestampToDate(notification.timestamp),
              primaryColor: vars.turivius,
              title: cutString(message.title, 56),
              text: cutString(message.subtitle, 115),
              actions: message.actions?.map((action) => {
                return {
                  label: action.button_label.toUpperCase(),
                  onClick: (e) => props.history.push(action.ref_link),
                };
              }),
            });
          });
        }
      })
      .catch((err) => console.error(err));
  };

  function onOpen() {
    if (
      refWebSocket.current?.state?.ws?.readyState === 1 &&
      props.location.pathname !== '/login' &&
      props.location.pathname !== '/reload' &&
      props.location.pathname !== '/index.html' &&
      props.location.pathname !== '/pagamento' &&
      props.location.pathname !== '/redirect'
    ) {
      setSubscribed(true);
      subscribeInChannels();
      // getAllSyncsNotNofieds()
    }
  }

  const notification = (result) => <NotificationPush now result={result} />;

  useEffect(() => {
    if (
      props.location.pathname !== '/login' &&
      props.location.pathname !== '/reload' &&
      props.location.pathname !== '/index.html' &&
      props.location.pathname !== '/pagamento' &&
      props.location.pathname !== '/redirect' &&
      refWebSocket.current?.state?.ws?.readyState === 1 &&
      !subscribed
    ) {
      setSubscribed(true);
      subscribeInChannels();
    }
  }, []);

  return (
    <div>
      <Websocket
        url={MICROSERVICES.notifications_ws}
        onMessage={handleData.bind(this)}
        onOpen={onOpen.bind(this)}
        ref={(Websocket) => {
          refWebSocket.current = Websocket;
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(NotificationsActions, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(NotificationPopUp));
