import posthog from 'posthog-js';

const env_check = process.env.NODE_ENV === 'production';
let initialized = false;

let actions = {
  init: (apiToken) => {
    if (env_check) {
      posthog.init(apiToken, {
        autocapture: false,
      });
      initialized = true;
    }
  },
  identify: (id) => {
    if (env_check && initialized) posthog.identify(id);
  },
  pageview: () => {
    if (env_check && initialized) posthog.capture('$pageview');
  },
  capture: (name, props) => {
    if (env_check && initialized) posthog.capture(name, props);
  },
  register: (props) => {
    if (env_check && initialized) posthog.register(props);
  },
  register_once: (props) => {
    if (env_check && initialized) posthog.register_once(props);
  },
  people: {
    set: (props) => {
      if (env_check && initialized) posthog.people.set(props);
    },
    set_once: (props) => {
      if (env_check && initialized) posthog.people.set_once(props);
    },
  },
  reset: () => {
    if (env_check && initialized) posthog.reset();
  },
};

export let Posthog = actions;
