import 'rsuite/dist/rsuite.min.css';
import styles from './styles.module.scss';

// Buttons
import TuriviusIconButton from './buttons/TuriviusIconButton';
import TuriviusButton from './buttons/TuriviusButton';

// Loaders
import TuriviusPreloader from './loaders/TuriviusPreloader';
import SimplePreloader from './loaders/SimplePreloader';

// Cards
import TuriviusEntityIndicator from './cards/turivius-entity-indicator/TuriviusEntityIndicator';
import TuriviusCard from './cards/turivius-card/TuriviusCard';
import TuriviusCardHeader from './cards/turivius-card/TuriviusCardHeader';

// Components Encapsulation
import TuriviusPanel from './component-encapsulation/turivius-panel/TuriviusPanel';
import TuriviusTable from './component-encapsulation/turivius-table/TuriviusTable';
import TuriviusMessage from './component-encapsulation/turivius-message/TuriviusMessage';
import TuriviusAlertMessage from './component-encapsulation/turivius-message/TuriviusAlertMessage';
import TuriviusTooltip from './component-encapsulation/turivius-tooltip/TuriviusTooltip';
import TuriviusPopover from './component-encapsulation/turivius-popover/TuriviusPopover';

// Data Viz
import TuriviusFileViewer from './data-viz/turivius-file-viewer/TuriviusFileViewer';
import TuriviusLineChart from './data-viz/turivius-line-chart/TuriviusLineChart';
import TuriviusPieChart from './data-viz/turivius-pie-chart/TuriviusPieChart';
import TuriviusSwarmChart from './data-viz/turivius-swarm-chart/TuriviusSwarmChart';
import TuriviusBarChart from './data-viz/turivius-bar-chart/TuriviusBarChart';

// Turivius Components
import TuriviusBasePage from './turivius-components/turivius-base-page/TuriviusBasePage';
import TuriviusBasePageContent from './turivius-components/turivius-base-page-content/TuriviusBasePageContent';
import TuriviusSidenav from './turivius-components/turivius-sidenav/TuriviusSidenav';
import TuriviusNotFound from './turivius-components/turivius-not-found/TuriviusNotFound';
import TuriviusLogin from './turivius-components/turivius-login/TuriviusLogin';
import TuriviusSearchOperators from './turivius-components/turivius-search-operators/TuriviusSearchOperators';
import TuriviusEntitiesModal from './turivius-components/turivius-entities-modal/TuriviusEntitiesModal';

// Turivius Filters
import TuriviusDateFilter from './turivius-filters/turivius-date-filter/TuriviusDateFilter';
import TuriviusRadioButton from './turivius-filters/turivius-radio-button/TuriviusRadioButton';
import TuriviusMultipleSelection from './turivius-filters/turivius-multiple-selection/TuriviusMultipleSelection';
import TuriviusSingleSelection from './turivius-filters/turivius-single-selection/TuriviusSingleSelection';

export const Turivius = {
  TuriviusIconButton,
  TuriviusPreloader,
  TuriviusPanel,
  TuriviusTable,
  TuriviusEntityIndicator,
  SimplePreloader,
  TuriviusButton,
  TuriviusMessage,
  TuriviusAlertMessage,
  TuriviusTooltip,
  TuriviusPopover,
  TuriviusFileViewer,
  TuriviusLineChart,
  TuriviusPieChart,
  TuriviusSwarmChart,
  TuriviusBarChart,
  TuriviusSidenav,
  TuriviusNotFound,
  TuriviusCard,
  TuriviusCardHeader,
  TuriviusLogin,
  TuriviusBasePage,
  TuriviusBasePageContent,
  TuriviusSearchOperators,
  TuriviusEntitiesModal,
  TuriviusDateFilter,
  TuriviusRadioButton,
  TuriviusMultipleSelection,
  TuriviusSingleSelection,
};

export const ExampleComponent = ({ text }) => {
  return <div className={styles.test}>Example Component: {text}</div>;
};
