import { request } from './requester';

function getJurimetricsAndInteiroTeorTable() {
  return request({
    type: 'GET',
    microservice: 'reports',
    action: `tables`,
    contentType: 'application/json',
  }).then((response) => {
    return response;
  });
}

function reportFixJurimetrics(
  userId,
  userName,
  userEmail,
  cardId,
  correctClassification,
) {
  return request({
    type: 'POST',
    microservice: 'reports',
    action: `fixjurimetrics`,
    contentType: 'application/json',
    data: {
      user_id: userId,
      user_name: userName,
      user_email: userEmail,
      card_id: cardId,
      correct_classification: correctClassification,
    },
  }).then((response) => {
    return response;
  });
}

function reportNewAppUser(companyId, companyName, userEmail, activeUsers) {
  return request({
    type: 'POST',
    microservice: 'reports',
    action: `newappuser`,
    contentType: 'application/json',
    data: {
      company_name: `${companyName} (${companyId})`,
      user_email: userEmail,
      active_users: activeUsers,
    },
  }).then((response) => {
    return response;
  });
}

function reportToRDStation(data) {
  return request({
    type: 'POST',
    microservice: 'rdstation',
    action: ``,
    contentType: 'application/json',
    auth: 'token',
    data,
  }).then((response) => {
    return response;
  });
}

export const reports = {
  getJurimetricsAndInteiroTeorTable,
  reportFixJurimetrics,
  reportNewAppUser,
  reportToRDStation,
};
