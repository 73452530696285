import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rsuite';
import './TuriviusNotFound.scss';

const TuriviusNotFound = ({ onReturn, error, imageError }) => (
  <div className="container">
    <div className="containerImgNotFound">
      <img src={imageError} alt="not-found" />
    </div>

    <div className="containerTxtNotFound">
      <p className="tagContainer">{error}</p>
      <p className="titleContainer">Oops...Página Não Encontrada</p>
      <p className="bodyContainer">
        A página que você está tentando acessar não existe, volte <br /> para
        tela inicial e tente novamente.
      </p>
      <Button className="btnContainer" onClick={onReturn}>
        Voltar para página anterior
      </Button>
    </div>
  </div>
);

TuriviusNotFound.propTypes = {
  onReturn: PropTypes.func,
  error: PropTypes.string,
};

TuriviusNotFound.defaultProps = {
  onReturn: () => {},
  error: 'Page Not Found',
};

export default TuriviusNotFound;
