import { useState } from 'react';
import PropTypes from 'prop-types';
import { Eye, InfoCircle } from 'iconsax-react';
import { Tooltip, Whisper } from 'rsuite';
import eyeClose from '../../../assets/svgs/eye-close.svg';

const InputWithIcon = ({
  type,
  icon: Icon,
  placeholder,
  style,
  value,
  onChange,
  errorInput,
  errorMessage,
  forgotPassword,
  isMob,
  tooltip,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="input-with-icon">
      <div className="input-with-icon_input-group" style={style}>
        <div className={`input-with-icon_input-group_input ${type}`}>
          <Icon size={12} color="#667085" />
          <input
            type={showPassword ? 'text' : type}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            placeholder={placeholder}
            {...rest}
          />
        </div>

        {tooltip ? (
          <Whisper
            trigger={'hover'}
            placement="top"
            speaker={<Tooltip>{tooltip}</Tooltip>}
          >
            <InfoCircle size={18} color="#667085" variant="Bold" />
          </Whisper>
        ) : null}

        {type === 'password' ? (
          <div className="input-with-icon_input-group_plus-pw">
            <button onClick={() => setShowPassword(!showPassword)}>
              <Whisper
                trigger={'hover'}
                placement="top"
                style={{ transform: 'translateY(-5px)' }}
                speaker={
                  <Tooltip>
                    {showPassword ? 'Ocultar senha' : 'Mostrar senha'}
                  </Tooltip>
                }
              >
                {showPassword ? <Eye size={16} /> : <img src={eyeClose} />}
              </Whisper>
            </button>
            {forgotPassword ? (
              <button
                onClick={forgotPassword}
                className="input-with-icon_input-group_plus-pw forgot-pw"
              >
                Esqueceu a senha?
              </button>
            ) : null}
          </div>
        ) : null}
      </div>

      {errorInput && (
        <small
          className={`input-with-icon_message ${errorInput ? 'error' : ''} mt-2`}
        >
          {errorMessage}
        </small>
      )}
    </div>
  );
};

InputWithIcon.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

export default InputWithIcon;
