import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CollectionsActions } from '../../../store/ducks/collections';
import { Creators as ProfilesActions } from '../../../store/ducks/profiles';
import { requester, posthog } from '../../../requesters/';
import FlagSystem from '../../../components/FlagSystem';
import MyCollectionSession from '../components/MyCollectionsSession';
import SharedWithMeSession from '../components/SharedWithMeSession';
import PublicToCompanySession from '../components/PublicToCompanySession';
import './Collections.scss';

const CollectionPage = (props) => {
  const triggerSuccessChecklist = useRef();
  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  function getCollections(
    group,
    filter,
    page,
    ordering,
    setCollections,
    setLoading,
    setHaveCollections,
  ) {
    setLoading(true);

    requester.collections
      .searchCollections(group, filter, page, ordering)
      .then((response) => {
        setCollections(response);
        if (
          (group === 'public_to_company' && !filter) ||
          (group === 'shared_with' && !filter)
        ) {
          setHaveCollections(response?.results?.length > 0 ? true : false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    document.title = 'Coleções';
    posthog.capture('$pageview');
  }, []);

  return (
    <div>
      <FlagSystem
        current="collections"
        openSuccessChecklist={openSuccessChecklist}
      />
      <MyCollectionSession getCollections={getCollections} />
      <SharedWithMeSession getCollections={getCollections} />
      <PublicToCompanySession getCollections={getCollections} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userCollections: state.collections.userCollections,
  profilesImages: state.collections.profilesImages,
  companiesProfiles: state.profiles.companiesProfiles,
  fetchingCompaniesProfiles: state.profiles.fetchingCompaniesProfiles,
  permissions: state.profiles.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    Object.assign({}, { ...CollectionsActions, ...ProfilesActions }),
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPage);
