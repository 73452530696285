import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'rsuite';
import './SimplePreloader.scss';

class SimplePreloader extends React.Component {
  render() {
    const { vertical, size, text, center, backdrop, style } = this.props;

    return (
      <Loader
        vertical={vertical}
        content={text}
        size={size}
        center={center}
        backdrop={backdrop}
        className="turivius-spinner"
        style={style}
      ></Loader>
    );
  }
}

SimplePreloader.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  vertical: PropTypes.bool,
  text: PropTypes.string,
  center: PropTypes.bool,
  backdrop: PropTypes.bool,
  style: PropTypes.object,
};

SimplePreloader.defaultProps = {
  size: 'sm',
  vertical: false,
  text: '',
  center: false,
  backdrop: false,
  style: {},
};

export default SimplePreloader;
