import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FlagSystem from '../../../../components/FlagSystem';
import { Button } from '../../../../ds-components';
import { ArrowLeft2 } from 'iconsax-react';
import { useHistory } from 'react-router-dom';
import SubjectFiltersContainer from '../../components/subject/SubjectFiltersContainer';
import DashboardLoader from '../../core/DashboardLoader';
import SubjectDashContainer from '../../components/subject/SubjectDashContainer';
import FiltersLoader from '../../core/Filters/FiltersLoader';
import { useState } from 'react';
import { requester } from '../../../../requesters';

const SubjectDash = () => {
  const [subjectData, setSubjectData] = useState();
  const [subjectAcordao, setSubjectAcordao] = useState();
  const [subjectProcessListPage, setSubjectProcessListPage] = useState(1);
  const [subjectFilter, setSubjectFilter] = useState();
  const [encodeFilter, setEncodeFilter] = useState();

  const [loadingSubjectData, setLoadingSubjectData] = useState(false);
  const [loadingSubjectAcordao, setLoadingSubjectAcordao] = useState(false);
  const [loadingSubjectFilter, setLoadingSubjectFilter] = useState(false);
  const [tempFilters, setTempFilters] = useState({
    trt: [],
    varas: [],
    adv_empresa: [],
    adv_empregado: [],
  });

  const companyId = localStorage.getItem('company');
  const history = useHistory();
  let { assunto } = useParams();

  useEffect(() => {
    getFilter();
  }, []);

  useEffect(() => {
    getSubjectDashData(assunto, encodeFilter);
  }, [encodeFilter]);

  useEffect(() => {
    getAcordao(assunto, subjectProcessListPage);
  }, [subjectProcessListPage]);

  const getFilter = () => {
    setLoadingSubjectFilter(true);
    requester.nestle
      .getFilters(companyId)
      .then((response) => {
        if (response) {
          setSubjectFilter([
            {
              label: 'TRT',
              options: response.trt,
              field_name: Object.keys(response)[4],
              type: 'multiple_select',
            },
            {
              label: 'Varas',
              options: response.varas,
              field_name: Object.keys(response)[0],
              type: 'multiple_select',
            },

            {
              label: 'Advogado da empresa',
              options: response.adv_empresa,
              field_name: Object.keys(response)[1],
              type: 'multiple_select',
            },
            {
              label: 'Advogado do reclamante',
              options: response.adv_empregado,
              field_name: Object.keys(response)[2],
              type: 'multiple_select',
            },
          ]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoadingSubjectFilter(false));
    return;
  };

  const handleFilter = (tempFilters) => {
    // console.log(tempFilters)
    var filt = JSON.stringify(tempFilters);
    // console.log("filt", filt)
    setEncodeFilter(btoa(filt));
    // getSubjectDashboard()
    return;
  };

  const getSubjectDashData = (assunto, filters) => {
    if (!encodeFilter) {
      setLoadingSubjectData(true);
      requester.nestle
        .getDashboard(`query=${assunto}`, companyId)
        .then((response) => {
          setSubjectData({
            panorama: response.panorama,
            assuntosComuns: response.assuntos_comuns,
            charts: [
              {
                title: 'Distribuição de processos no país',
                data: [['Estado', 'Processos'], ...response.brasil],
                highlights: response?.highlights?.hl_brasil,
                highlightsPosition: 'left',
                type: 'cloropleth',
              },
              {
                title: `Quantidade de decisões publicadas ao longo do tempo sobre ${assunto}`,
                data: [
                  [
                    'Ano',
                    'Decisões favoráveis',
                    'Decisões desfavoráveis',
                    'Decisões parcialmente favoráveis',
                  ],
                  response.decisoes_publicadas[1],
                  response.decisoes_publicadas[2],
                  response.decisoes_publicadas[3],
                ],
                total: response.decisoes_publicadas[0],
                totalHighlight: response?.highlights?.hl_decisoes_publicadas[0],
                highlights: response?.highlights?.hl_decisoes_publicadas[1],
                highlightsPosition: 'right',
                type: 'line',
              },
              {
                title: `Distribuição de processos envolvendo ${assunto} por advogados da empresa`,
                data: response.treemaps[0][0],
                highlights: response?.highlights?.hl_treemaps[0],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: `Distribuição de processos envolvendo ${assunto} por advogados do reclamante`,
                data: response.treemaps[1][0],
                highlights: response?.highlights?.hl_treemaps[1],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: 'Ranking de varas mais julgadoras',
                data: response.bars,
                highlights: response?.highlights?.hl_bars,
                type: 'bars',
              },
            ],
          });
          setLoadingSubjectData(false);
          getAcordao(assunto, subjectProcessListPage);
        })

        .catch((err) => console.error(err));
      // .finally(() => setLoadingSubjectData(false));
      // getAcordao(subject);
    } else {
      setLoadingSubjectData(true);
      requester.nestle
        .getDashboard(`query=${assunto}&filters=${filters}`, companyId)
        .then((response) => {
          setSubjectData({
            panorama: response.panorama,
            assuntosComuns: response.assuntos_comuns,
            charts: [
              {
                title: 'Distribuição de processos no país',
                data: [['Estado', 'Processos'], ...response.brasil],
                highlights: response?.highlights?.hl_brasil,
                highlightsPosition: 'left',
                type: 'cloropleth',
              },
              {
                title: `Quantidade de decisões publicadas ao longo do tempo sobre ${assunto}`,
                data: [
                  [
                    'Ano',
                    'Decisões favoráveis',
                    'Decisões desfavoráveis',
                    'Decisões parcialmente favoráveis',
                  ],
                  response.decisoes_publicadas[1],
                  response.decisoes_publicadas[2],
                  response.decisoes_publicadas[3],
                ],
                total: response.decisoes_publicadas[0],
                totalHighlight: response?.highlights?.hl_decisoes_publicadas[0],
                highlights: response?.highlights?.hl_decisoes_publicadas[1],
                highlightsPosition: 'right',
                type: 'line',
              },
              {
                title: `Distribuição de processos envolvendo ${assunto} por advogados da empresa`,
                data: response.treemaps[0][0],
                highlights: response?.highlights?.hl_treemaps[0],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: `Distribuição de processos envolvendo ${assunto} por advogados do reclamante`,
                data: response.treemaps[1][0],
                highlights: response?.highlights?.hl_treemaps[1],
                highlightsPosition: 'right',
                type: 'treemap',
              },
              {
                title: 'Ranking de varas mais julgadoras',
                data: response.bars,
                highlights: response?.highlights?.hl_bars,
                type: 'bars',
              },
            ],
          });
          setLoadingSubjectData(false);
          getAcordao(assunto, subjectProcessListPage);
        })
        .catch((err) => console.error(err));
    }
  };

  const getAcordao = (assunto, currentPage) => {
    setLoadingSubjectAcordao(true);
    if (!encodeFilter) {
      requester.nestle
        .getAcordaos('query=' + assunto + '&page=' + currentPage, companyId)
        .then((response) => {
          setSubjectAcordao({
            totalPages: response.n_pages,
            table: response.table,
          });
          setLoadingSubjectAcordao(false);
        })
        .catch((err) => {
          console.log(err);
          // toast.error()
        });
      return;
    } else {
      setLoadingSubjectAcordao(true);
      requester.nestle
        .getAcordaos(
          `query=${assunto}&filters=${encodeFilter}&page=${currentPage}`,
          companyId,
        )
        .then((response) => {
          setSubjectAcordao({
            totalPages: response.n_pages,
            table: response.table,
          });
          setLoadingSubjectAcordao(false);
        })
        .catch((err) => console.log(err));
      return;
    }
  };

  return (
    <div className="business-dashboard">
      <FlagSystem current="dashboard-empresarial" />
      <div className="dashboard-container">
        <div className="dashboard-container_filters">
          <Button
            appearance="secondary"
            size="sm"
            icon={<ArrowLeft2 color="#667085" size={16} />}
            onClick={() => history.push('/dashboard-empresarial')}
          >
            Voltar
          </Button>
          {loadingSubjectFilter || !subjectFilter ? (
            <FiltersLoader />
          ) : (
            <SubjectFiltersContainer
              filters={subjectFilter}
              handleFilter={handleFilter}
              tempFilters={tempFilters}
              setTempFilters={setTempFilters}
            />
          )}
        </div>
        <div className="dashboard-container_content">
          {loadingSubjectData || !subjectData ? (
            <DashboardLoader />
          ) : (
            <SubjectDashContainer
              dashboardData={subjectData}
              subjectAcordao={subjectAcordao}
              loadingSubjectData={loadingSubjectData}
              loadingSubjectAcordao={loadingSubjectAcordao}
              subjectProcessListPage={subjectProcessListPage}
              setSubjectProcessListPage={setSubjectProcessListPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubjectDash;
