import React, { Component } from 'react';
import { Button, Loader, TagPicker } from 'rsuite';
import { postHubSpotEvent } from '../../../../../services/hubspot';

class MultiSelect extends Component {
  state = {
    requesting: true,
    items: [],
    value: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.resultById !== this.props.resultById) {
      this.populateTagPickerFromResultById();
    }
    if (prevProps.incomingItems !== this.props.incomingItems) {
      this.setState({
        items:
          this.props.filtersProps.externalFilters[this.props.filter.field_name],
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadItems()
        .then((items) => {
          this.populateTagPickerFromResultById();
          if (items.length > 0) {
            this.setState({ items, requesting: false }, () => {
              this.loadValuesFromUrl();
            });
          } else {
            this.setState({ requesting: false });
          }
        })
        .catch((error) => {
          console.error('Erro ao carregar os itens:', error);
          this.setState({ requesting: false });
        });
    }, 6000);

    this.logFilterByRelatorParams();
  }

  populateTagPickerFromResultById = () => {
    const resultById = JSON.parse(
      localStorage.getItem('filters'),
    ).filter_by_relator;
    if (resultById) {
      const matchedValues = this.matchValuesWithItems(
        resultById.map((id) => id.toString()),
      );
      this.setState({ value: matchedValues });
      this.props.filtersProps.setTmpFilters(
        this.props.filter.field_name,
        matchedValues,
      );
      this.props.updateSelectedFilters({
        [this.props.filter.field_name]: matchedValues,
      });
    }
  };

  componentWillUnmount() {}
  loadItems = async () => {
    try {
      const externalFilters =
        this.props.filtersProps.externalFilters[this.props.filter.field_name];
      const items = externalFilters
        ? externalFilters.map((filter) => ({
            ...filter,
            id: filter.key,
          }))
        : [];
      return items;
    } catch (error) {
      return [];
    }
  };

  loadValuesFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filters = urlParams.get('filters');
    if (filters) {
      try {
        const filtersObject = JSON.parse(filters);
        const filterByRelator = filtersObject.filter_by_relator;

        if (filterByRelator) {
          const selectedValues = filterByRelator.map((item) => item.toString());
          const matchedValues = this.matchValuesWithItems(selectedValues);
          this.setState({ value: matchedValues });
          this.props.filtersProps.setTmpFilters(
            this.props.filter.field_name,
            matchedValues,
          );
          this.props.updateSelectedFilters({
            [this.props.filter.field_name]: matchedValues,
          });
        }
      } catch (error) {
        console.error("Erro ao parsear o JSON de 'filters':", error);
      }
    }
  };

  logFilterByRelatorParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const filters = urlParams.get('filters');
    if (filters) {
      try {
        const filtersObject = JSON.parse(filters);
        const filterByRelator = filtersObject.filter_by_relator;
      } catch (error) {
        console.error("Erro ao parsear o JSON de 'filters':", error);
      }
    } else {
      console.log("Nenhum parâmetro 'filters' encontrado na URL");
    }
  };

  matchValuesWithItems = (selectedValues) => {
    const { items } = this.state;
    if (!items.length) return selectedValues;
    return selectedValues
      .map((val) => {
        const matchedItem = items.find(
          (item) =>
            item.id.toString() === val ||
            item.key.toLowerCase() === val.toLowerCase() ||
            item.related_names.toLowerCase().includes(val.toLowerCase()),
        );
        return matchedItem ? matchedItem.id : val;
      })
      .filter(Boolean);
  };

  cleanDates = () => {
    const { filter, updateSelectedFilters } = this.props;
    const { setTmpFilters } = this.props.filtersProps;
    this.setState({ value: [] });
    setTmpFilters(filter.field_name, []);
    updateSelectedFilters({ [filter.field_name]: [] });
  };

  updateUrlParams = (ids) => {
    let filtersLocal = localStorage.getItem('filters');
    filtersLocal = JSON.parse(filtersLocal);

    filtersLocal.filter_by_relator = ids;
    localStorage.setItem('filters', JSON.stringify(filtersLocal));
    const urlParams = new URLSearchParams(window.location.search);
    if (ids.length > 0) {
      urlParams.set('filter_by_relator', JSON.stringify(ids));
    } else {
      urlParams.delete('filter_by_relator');
    }
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`,
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.clear !== this.props.clear) {
      this.handleClearChange();
    }
  }

  handleClearChange = () => {
    this.setState({ value: [] });
    this.props.setClear(false);
  };

  render() {
    const { items, requesting, value } = this.state;
    const { filter, placeholder } = this.props;

    return (
      <div className="single-filter-box" id={filter.field_name}>
        <div className="single-filter-box_container-title">
          <span className="single-filter-box_title">{filter.public_name}</span>
          <Button size="xs" appearance="link" onClick={this.cleanDates}>
            Limpar
          </Button>
        </div>

        {!requesting ? (
          <TagPicker
            virtualized
            block
            id={`rs-select-${filter.field_name}`}
            labelKey="related_names"
            valueKey="id"
            data={items.map((item) => ({
              ...item,
              related_names:
                item.related_names.length > 35
                  ? item.related_names.slice(0, 35) + '...'
                  : item.related_names,
            }))}
            placeholder={placeholder}
            cleanable={false}
            placement="top"
            value={value}
            onChange={(valor) => {
              const selectedIds = valor.map((v) =>
                typeof v === 'string' ? v : v['id'],
              );
              this.setState({ value: valor });
              this.props.filtersProps.setTmpFilters(filter.field_name, valor);
              this.props.updateSelectedFilters({
                [filter.field_name]: valor,
              });
              this.updateUrlParams(selectedIds);

              if (this.props.context) {
                postHubSpotEvent('app_changefilters', {
                  app_changefilters: this.props.context,
                });
              }
            }}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default MultiSelect;
