import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  CheckPicker,
  Col,
  Grid,
  Loader,
} from 'rsuite';
import { requester } from '../../../../requesters';
import colors from '../../../../vars.module.scss';

const footerStyles = {
  padding: '10px 2px',
  borderTop: '1px solid #e5e5e5',
};

const footerButtonStyle = {
  float: 'right',
  marginRight: 10,
  marginTop: 2,
};

const SelectSharePeopleMonitoring = ({ menu, sharePeople, setSharePeople }) => {
  const picker = React.useRef();
  const [users, setUsers] = useState([]);

  const allValue = users?.map((item) => item.id);

  const getUsers = () => {
    const company = localStorage.getItem('company');
    requester.profiles
      .getCompanyUserNames(company, '')
      .then(setUsers)
      .catch(console.error);
  };

  const handleCheckAll = (value, checked) => {
    setSharePeople(checked ? allValue : []);
  };

  const renderMenu = (menu) => {
    if (users.length === 0) {
      return (
        <div style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Loader /> Carregando...
        </div>
      );
    }
    return menu;
  };

  useEffect(() => {
    if (sharePeople.length > 0) {
      getUsers();
    }
  }, []);

  return (
    <Grid fluid>
      <Col sm={24}>
        <div className="monitoring-settings-content">
          {!menu ? (
            <label className="monitoring-settings-label">
              Compartilhar Monitoramento
            </label>
          ) : null}
          <CheckPicker
            block
            ref={picker}
            data={users?.map((user) => ({
              label: user.name,
              value: user.id,
              ...user,
            }))}
            onOpen={sharePeople.length > 0 ? null : getUsers}
            onSearch={getUsers}
            value={sharePeople}
            onChange={setSharePeople}
            placeholder="Selecione usuários da sua empresa..."
            renderMenu={renderMenu}
            renderMenuItem={(label, item) => (
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                {item.photo ? (
                  <Avatar
                    circle
                    size="sm"
                    src={`data:image/*;base64, ${item.photo}`}
                  />
                ) : (
                  <Avatar
                    style={{ backgroundColor: colors.turivius_dark }}
                    circle
                    size="sm"
                  >
                    <FontAwesomeIcon
                      className="rs-icon"
                      icon={faUser}
                      style={{ color: colors.secondary_lighter }}
                    />
                  </Avatar>
                )}
                {label}
              </div>
            )}
            renderExtraFooter={() => (
              <div style={footerStyles}>
                <Checkbox
                  indeterminate={
                    sharePeople.length > 0 &&
                    sharePeople.length < allValue.length
                  }
                  checked={sharePeople.length === allValue.length}
                  onChange={handleCheckAll}
                >
                  Selecionar Todos
                </Checkbox>

                <Button
                  style={footerButtonStyle}
                  appearance="primary"
                  size="sm"
                  onClick={() => {
                    picker.current.close();
                  }}
                >
                  Ok
                </Button>
              </div>
            )}
          />
        </div>
      </Col>
    </Grid>
  );
};

export default SelectSharePeopleMonitoring;
