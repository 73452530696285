import { create } from 'zustand';

interface OauthMicrosoftState {
  code: string;
  email: string;
  setCode: (code: string) => void;
  setEmail: (email: string) => void;
  clear: () => void;
}

export const useOauthMicrosoftStore = create<OauthMicrosoftState>((set) => ({
  code: '',
  email: '',
  setCode: (code) =>
    set((state) => ({
      ...state,
      code,
    })),
  setEmail: (email) =>
    set((state) => ({
      ...state,
      email,
    })),
  clear: () => set(() => ({ email: '', code: '' })),
}));
