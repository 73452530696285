import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FlexboxGrid, Grid, Message, Pagination, Row, toaster } from 'rsuite';
import TuriviusPreloader from '../../../app/TuriviusPreloader';
import FlagSystem from '../../../components/FlagSystem';
import { CollectionSkeleton } from '../../../components/Skeleton';
import { posthog, requester } from '../../../requesters';
import { useCollectionTags } from '../../../store/ducks/collectionTags';
import { Types as BulkactionTypes } from '../../../store/ducks/collectionsBulkaction';
import { useCollections } from '../../../store/ducks/theCollections';
import BulkActionBar from '../../jurisprudential-search/components/results/BulkActionBar/BulkActionBar';
import AccessToCollectionDenied from '../components/Collection/AccessToCollectionDenied/AccessToCollectionDenied';
import CollectionHeader from '../components/Collection/CollectionHeader/CollectionHeader';
import EmptyCollection from '../components/Collection/EmptyCollection/EmptyCollection';
import CollectionsResultCard from '../components/Collections/CollectionsResultCard';
import './Collection.scss';

const Collection = () => {
  const LIMIT = 10;
  const { collections: theCollections, fetch: fetchCollections } =
    useCollections();
  const {
    collections: collectionsTags,
    clear: clearCollectionTags,
    fetch: fetchCollectionTags,
  } = useCollectionTags();

  const { collectionId } = useParams();

  const collectionTags = collectionsTags.find(
    (collection) => collection.id === collectionId,
  )?.tags;

  const reference = useRef(null);
  const triggerSuccessChecklist = useRef(null);

  const [collection, setCollection] = useState({});
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCards, setPageCards] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const [requestingComments, setRequestingComments] = useState(true);
  const [comments, setComments] = useState(null);
  const [
    theMatchResultsThatHaveCollections,
    setTheMatchResultsThatHaveCollections,
  ] = useState([]);

  const openSuccessChecklist = () => triggerSuccessChecklist.current?.open();

  const dispatch = useDispatch();

  const selectedCards = useSelector(
    (state) => state.collectionsBulkaction.selectedCards,
  );

  const getEntities = async () => {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    await requester.cards
      .getEntities('trabalhista')
      .then((res) => {
        Object.keys(res)?.map((e) => {
          entity.push(res[e]);
        });
      })
      .catch((err) => {
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });

    setEntities(entity);
  };

  const getCollection = () => {
    let tmpCollection = { id: -1 };
    Object.entries(theCollections).forEach((collectionType) => {
      collectionType[1].boards.forEach((currCollection) => {
        if (currCollection.id.toString() === collectionId)
          tmpCollection = currCollection;
      });
    });
    setCollection(tmpCollection);
  };

  const getPageCards = (page = 1, offSetPage = LIMIT) => {
    setLoading(true);
    const pageCardsIds = collection.cards.slice(
      offSetPage * page - offSetPage,
      offSetPage * page,
    );

    requester.cards
      .getCards(pageCardsIds)
      .then((response) => {
        collection.cards.forEach((card, idx) => {
          response.cards.forEach((rcard) => {
            if (parseInt(rcard.id) === parseInt(card)) {
              rcard.idx = idx;
            }
          });
        });
        setPageCards(response.cards);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toaster.push(
          <Message
            header="Ocorreu um erro"
            duration={4000}
            type="error"
            showIcon
            closable
          >
            Houve um problema de conexão, tente novamente
          </Message>,
        );
      });
  };

  const getCollectionComments = () => {
    setRequestingComments(true);
    requester.collections
      .getCollectionComments(collectionId)
      .then((response) => {
        setComments(response);
      })
      .catch(console.error)
      .finally(() => setRequestingComments(false));
  };

  const addComment = (cardId, comment) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId] ? [...comments[cardId], comment] : [comment],
    });
  };

  const removeComment = (cardId, commentId) => {
    setComments({
      ...comments,
      [cardId]: comments[cardId].filter((comment) => comment.id !== commentId),
    });
  };

  const onChangePageHandler = (page) => {
    setActivePage(page);
    getPageCards(page, LIMIT);
    const body = document.querySelector('.rs-content');
    body.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchMatchResults = async () => {
      if (pageCards == null) return;

      try {
        const cardIds = pageCards.map((card) => card.id);
        const response =
          await requester.collections.matchResultsThatHaveCollections(
            encodeURIComponent(JSON.stringify(cardIds)),
          );

        const uniqueResults = [...new Set(response)];
        setTheMatchResultsThatHaveCollections(uniqueResults);
      } catch (error) {
        console.error('Erro ao buscar resultados de coleções:', error);
        setLoading(false);
      }
    };

    fetchMatchResults();
  }, [theCollections, pageCards]);

  useEffect(() => {
    posthog.capture('$pageview');
    document.title = `Coleção #${collectionId}`;

    getEntities();
    getCollectionComments();
    fetchCollections();
    fetchCollectionTags(collectionId);

    return () => {
      clearCollectionTags();
      dispatch({ type: BulkactionTypes.UNSELECT_ALL });
    };
  }, [collectionId]);

  useEffect(() => {
    if (collection.id && collection.id.toString() !== collectionId) {
      setCollection({});
      setPageCards(null);
    }
    if (
      theCollections &&
      theCollections.public_to_company_boards.boards.length +
        theCollections.shared_w_boards.boards.length +
        theCollections.user_boards.boards.length >
        0
    )
      getCollection();
  }, [collectionId, theCollections]);

  useEffect(() => {
    if (collection.id && collection.id !== -1) {
      getPageCards();
      return setLoading(false);
    }
  }, [collection]);

  return (
    <div>
      <FlexboxGrid
        justify="center"
        className={`transition duration-300 ease-out ${selectedCards.length > 0 ? 'translate-y-0' : '-translate-y-20'}`}
      >
        <FlexboxGrid.Item colspan={24} className="sticky top-0 z-20">
          <BulkActionBar
            theMatchResultsThatHaveCollections={
              theMatchResultsThatHaveCollections
            }
            collection={collection}
            collectionComments={comments}
            collectionTags={collectionTags}
            showExportCardsAsCSV={true}
            showSaveCardToCollectionButton={true}
            showRemoveCardFromCollectionButton={true}
            showAddTagDecision={true}
            showRemoveTagDecision={true}
          />
        </FlexboxGrid.Item>

        {collection && (
          <FlexboxGrid.Item colspan={24} className="pt-2">
            <FlagSystem
              current="collection"
              titleBreadCrumb={collection.name}
              additionalRoute={`${collection.id} - ${collection.name} `}
              openSuccessChecklist={openSuccessChecklist}
            />
          </FlexboxGrid.Item>
        )}

        <FlexboxGrid.Item colspan={24} id="collection-cont">
          {loading || !collection.hasOwnProperty('id') ? (
            <div className="collection-section">
              <div className="collection-container">
                <TuriviusPreloader backdrop />
                <div style={{ padding: 20 }}>
                  <Grid fluid>
                    <Row>
                      <CollectionSkeleton />
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          ) : collection.id === -1 ? (
            <Grid fluid style={{ padding: 20 }}>
              <Row style={{ paddingBottom: 10 }}>
                <AccessToCollectionDenied />
              </Row>
            </Grid>
          ) : (
            <Grid fluid style={{ padding: 20 }}>
              <Row style={{ paddingBottom: 10 }}>
                <CollectionHeader collection={collection} />
              </Row>

              <Row>
                {collection?.cards?.length > 0 ? (
                  <div
                    id="collection-infinite-scroll"
                    ref={reference}
                    style={{
                      minHeight: 400,
                      overflow: 'auto',
                    }}
                  >
                    {pageCards &&
                      pageCards?.map((card) => (
                        <CollectionsResultCard
                          key={card.id}
                          card={card}
                          collectionId={collectionId}
                          collection={collection}
                          entities={entities}
                          entity={
                            entities.filter(
                              (item) => item.id === card.entity,
                            )[0]
                          }
                          requestingComments={requestingComments}
                          comments={
                            comments
                              ? comments[card.id]
                                ? comments[card.id]
                                : []
                              : comments
                          }
                          addComment={addComment}
                          removeComment={removeComment}
                          context={'Single Collection'}
                          theMatchResultsThatHaveCollections={
                            theMatchResultsThatHaveCollections
                          }
                          collectionTags={collectionTags}
                          getCollectionComments={getCollectionComments}
                        />
                      ))}

                    <Pagination
                      prev
                      next
                      first
                      last
                      total={collection.cards.length}
                      activePage={activePage}
                      onChangePage={(page) => onChangePageHandler(page)}
                      limit={LIMIT}
                      maxButtons={10}
                    />
                  </div>
                ) : (
                  <EmptyCollection />
                )}
              </Row>
            </Grid>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {/*<SuccessChecklistButton openSuccessChecklist={triggerSuccessChecklist} />*/}
    </div>
  );
};

export default Collection;
