import React from 'react';
import { Chart } from 'react-google-charts';

const LineChart = ({ data }) => {
  const chartData = data?.map((item) => {
    if (Array.isArray(item)) {
      return item;
    } else {
      return [...item.data?.map((d) => [d.x, d.y])];
    }
  });

  const filteredChartData = chartData[1]?.map(([year, value1], index) => {
    const value2 = chartData[2][index][1];
    const value3 = chartData[3][index][1];
    return [year, value1, value2, value3];
  });

  const options = {
    curveType: 'linear',
    legend: { position: 'bottom' },
    vAxis: {
      format: '0',
      gridlines: {
        color: '#E4E7EC',
      },
    },
    hAxis: {
      format: '0',
      gridlines: {
        color: '#E4E7EC',
      },
    },
    colors: ['#12B76A', '#F79009', '#0BA5EC'],
    pointSize: 8,
  };

  return (
    <Chart
      chartEvents={[
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
          },
        },
      ]}
      chartType="LineChart"
      data={[data[0], ...filteredChartData]}
      options={options}
      width="100%"
    />
  );
};

export default LineChart;
