import React from 'react';
import './style.scss';

class IndependentBottomSheetNotification extends React.Component {
  state = {
    dismissed: false,
  };

  onDismiss = () => {
    this.setState({ dismissed: true });
  };

  render() {
    const { dismissed } = this.state;
    const { children, style } = this.props;
    if (dismissed) {
      return null;
    }
    return (
      <div
        className={'BottomSheetNotification animated'}
        onClick={this.onDismiss}
        style={style ? { ...style } : {}}
      >
        <button
          className="BottomSheetNotification-Close"
          onClick={this.onDismiss}
        >
          ✕
        </button>
        <div className="BottomSheetNotification-Inner">{children}</div>
      </div>
    );
  }
}

export default IndependentBottomSheetNotification;
