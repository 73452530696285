import React from 'react';
import '../style.scss';

const CompanyAvatar = ({ alt, src, size }) => {
  return (
    <div className="avatar-company">
      {/* <div className="avatar"> */}
      <img className="avatar-image" alt={alt} src={src} />
      {}
    </div>
  );
};

export default CompanyAvatar;
