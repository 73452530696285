import React from 'react';

import MonitoringSearch from './MonitoringSearch';
import OrderBy from './OrderBy';
import MonitoringCardsContainer from './MonitoringCardsContainer';

const SharedMonitoringSession = ({
  entities,
  onChangeFilters,
  monitoringSharedWithMeCount,
  monitoringSharedWithMeList,
  loadingMonitoringSharedWithMe,
  pageMonitoringSharedWithMe,
}) => {
  return monitoringSharedWithMeCount > 0 ? (
    <div style={{ padding: ' 0 24px 24px 24px' }}>
      <MonitoringSearch
        shared
        title="Monitoramentos Compartilhados Comigo"
        whereSearch="monitoring-shared"
        onSearch={onChangeFilters}
        id="shared-monitoring"
      />
      <OrderBy
        whereSearch="monitoring-shared"
        changeFilters={onChangeFilters}
      />
      <MonitoringCardsContainer
        shared
        whereSearch="monitoring-shared"
        entities={entities}
        monitoringCount={monitoringSharedWithMeCount}
        terms={monitoringSharedWithMeList}
        loading={loadingMonitoringSharedWithMe}
        pageMonitoring={pageMonitoringSharedWithMe}
        changePage={onChangeFilters}
      />
    </div>
  ) : null;
};

export default SharedMonitoringSession;
