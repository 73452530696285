import React from 'react';
import { Button, IconButton, Modal } from 'rsuite';
import CreateNewMonitoringButton from './CreateNewMonitoring';

const SettingsModalMonitoring = ({
  monitoring,
  entities,
  onEditMonitoring,
  getMonitoring,
}) => {
  return (
    <>
      {/* <IconButton
            block
            className="menu-button"
            icon={<i className="ri-settings-4-line menu-button_icon rs-icon"/>}
        >
            Configuração
        </IconButton> */}
      <CreateNewMonitoringButton
        menu
        edit
        monitoring={monitoring}
        entities={entities}
        onEditMonitoring={onEditMonitoring}
        getMonitoring={getMonitoring}
      />
    </>
  );
};

export default SettingsModalMonitoring;
