import React from 'react';
import PropTypes from 'prop-types';
import { Whisper, Popover, Col, Grid, Row } from 'rsuite';
import './TuriviusSearchOperators.scss';

const operators = [
  {
    img: '/e.png',
    imgAlt: 'dois conjuntos matemáticos representando a operação E',
    example: `<p>
                    <b>Exemplo</b><br />
                    <i>cessão <b>E</b> quotas</i> irá retornar os resultados em que <u>as duas palavras</u> sejam encontradas.
                </p>`,
    quote: `[...] julgada parcialmente procedente <mark>cessão</mark> de <mark>quotas</mark> de sociedade [...]`,
    controlId: 'control-id-e',
    dataTestId: 'operator-btn-e',
    addOperator: 'E',
  },
  {
    img: '/ou.png',
    imgAlt: 'dois conjuntos matemáticos representando a operação OU',
    example: `<p>
                    <b>Exemplo</b><br />
                    <i>monocular <b>OU</b> monovisão</i> irá retornar os resultados em que <u>pelo menos uma das palavras</u> seja encontrada.
                </p>`,
    quote: `[...] de necessidades especiais - <mark>monovisão</mark> ou visão <mark>monocular</mark> - enquadramento devido [...]`,
    controlId: 'control-id-ou',
    dataTestId: 'operator-btn-ou',
    addOperator: 'OU',
  },
  {
    img: '/nao.png',
    imgAlt: 'dois conjuntos matemáticos representando a operação NÃO',
    example: `<p>
                    <b>Exemplo</b><br />
                    <i>prisão <b>NÃO</b> preventiva</i> irá retornar os resultados <u>que contenham prisão e não contenham preventiva</u>.
                </p>`,
    quote: `[...] pedido de revogação da <mark>prisão</mark> temporária ao Juízo do Plantão [...]`,
    controlId: 'control-id-nao',
    dataTestId: 'operator-btn-nao',
    addOperator: 'NÃO',
  },
  {
    img: null,
    imgAlt: null,
    example: `<p>
                    <b>Exemplo</b><br />
                    <i><b>"</b>zona franca<b>"</b></i> irá retornar os resultados que <u>contenham a frase <b>"</b>zona franca<b>"</b> com todas as palavras e exatamente nessa ordem</u>.
                </p>`,
    quote: `[...] empresas estabelecidas na <mark>Zona Franca</mark> de Manaus não configuram receitas de [...]`,
    controlId: 'control-id-quotes',
    dataTestId: 'operator-btn-quotes',
    addOperator: ' "" ',
  },
  {
    img: null,
    imgAlt: null,
    example: `<p>
                    <b>Exemplo</b><br />
                    <i>analis<b>$</b> E <b>$</b>mercado</i> irá retornar os resultados com <u>palavras que comecem com "analis" (analisou, analisar, analisado) e palavras que terminem com "mercado" (mercado, supermercado, hipermercado)</u>.
                </p>`,
    quote: `[...]  não pode ser exigida cumulativamente com a <mark>multa</mark> de <mark>ofício</mark>, aplicável [...]`,
    controlId: 'control-id-dollar-sign',
    dataTestId: 'operator-btn-dollar-sign',
    addOperator: '$',
  },
  {
    img: null,
    imgAlt: null,
    example: `<p>
                    <b>Exemplo</b><br />
                    <i>multa <b>ADJ2</b> ofício</i> irá retornar os resultados em que <u> ofício será no máximo a segunda palavra após multa</u>.
                </p>`,
    quote: `[...] julgada parcialmente procedente <mark>cessão</mark> de <mark>quotas</mark> de sociedade [...]`,
    controlId: 'control-id-adj',
    dataTestId: 'operator-btn-adj',
    addOperator: 'ADJ',
  },
];

const TuriviusSearchOperators = ({ addOperator }) => (
  <span className="turivius-search-operators">
    <b>Operadores:&nbsp;&nbsp;</b>
    {operators?.map((operator, i) => (
      <Whisper
        key={i}
        placement="bottom"
        trigger="hover"
        speaker={
          <Popover className="operators-popover">
            <div style={{ maxWidth: 320 }}>
              <Grid fluid>
                <Row>
                  {operator.img ? (
                    <Col xs={10}>
                      <img
                        src={operator.img}
                        alt={operator.imgAlt}
                        style={{ width: '100%' }}
                      />
                    </Col>
                  ) : null}
                  <Col
                    xs={operator.img ? 14 : 24}
                    dangerouslySetInnerHTML={{ __html: operator.example }}
                  />
                  <Col
                    xs={24}
                    dangerouslySetInnerHTML={{ __html: operator.quote }}
                    className="quote"
                  />
                </Row>
              </Grid>
            </div>
          </Popover>
        }
        controlId={operator.controlId}
      >
        <span
          data-testid={operator.dataTestId}
          className="operator-btn"
          onClick={() => addOperator(operator.addOperator)}
        >
          {operator.addOperator}
        </span>
      </Whisper>
    ))}
  </span>
);

TuriviusSearchOperators.propTypes = {
  addOperator: PropTypes.func,
};

TuriviusSearchOperators.defaultProps = {
  addOperator: () => {},
};

export default TuriviusSearchOperators;
