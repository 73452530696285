export const queryStringToObject = (url: string) =>
  Object.fromEntries([...new URLSearchParams(url.split('?')[1])]);

export const objectToQueryString = (queryParameters: Record<string, any>) => {
  return queryParameters
    ? Object.entries(queryParameters).reduce((queryString, [key, val]) => {
        const symbol = queryString.length === 0 ? '?' : '&';
        queryString += typeof val === 'string' ? `${symbol}${key}=${val}` : '';
        return queryString;
      }, '')
    : '';
};

export const extractTextFromDoubleQuotes = (text: string): string => {
  const parts: string[] = text.split('"');
  const processedParts: string[] = parts.map((part, index) =>
    index % 2 === 1 ? part.toLowerCase() : part,
  );
  const processedText: string = processedParts.join('');
  return processedText;
};
