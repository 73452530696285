import React from 'react';

const ReloadIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 8.00065C14.6663 11.6807 11.6797 14.6673 7.99967 14.6673C4.31967 14.6673 2.07301 10.9607 2.07301 10.9607M2.07301 10.9607H5.08634M2.07301 10.9607V14.294M1.33301 8.00065C1.33301 4.32065 4.29301 1.33398 7.99967 1.33398C12.4463 1.33398 14.6663 5.04065 14.6663 5.04065M14.6663 5.04065V1.70732M14.6663 5.04065H11.7063"
        stroke="#0069F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReloadIcon;
