import { create } from 'zustand';

interface KnowledgeState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const useKnowledgeStore = create<KnowledgeState>((set) => ({
  isOpen: false,
  open: () =>
    set((state) => ({
      ...state,
      isOpen: true,
    })),
  close: () =>
    set((state) => ({
      ...state,
      isOpen: false,
    })),
}));
