function satismaterData(profile) {
  if (window.satismeter) {
    window.satismeter({
      writeKey: 'BOPCaSo62Birj8Id',
      userId: profile.id,
      traits: {
        name: profile.name,
        email: profile.email,
        createdAt: profile.created,
        company: profile.company.id,
        companyName: profile.company.name,
        active: profile.active,
      },
    });
  }
}

export { satismaterData };
