import { useMutation, useQuery } from '@tanstack/react-query';
import api from './api';
import {
  ChangeColorRequest,
  ChangeColorResponse,
  CreateBoardRequest,
  CreateBoardResponse,
  FetchReportInfoRequest,
  FetchReportInfoResponse,
  UploadLogoUrlRequest,
} from './types';
import { queryClient } from '../../providers/query.provider';
import { toast } from 'react-toastify';

const endpoints = {
  createBoard: () => 'reports/info/',
  fetchReportInfo: (board_id: number) => `reports/info?board=${board_id}`,
  uploadLogoUrl: (board_id: number) => `reports/${board_id}/logo/`,
  changeColor: () => 'reports/info',
};

const reportKeys = {
  all: ['reports'] as const,
  createBoards: () => [...reportKeys.all, 'createBoard'] as const,
  createBoard: (board_id: number) =>
    [...reportKeys.createBoards(), board_id] as const,
  fetchReportInfos: () => [...reportKeys.all, 'fetchReportInfo'] as const,
  fetchReportInfo: (board_id: number) =>
    [...reportKeys.fetchReportInfos(), board_id] as const,
  uploadLogoUrls: () => [...reportKeys.all, 'uploadLogoUrl'] as const,
  uploadLogoUrl: () => [...reportKeys.uploadLogoUrls()] as const,
  changeColors: () => [...reportKeys.all, 'changeColor'] as const,
  changeColor: ({ board, main_color, text_color }: ChangeColorRequest) =>
    [...reportKeys.uploadLogoUrls(), board, main_color, text_color] as const,
};

const reportApi = {
  useCreateBoard: () =>
    useMutation({
      mutationFn: async ({ board }: CreateBoardRequest) => {
        const response = await api.post<
          CreateBoardRequest,
          CreateBoardResponse
        >(endpoints.createBoard(), {
          board,
        });
        return response;
      },
      onSuccess: (_, request) => {
        queryClient.invalidateQueries({
          queryKey: reportKeys.fetchReportInfo(request.board),
        });
      },
    }),
  useFetchReportInfo: ({ board_id }: FetchReportInfoRequest) =>
    useQuery({
      queryKey: reportKeys.fetchReportInfo(board_id),
      queryFn: async () => {
        const response = await api.get<FetchReportInfoResponse>(
          endpoints.fetchReportInfo(board_id),
        );
        return response.data;
      },
      retry: false,
    }),
  useUploadLogoUrl: () =>
    useMutation({
      mutationFn: async ({ board_id, file }: UploadLogoUrlRequest) => {
        const formData = new FormData();
        formData.append('logo', file);

        const response = await api.post(
          endpoints.uploadLogoUrl(board_id),
          {
            logo: file,
          },
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );
        return response;
      },
      onSuccess: (_, request) => {
        queryClient.invalidateQueries({
          queryKey: reportKeys.fetchReportInfo(request.board_id),
        });
        toast.success('Logo alterada com sucesso');
      },
      onError: () => toast.error('Erro ao fazer upload, tente novamente'),
    }),
  useChangeColor: () =>
    useMutation({
      mutationFn: async ({
        board,
        main_color,
        text_color,
      }: ChangeColorRequest) => {
        const response = await api.patch<
          ChangeColorRequest,
          ChangeColorResponse
        >(endpoints.changeColor(), {
          board,
          main_color,
          text_color,
        });
        return response;
      },
    }),
};

export const {
  useCreateBoard,
  useFetchReportInfo,
  useUploadLogoUrl,
  useChangeColor,
} = reportApi;
