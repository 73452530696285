import { withRouter } from 'react-router-dom';
import { Turivius } from '../../ui';

const NotFound = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Turivius.TuriviusNotFound
        imageError="/error.png"
        onReturn={props.history.goBack}
      />
    </div>
  );
};

export default withRouter(NotFound);
