import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';
import SynonymsFilter from '../SynonymsFilter';
import { useState, useEffect } from 'react';
import { ArrowDown2, ArrowUp2, TickCircle } from 'iconsax-react';
import './InteiroTeorFilter.scss';

const InteiroTeorFilter = ({
  context,
  filtersProps,
  updateSelectedFilters,
  resultById,
  clear,
  setClear,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [synonyms, setSynonyms] = useState(false);
  const [recursosRepetitivos, setRecursosRepetitivos] = useState(false);
  const [repercussaoGeral, setRepercussaoGeral] = useState(false);
  const [selectedValue, setSelectedValue] = useState('["ementa","full_text"]');

  useEffect(() => {
    const field = localStorage.getItem('fields');
    if (field) {
      if (
        field === '["ementa","full_text"]' ||
        field === '["ementa"]' ||
        field === '["full_text"]'
      ) {
        setSelectedValue(field);
      }
    }
    if (resultById) {
      if (resultById === 'ementa,full_text') {
        setSelectedValue('["ementa","full_text"]');
      } else if (resultById === 'ementa') {
        setSelectedValue('["ementa"]');
      } else if (resultById === 'full_text') {
        setSelectedValue('["full_text"]');
      }
    }
  }, [resultById]);

  const changeSynonyms = (value) => {
    setSynonyms(value);
  };

  const changeRecursosRepetitivos = (value) => {
    setRecursosRepetitivos(value);
  };

  const changeRepercussaoGeral = (value) => {
    setRepercussaoGeral(value);
  };

  function OnChangeFilter(option) {
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'ementa_inteiro_teor',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }
    localStorage.setItem('fields', option);
    const parsedOption = JSON.parse(option);

    filtersProps.changeInteiroTeor(parsedOption);
    updateSelectedFilters({ inteiroTeor: parsedOption });

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('fields', encodeURIComponent(option));
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams}`,
    );

    setSelectedValue(option);
  }

  useEffect(() => {
    if (clear) {
      setSelectedValue('["ementa","full_text"]');
      setClear(false);
    }
  }, [clear]);

  return (
    <div className="single-filter-box">
      <div className="filter-header" onClick={() => setCollapsed(!collapsed)}>
        <label>Buscar em</label>
        {collapsed ? (
          <ArrowUp2 color="#98A2B3" />
        ) : (
          <ArrowDown2 color="#98A2B3" />
        )}
      </div>
      <RadioGroup
        inline
        name="radioList"
        onChange={(option) => OnChangeFilter(option)}
        value={selectedValue}
      >
        <Radio value='["ementa","full_text"]'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TickCircle size="12" />
            Todos
          </div>
        </Radio>

        <Radio value='["ementa"]'>Ementa</Radio>
        <Radio value='["full_text"]'>Inteiro Teor</Radio>
      </RadioGroup>

      <div className="synonymsFilter-styles">
        {collapsed && (
          <SynonymsFilter
            context="Results"
            filtersProps={{
              synonyms,
              recursosRepetitivos,
              repercussaoGeral,
              changeSynonyms,
              changeRecursosRepetitivos,
              changeRepercussaoGeral,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InteiroTeorFilter;
