import { useEffect } from 'react';
import { Radio, RadioGroup } from 'rsuite';
import { posthog } from '../../../../../../../requesters';
import { postHubSpotEvent } from '../../../../../../../services/hubspot';

const DefaultOperatorFilter = (props) => {
  const handleChange = (newOption) => {
    const { context, filtersProps, setDefaultOperator } = props;
    if (context) {
      posthog.capture(context, {
        action: 'change_parameter',
        id: 'default_operator',
      });
      postHubSpotEvent('app_changeparameter', { app_changeparameter: context });
    }

    if (context === 'Monitoring') {
      setDefaultOperator(newOption);
    } else {
      filtersProps.changeDefaultOperator(newOption);
    }
  };

  useEffect(() => {
    if (props.context != 'Monitoring') {
      if (!props.filtersProps.defaultOperator) {
        props.filtersProps.changeDefaultOperator('ou');
      }
    }
  });

  return (
    <div className="default-operator-filter">
      <span className="title-option">Operador de Busca Padrão</span>
      <RadioGroup
        name="radioList"
        inline
        appearance="picker"
        onChange={handleChange}
        value={
          props.context != 'Monitoring'
            ? props.filtersProps.defaultOperator
            : props.defaultOperator
        }
      >
        <Radio value="ou">
          {' '}
          <span>
            Pelo menos um termo
            <br />
            (OU)
          </span>{' '}
        </Radio>
        <Radio value="e">
          {' '}
          Todos os termos
          <br />
          (E){' '}
        </Radio>
      </RadioGroup>
    </div>
  );
};

export default DefaultOperatorFilter;
