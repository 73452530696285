import React from 'react';
import { Star } from 'iconsax-react';
import { Dropdown } from 'rsuite';

const PlanUpgradeOption = ({ eventKey }) => {
  const link = process.env.REACT_APP_PLAN_UPGRADE;
  return (
    <Dropdown.Item eventKey={eventKey}>
      <button
        className="option-menu-default"
        onClick={() => window.open(link, '_black')}
      >
        <Star size="20" color="#0069F5" />
        <label> Quero um treinamento </label>
      </button>
    </Dropdown.Item>
  );
};

export default PlanUpgradeOption;
