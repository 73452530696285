import { useState } from 'react';
import { useEffect } from 'react';
import FlagSystem from '../../../../components/FlagSystem';
import Autocomplete from '../../core/Filters/Autocomplete';
import { Button, SpinningLoader } from '../../../../ds-components';
import { CloseCircle } from 'iconsax-react';
import { useHistory } from 'react-router-dom';
import { requester } from '../../../../requesters';

const SubjectModal = () => {
  const [allSubjects, setAllSubjects] = useState();
  const [subject, setSubject] = useState('');
  const [loadingAllSubjects, setLoadingAllSubjects] = useState(false);
  const [disableSubjectDash, setDisableSubjectDash] = useState(true);

  const companyId = localStorage.getItem('company');
  const history = useHistory();

  useEffect(() => {
    getAllSubjects();
  }, []);

  const getAllSubjects = () => {
    setLoadingAllSubjects(true);
    requester.nestle
      .getSubjects(companyId)
      .then((response) => {
        if (response) {
          setAllSubjects(response);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoadingAllSubjects(false));
  };

  const handleSubmitSubjectDash = (e) => {
    e.preventDefault();
    history.push(`/dashboard-empresarial/visao-assunto/${subject}`);
    return;
  };

  const handleSelectSubject = (e) => {
    setSubject(e);
    setDisableSubjectDash(false);
  };

  return (
    <div className="business">
      <FlagSystem current="dashboard-empresarial" />
      <div className="business-home">
        <Button
          appearance="minimal"
          size="sm"
          icon={<CloseCircle color="#667085" size={24} />}
          iconPosition="right"
          onClick={() => history.push('/dashboard-empresarial')}
          className="business-home_back"
        >
          Fechar
        </Button>

        <h5 className="business-home_title font-semibold text-color_black">
          Qual o assunto a ser pesquisado?
        </h5>
        <p className="pb-3">
          Digite apenas 1 assunto para análise nos Tribunais Regionais do
          Trabalho.
        </p>
        <div className="mt-3 w-75">
          {loadingAllSubjects ? (
            <div className="d-flex align-items_center">
              <SpinningLoader size="sm" color="primary" className="mr-4" />
              <span>carregando</span>
            </div>
          ) : (
            <form
              className="business-home_form"
              onSubmit={(e) => handleSubmitSubjectDash(e)}
            >
              <Autocomplete
                suggestions={allSubjects}
                onChangeText={(e) => handleSelectSubject(e)}
              />
              <Button
                appearance="primary"
                size="md"
                disabled={disableSubjectDash}
                className="w-25"
                type="submit"
              >
                Buscar
              </Button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubjectModal;
