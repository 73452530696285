import db from '../config/fire-config';
import { getDocs, collection, query, where } from 'firebase/firestore';

export const getAll = async () => {
  const infoRef = collection(db, 'informations');

  const querySnapshot = await getDocs(infoRef);
  const result = [];
  querySnapshot.forEach((doc) => {
    var obj = doc.data();
    obj.id = doc.id;
    result.push(obj);
  });
  return result;
};
export const getBySession = async (session) => {
  const infoRef = collection(db, 'informations');
  const q = query(infoRef, where('route', '==', session));

  const querySnapshot = await getDocs(q);
  const result = [];
  querySnapshot.forEach((doc) => {
    var obj = doc.data();
    obj.id = doc.id;
    result.push(obj);
  });
  return result;
};
