import { Posthog } from '../posthog/posthog';
import { request } from './requester';

function createForm(name) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: 'forms',
    auth: 'token',
    data: {
      name: name,
    },
  }).then((form) => {
    // FAZER UM POST DE EVENTO NO POSTHOG
    return form;
  });
}

function duplicateForm(formId) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `forms/${formId}/duplicate`,
    auth: 'token',
  }).then((form) => {
    // FAZER UM POST DE EVENTO NO POSTHOG
    return form;
  });
}

function createFormHistory(formId, layout, content) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `forms/${formId}`,
    auth: 'token',
    data: {
      layout,
      content,
    },
  }).then((form) => {
    // FAZER UM POST DE EVENTO NO POSTHOG
    return form;
  });
}

function createClient(name) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `clients`,
    auth: 'token',
    data: {
      name,
      email: '',
    },
  }).then((client) => {
    return client;
  });
}

function shareForm(formId, clientId, expiration = 24) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `share/${formId}/${clientId}?expiration=${expiration}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function answerForm(formId, shareToken, answers) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `submissions/${formId}?share_token=${shareToken}`,
    data: answers,
  }).then((response) => {
    return response;
  });
}

function updateAnswerForm(formId, shareToken, answers) {
  return request({
    type: 'PATCH',
    microservice: 'docs',
    action: `submissions/${formId}/${shareToken}`,
    data: answers,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function uploadFiles(formId, shareToken, files) {
  return request({
    type: 'POST',
    microservice: 'docs',
    contentType: 'multipart/form-data',
    action: `files/${formId}?share_token=${shareToken}`,
    //action: `files?user_id=42&form_id=${formId}&client_id=7b373445-b6ef-4482-91d3-07055dc829d8&field_id=${fieldId}`,
    //action: `files?form_id=${formId}&share_token=${shareToken}&field_id=${fieldId}`,
    //auth: "token", // precisa remover essa autenticação depois
    data: files,
  }).then((response) => {
    return response;
  });
}

function getFiles(formId, fileData) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `files/${formId}?file_data=${fileData}`,
    auth: 'token',
    contentType: 'multipart/form-data',
  }).then((file) => {
    return file;
  });
}

function getAllForms(getHistory = true) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: 'forms',
    auth: 'token',
    data: {
      get_history: getHistory,
      page_size: 100,
    },
  }).then((forms) => {
    return forms;
  });
}

function getForms(currentPage, getHistory = false, page_size = 10) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: 'forms',
    auth: 'token',
    data: {
      get_history: getHistory,
      page_size: page_size,
      n_page: currentPage,
    },
  }).then((forms) => {
    return forms;
  });
}

function getFormById(formId, getHistory = false) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `forms/${formId}`,
    auth: 'token',
    data: {
      get_history: getHistory,
    },
  }).then((form) => {
    return form;
  });
}

function getFormToAnswer(formId, shareToken) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `share/forms/${formId}`,
    data: {
      share_token: shareToken,
    },
  }).then((form) => {
    return form;
  });
}

function sharePublicFormToAnswer(formId, expiration = 24) {
  return request({
    type: 'POST',
    microservice: 'docs',
    action: `share/${formId}?expiration=${expiration}`,
    auth: 'token',
  }).then((form) => {
    return form;
  });
}

function getAllAnswers() {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: 'submissions',
    auth: 'token',
  }).then((answers) => {
    return answers;
  });
}

function getAnswers(formId, page = 1, query = '', page_size = 10) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `submissions/${formId}?query=${query}&page_size=${page_size}&n_page=${page}`,
    auth: 'token',
  }).then((answers) => {
    return answers;
  });
}

function getAnswersByClient(formId, clientId) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `submissions/${formId}/${clientId}`,
    auth: 'token',
  }).then((answers) => {
    return answers;
  });
}

function getFormShareInfo(formId) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `share/${formId}`,
    auth: 'token',
  }).then((share) => {
    return share;
  });
}

function getFormClientShareInfo(formId, clientId) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `share/${formId}/${clientId}`,
    auth: 'token',
  }).then((share) => {
    return share;
  });
}

function getClient(clientId) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `clients/${clientId}`,
    auth: 'token',
  }).then((client) => {
    return client;
  });
}

function getClients(query, { page, pageSize } = {}) {
  let data = {
    query,
    n_page: page,
    page_size: pageSize,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `search/client`,
    auth: 'token',
    data,
  }).then((share) => {
    return share;
  });
}

function getFormAnswersByClientName(formId, clientName) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `submissions/${formId}?query=${clientName}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function getClientByName(clientName) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `/search/client?query=${clientName}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function updateForm(formId, { name, isActive } = {}) {
  let data = {
    form_name: name,
    is_active: isActive,
  };
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key],
  );

  return request({
    type: 'PATCH',
    microservice: 'docs',
    action: `forms/${formId}`,
    auth: 'token',
    data,
  }).then((form) => {
    return form;
  });
}

function deleteForm(formId) {
  return request({
    type: 'DELETE',
    microservice: 'docs',
    action: `forms/${formId}`,
    auth: 'token',
  }).then((response) => {
    // INSERIR POSTHOG
    return response;
  });
}

function unshareForm(formId, clientId) {
  return request({
    type: 'DELETE',
    microservice: 'docs',
    action: `share/${formId}/${clientId}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

function deleteAnswer(formId, clientId) {
  return request({
    type: 'DELETE',
    microservice: 'docs',
    action: `submissions/${formId}/${clientId}`,
    auth: 'token',
  }).then((response) => {
    // INSERIR POSTHOG
    return response;
  });
}

function getFormByName(formName) {
  return request({
    type: 'GET',
    microservice: 'docs',
    action: `/search/form?query=${formName}`,
    auth: 'token',
  }).then((response) => {
    return response;
  });
}

export const docs = {
  createForm,
  duplicateForm,
  createFormHistory,
  createClient,
  answerForm,
  updateAnswerForm,
  uploadFiles,
  getFiles,
  shareForm,
  getAllForms,
  getForms,
  getFormById,
  getFormByName,
  getFormToAnswer,
  getFormShareInfo,
  getFormClientShareInfo,
  getClient,
  getClientByName,
  getFormAnswersByClientName,
  getClients,
  getAllAnswers,
  getAnswers,
  getAnswersByClient,
  updateForm,
  deleteForm,
  unshareForm,
  deleteAnswer,
  sharePublicFormToAnswer,
};
