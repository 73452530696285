import { Button, Modal } from 'rsuite';
import { Turivius } from '../../../ui';
import SLATable from './SLATable';
import './style.scss';
import { Tag } from '../../../ds-components';
import { Electricity } from 'iconsax-react';

const SLAJurimetric = ({ open, handleClose, bases, accuracy, classified }) => {
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Turivius.TuriviusPopover
            trigger="hover"
            message={
              <p>
                O Vision é a Inteligência Artificial Tributária da Turivius.
                <br /> Clique aqui e saiba mais
              </p>
            }
          >
            <div
              className="premium-filter-identifier-animated"
              onClick={() =>
                window.open(
                  'https://turivius.com/portal/conheca-o-vision-inteligencia-artificial-tributaria/',
                  '__blank'
                )
              }
            >
              <Tag
                color={'orange'}
                size="sm"
                type="default"
                iconPosition="left"
                className="pb-1 pr-2 mb-1"
              >
                <Electricity size="14" color="#FF8A65" className="mt-2" />{' '}
                Powered by Vision
              </Tag>
            </div>
          </Turivius.TuriviusPopover>
        </Modal.Header>
        <Modal.Body className="modal-vision">
          <h4>Disponibilidade da Jurimetria</h4>
          <p className="mt-4">
            Para a Turivius, transparência e confiança são fundamentais. Entenda
            como fazemos a jurimetria e porque você pode confiar nela.
          </p>
          <SLATable
            title={'Quais as bases cobertas pela Jurimetria da Turivius?'}
            description={
              <p>
                Atualmente a inteligência artificial da Turivius cobre as bases
                jurisprudenciais abaixo:
              </p>
            }
            data={bases}
          />
          <SLATable
            title={
              'Dentro dessas bases, qual o percentual de decisões classificada?*'
            }
            description={
              <p>
                <span>
                  OBS: A quantidade de processos está sem a utilização de
                  filtros (embargos, full_text, ementa)
                </span>
                <br />
                <br />
                <b>*Com exceção dos Conselhos Administrativos</b>, nem todas as
                decisões são tributárias. Por isso selecionamos o subconjunto de
                decisões tributárias para classificar.
                <br />
                *Para <b>STF, STJ e TRFs</b>, os processos na terceira coluna se
                referem aos processos identificados como tributários, a partir
                das classificações em suas ementas.{' '}
              </p>
            }
            data={classified}
          />
          <SLATable
            title={
              'Das decisões classificadas, qual é o percentual estimado de acerto?*'
            }
            description={
              <p>
                O percentual de acerto, ou acurácia, mostra a média de acertos
                das classificações de resultados de decisões dos nossos
                algoritmos.Por exemplo, uma acurácia de 90 % significa que a
                cada 100 decisões classificadas nessa base de dados, 90, em
                média, estão classificadas corretamente, enquanto 10 podem estar
                classificadas incorretamente.
                <br />
                <b>*Note que a acurácia se refere apenas à jurimetria.</b>
              </p>
            }
            data={accuracy}
          />
          <h5 className="sla-title">
            Como vocês estimam o percentual de acerto?
          </h5>
          <div className="sla-description">
            <p>
              Nós coletamos uma amostra do tribunal que é estatisticamente
              significativa. Ela representa em torno de 90% dos processos do
              tribunal analisado.
              <br />A partir dela é realizada a classificação manual de cada um
              dos processos por profissionais da área. Eles classificam em
              contrário ao contribuinte, favorável ao contribuinte, parcialmente
              favorável ao contribuinte ou outros.
              <br /> Nosso software analisa os mesmos processos. A partir do
              resultado do software, comparamos com o que foi obtido pela
              classificação manual. A porcentagem de acerto final é a quantidade
              processos que o software classificou igual à classificação manual
              pela quantidade total de processos da amostra.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="sla-footer">
          <Button onClick={handleClose} appearance="primary">
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SLAJurimetric;
