import { Input } from 'rsuite';
import OperatorsHelper from '../../../jurisprudential-search/components/search/OperatorsHelper';

const TermLabelMonitoring = ({ term, setTerm, termError }) => {
  const addOperator = (operator) => {
    let query = `${term} ${operator} `.replace('  ', ' ');
    setTerm(query);
    document
      .getElementById('term-monitoring')
      .getElementsByTagName('input')[0]
      .focus();
  };

  return (
    <div
      className={`monitoring-settings-content ${termError ? 'error' : null}`}
    >
      <label className="monitoring-settings-label">Termo</label>
      <Input
        id="term-monitoring"
        className="term-monitoring"
        placeholder="Qual termo deseja monitorar?"
        onChange={setTerm}
        value={term}
      />
      <OperatorsHelper addOperator={addOperator} />
    </div>
  );
};

export default TermLabelMonitoring;
