import Chart from 'react-google-charts';

const BarsChart = ({ data }) => {
  const mountChartData = (chartData) => {
    if (chartData.length === 0) {
      return [];
    }

    const headerData = Object.keys(chartData[0]);

    if (headerData[0] === 'pedido') {
      const data = [['Pedido', 'Porcentagem', { role: 'style' }]];

      for (const item of chartData) {
        const { pedido, Porcentagem, PorcentagemColor } = item;
        data.push([pedido, parseFloat(Porcentagem), PorcentagemColor]);
      }

      return data;
    } else {
      const data = [
        [
          'Vara',
          'Favoráveis',
          { role: 'style' },
          'Desfavoráveis',
          { role: 'style' },
          'Parcialmente',
          { role: 'style' },
        ],
      ];

      for (const item of chartData) {
        const {
          vara,
          Favoráveis,
          FavoráveisColor,
          Desfavoráveis,
          DesfavoráveisColor,
          Parcialmente,
          ParcialmenteColor,
        } = item;
        data.push([
          vara,
          Favoráveis,
          FavoráveisColor,
          Desfavoráveis,
          DesfavoráveisColor,
          Parcialmente,
          ParcialmenteColor,
        ]);
      }

      return data;
    }
  };

  const options = {
    legend: { position: 'bottom' },
    colors: ['#12B76A', '#F79009', '#0BA5EC'],
    hAxis: {
      minValue: 0,
      gridlines: {
        color: '#E4E7EC',
      },
    },
    bars: 'vertical',
    vAxis: {
      minValue: 0,
      gridlines: {
        color: '#E4E7EC',
      },
    },
  };

  return (
    <Chart
      chartType="ColumnChart"
      data={mountChartData(data)}
      options={options}
      width="100%"
      height="400px"
    />
  );
};

export default BarsChart;
