import { createActions, createReducer } from 'reduxsauce';

/*
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setNotifications: ['notifications'],
  appendNotification: ['notification'],
  incrementPage: [],
  setPage: ['page'],
});

const INITIAL_STATE = {
  notifications: [],
  page: 1,
};

const setPage = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    page: action.page,
  };
};

const incrementPage = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    page: state.page + 1,
  };
};

const setNotifications = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    notifications: [...action.notifications],
  };
};

const appendNotification = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    notifications: [action.notification, ...state.notifications],
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.SET_NOTIFICATIONS]: setNotifications,
  [Types.APPEND_NOTIFICATION]: appendNotification,
  [Types.INCREMENT_PAGE]: incrementPage,
  [Types.SET_PAGE]: setPage,
});
